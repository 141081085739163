/**
 * スネークラインメッシュのレベルごとの色定義
 * @module app/map/snakeline/MeshColor
 */
define({
    1: '#F2E700',
    2: '#FF2800',
    3: '#AA00AA',
    4: '#0C000C'
});
