/**
 * クリックアクションを持つラスターレイヤー
 * @module app/map/ClickableRasterTileLayer
 */
define([
    'dojo/_base/lang',
    'dojo/topic',
    'leaflet'
], function(lang, topic, leaflet) {

    var clickableRasterTileLayer = leaflet.TileLayer.extend({
        // コンストラクタ
        initialize: function(layerUrl, layerInfo, opacity) {
            // オプションに透過度、オーバーズーミング設定を加える
            var options = lang.mixin(null, layerInfo, {opacity: opacity});
            options.maxNativeZoom = layerInfo.overlayZoom;

            // superの呼び出し
            leaflet.TileLayer.prototype.initialize.call(
                this,
                layerUrl,
                options
            );

            // mapに対するclickイベントを受け取って処理
            // 継承先で `this._onClick` を定義して処理を書くこと
            topic.subscribe('idis/map/IdisMap::click', lang.hitch(this, this._onclick));
        }
    });
    return clickableRasterTileLayer;
});
