/**
 * sessionStorage, locaiStorageに含まれるパラメーター種別を定数として定義
 * @module idis/consts/QUERY
 */
define({
    // 自動更新間隔
    AUTO_UPDATE_INTERVAL: 'AutoUpdateInterval',
    // 中心表示
    CENTER_MARK: 'CenterMark'
});
