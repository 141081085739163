/**
 * 古いブラウザー用のパッチを提供。
 * @module idis/util/polyfill
 */
define(function() {
    /* jshint freeze:false */
    // String.prototype.includes
    // https://developer.mozilla.org/ja/docs/Web/JavaScript/Reference/Global_Objects/String/includes
    if (!String.prototype.includes) {
        String.prototype.includes = function(search, start) {
            if (typeof start !== 'number') {
                start = 0;
            }
            if (start + search.length > this.length) {
                return false;
            } else {
                return this.indexOf(search, start) !== -1;
            }
        };
    }
});

