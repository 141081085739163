define([
    'leaflet'
], function(leaflet) {
    return leaflet.Icon.extend({
        /*
           note has dom like this
           <div (leaflet created = DivIcon) >
               <div (this includes ResizeHandle and span)>
                   <div (ResizeHandle)></div>
                   <span (comment)></span>
               </div>
           </div>
           */
        options: {
            className     : 'CustomDivIcon',
            drawType      : 'DivIcon',
            isNewNote     : true,
            iconSize      : new leaflet.Point(200, 50),
            width         : '200px',
            height        : '50px',
            fillColor     : '#8899ff',
            fillOpacity   : 1,
            borderColor   : '#0000ff',
            borderOpacity : 1,
            borderWidth   : 4,
            fontSize      : 14,
            fontColor     : '#000000',
            comment       : ''
        },

        initialize: function(options){
            leaflet.setOptions(this, options);
        },

        createIcon: function (oldIcon) {
            var div = (oldIcon && oldIcon.tagName === 'DIV') ? oldIcon : document.createElement('div');
            this._setIconStyles(div, 'icon');
            this.setDefaultStyle(div, this.options);
            this.setThumnails(div, this.options.fileList);
            return div;
        },

        setDefaultStyle: function(div, options){
            div.style.border = options.borderWidth + 'px solid ' +
                this.colorFormatConverter(options.borderColor, options.borderOpacity);

            div.style.backgroundColor =
                this.colorFormatConverter(options.fillColor, options.fillOpacity);

            div.style.color = this.colorFormatConverter(options.fontColor);

            div.style.fontSize = options.fontSize + 'px';
            div.style.width  = options.width;
            div.style.height = options.height;
            div.style.backgroundClip = 'content-box';

            this.createCommentArea(div, options);
        }
    });
});
