/**
 * 緯度経度よりメッシュコード算出
 * @module app/map/layer/CalcMeshCode
*/
define([
], function CalcMeshCode(){

    /**
     * 緯度経度をメッシュコードに変換します。
     * @param 緯度 latitude
     * @param 経度 longitude
     * @param メッシュ番号 resolution
     * @return メッシュコード
     */
    function _convertLatLngToMeshCode(latitude, longitude, resolution) {

        var p = Math.floor((latitude * 60) / 40);
        var a = (latitude * 60) % 40;
        var q = Math.floor(a / 5);
        var b = a % 5;
        var r = Math.floor((b * 60) / 30);
        var c = (b * 60) % 30;
        var s = Math.floor(c / 15);
        var d = c % 15;
        var t = Math.floor(d / 7.5);

        var u = Math.floor(longitude - 100);
        var f = longitude - 100 - u;
        var v = Math.floor((f * 60) / 7.5);
        var g = (f * 60) % 7.5;
        var w = Math.floor((g * 60) / 45);
        var h = (g * 60) % 45;
        var x = Math.floor(h / 22.5);
        var i = h % 22.5;
        var y = Math.floor(i / 11.25);

        var m = (s * 2) + (x + 1);
        var n = (t * 2) + (y + 1);

        //1次メッシュ
        var mesh = '' + p + u;
        //2次メッシュ
        if(resolution >= 2){
            mesh = mesh + q + v;
            //3次メッシュ
            if(resolution >= 3){
                mesh = mesh + r + w;
                // 1/2メッシュ
                if(resolution >= 4){
                    mesh = mesh + m;
                    // 1/4メッシュ
                    if(resolution >=5){
                        mesh = mesh + n;
                    }
                }
            }
        }
        return mesh;
    }

    /**
     * 1桁0パディング。
     * @param 数値 val
     * @return パディング数値
     */
    function intVal2(val) {
        val = Math.floor(val);
        if (val < 10) {
            return '0' + val;
        }
        return '' + val;
    }

    /**
     * 緯度経度を5Kmメッシュコードに変換します。
     * @param 緯度 latitude
     * @param 経度 longitude
     * @return 5Kmメッシュコード
     */
    function _convertLatLngTo5KmMeshCode(latitude, longitude) {

        // メッシュ計算用に緯度経度をミリ秒に変換
        var lat = latitude * 3600000;
        var lng = longitude * 3600000;

        var lat1 = parseInt(lat / 3600000, 10);
        var lng1 = parseInt(lng / 3600000, 10);
        var lat2 = (lat % 3600000 / 3600000);
        var lng2 = (lng % 3600000 / 3600000);

        return lat1 + 
            (lng1 + '').substring(1, 3) +
            intVal2(lat2 * 20) +
            intVal2(lng2 * 16);
    }

    return {
        convertLatLngToMeshCode: _convertLatLngToMeshCode,
        convertLatLngTo5KmMeshCode: _convertLatLngTo5KmMeshCode
    };
});

