/**
 * ローディングの表示用モジュール。
 * @module idis/view/Loader
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/Deferred',
    'dojo/dom-class',
    'dijit/_WidgetBase',
    '../error/InvalidArgumentException'
], function(module, declare, lang, Deferred, domClass, _WidgetBase, InvalidArgumentException) {
    /**
     * 表示する際のクラス
     * @member DISPLAY_CLASS
     * @type {string}
     * @private
     */
    var DISPLAY_CLASS = 'is-shown';

    /**
     * Flyweight用
     * @member _loaderMap
     * @type {Object<string, module:idis/view/Loader~Loader>}
     * @private
     */
    var _loaderMap = {};

    /**
     * ローディング表示ウィジェット。
     * @class Loader
     * @extends module:dijit/_WidgetBase~_WidgetBase
     */
    var Loader = declare(module.id.replace(/\//g, '.'), _WidgetBase, /** @lends module:idis/view/Loader~Loader# */ {
        // ルート要素のCSSクラス
        baseClass: 'idis-Loader',

        /**
         * インスタンス識別用文字列
         * @type {string}
         * @private
         */
        key: null,

        /**
         * 画面上へ設置する。
         * @function module:idis/view/Loader~Loader#~postCreate
         * @protected
         */
        postCreate: function() {
            this.inherited(arguments);
            // 画面へ設置
            this.ownerDocumentBody.appendChild(this.domNode);
        },

        /**
         * Flyweight用のマップから自身を除去する。
         * @function module:idis/view/Loader~Loader#~destroy
         * @param {boolean} [preserveDom] trueが指定された場合、元のDOM構造を保持する
         */
        destroy: function() {
            delete _loaderMap[this.key];
            this.inherited(arguments);
        },

        /**
         * 表示されているかどうかを返す。
         * @returns {boolean} 表示されていればtrue、それ以外の場合はfalse
         */
        isShown: function() {
            return this.domNode && domClass.contains(this.domNode, DISPLAY_CLASS);
        },

        /**
         * 隠す。
         * @returns {Promise<module:idis/view/Loader~Loader>} 非表示完了時に解決する{@link Promise}。
         *     解決結果として自分自身を返す。
         */
        hide: function() {
            domClass.remove(this.domNode, DISPLAY_CLASS);
            var dfd = new Deferred();
            // WebKitにRepaintさせる
            setTimeout(lang.hitch(this, function() {
                dfd.resolve(this);
            }), 0);
            return dfd.promise;
        },

        /**
         * 表示する。
         * @returns {Promise<module:idis/view/Loader~Loader>} 表示完了時に解決する{@link Promise}。
         *     解決結果として自分自身を返す。
         */
        show: function() {
            // 画面上に表示
            domClass.add(this.domNode, DISPLAY_CLASS);
            // 誤操作防止のため、強制的にフォーカスを解除する
            if (document.activeElement) {
                document.activeElement.blur();
            }
            var dfd = new Deferred();
            // WebKitにRepaintさせる
            setTimeout(lang.hitch(this, function() {
                dfd.resolve(this);
            }), 0);
            return dfd.promise;
        },

        /**
         * ローディング表示を行い、指定されたPromiseが成功・失敗に関わらず完了したときに隠す。
         * 指定された引数がPromiseでない場合は何もせず、即時に完了するPromiseを返す。
         * @param {Promise|*} target ローディング表示を完了する条件となるPromise
         * @returns {Promise<*>} 非表示完了時に完了する{@link Promise}。target成功時は解決してtargetの結果を返す。
         * target失敗時は失敗してtargetのエラー内容を返す。
         * @example
         * Loader.wait(Requester.get('...')).then(function(data) {
         *     // リクエスト成功時の操作
         * }, function(err) {
         *     // リクエスト失敗時の操作
         * });
         */
        wait: function(target) {
            // Promiseで無いなら即解決
            if (!target || !target.always) {
                return new Deferred().resolve(target);
            }
            // ローダーを表示
            this.show();
            return target.always(lang.hitch(this, function(data) {
                // target解決時にローダーを隠す
                return this.hide().then(function() {
                    // 非表示後、targetの結果と値を後続のthenへ流す
                    if (target.isResolved()) {
                        return data;
                    } else {
                        throw data;
                    }
                });
            }));
        }
    });

    /**
     * {@link module:idis/view/Loader.get}の実体。
     * @function _get
     * @param {string} [key] インスタンスの識別文字列
     * @returns {module:idis/view/Loader~Loader}
     * @throws {module:idis/error/InvalidArgumentException~InvalidArgumentException}
     * @private
     */
    function _get(key) {
        // 引数省略時以外は偽値を許容しない
        if (arguments.length && (!key || !lang.isString(key))) {
            throw new InvalidArgumentException('key must be a non-empty string');
        }
        key = key || '';
        // 初出の場合はインスタンス化して返す
        return (_loaderMap[key] = _loaderMap[key] || new Loader({key: key}));
    }

    /**
     * 指定された識別子で登録されたインスタンスを返す。
     * @function module:idis/view/Loader.get
     * @param {string} [key] インスタンスの識別文字列
     * @returns {module:idis/view/Loader~Loader}
     * @example
     * Loader.get('wait-my-api-response').show().then(function(loader) {
     *     ...
     *     loader.hide();
     * });
     */
    Loader.get = _get;

    /**
     * デフォルト・ローダーを表示する。
     * @function module:idis/view/Loader.show
     * @returns {Promise<module:idis/view/Loader~Loader>} 表示完了時に解決する{@link Promise}。
     *     解決結果としてデフォルト・ローダー自身を返す。
     * @example
     * Loader.show().then(function(loader) {
     *     ...
     *     loader.hide();
     * });
     */
    Loader.show = function() {
        return _get().show();
    };

    /**
     * デフォルト・ローダーを隠す。
     * @function module:idis/view/Loader.hide
     * @returns {Promise<module:idis/view/Loader~Loader>} 非表示完了時に解決する{@link Promise}。
     *     解決結果としてデフォルト・ローダー自身を返す。
     * @example
     * Loader.hide();
     */
    Loader.hide = function() {
        return _get().hide();
    };

    /**
     * ローディング表示を行い、指定されたPromiseが成功・失敗に関わらず完了したときに隠す。
     * 指定された引数がPromiseでない場合は何もせず、即時に完了するPromiseを返す。
     * @param {Promise|*} target ローディング表示を完了する条件となるPromise
     * @returns {Promise<*>} 非表示完了時に完了する{@link Promise}。target成功時は解決してtargetの結果を返す。
     * target失敗時は失敗してtargetのエラー内容を返す。
     */
    Loader.wait = function(target) {
        return _get().wait(target);
    };

    return Loader;
});
