/**
* 観測所管理者コード（所管）を扱うモデル。
* @module app/model/ObservatoryManager
*/
define([], function() {
        // 管理者コードに対する名称と観測所名ラベル用のサフィックスを連想配列で返す
	return {
		// 雨量観測所
		'1': {name: '道（水防）', suffix: ''},
		'2': {name: '道（治山）', suffix: ''},
		'3': {name: '道（砂防）', suffix: ''},
		'4': {name: '市', suffix: ''},
		'5': {name: '国', suffix: '（国）'},
		'6': {name: '国（治山）', suffix: '（国）'},
		'7': {name: '国（砂防）', suffix: '（国）'},
		'8': {name: '中電', suffix: '（電）'},
		'9': {name: '気象庁', suffix: '（気）'},
		// 水位観測所
		'11': {name: '道（河川）', suffix: ''},
		'12': {name: '道（ダム）', suffix: ''},
		'13': {name: '道（農林）', suffix: ''},
		'14': {name: '国', suffix: '（国）'},
		'15': {name: '中電', suffix: '（電）'},
		// 潮位観測所
		'21': {name: '国（道）', suffix: ''},
		'22': {name: '道', suffix: ''}
	};
});
