/**
 * 初回に全データを取得し、以降はキャッシュを利用するdojo/store形式のStore。
 * @module idis/store/JsonLayerStore
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/Deferred',
    'dojo/Evented',
    'dojo/promise/all',
    'dojo/when',
    'dojox/lang/functional/array',
    'idis/service/Requester'
], function(module, array, declare, lang, Deferred, Evented, all, when, df, Requester) {
    /**
     * ツリー用。各要素を個別のJSONファイルとして取得する。
     *
     * 識別子として扱うプロパティー名はオプション指定可能。
     * 表示文字列として扱うプロパティー名はdijit/tree/model実装クラス側で設定する。
     *
     * @class JsonLayerStore
     * @extends module:dojo/store/api/Store
     * @param {Object} kwArgs
     * @param {string} [kwArgs.idProperty='id'] 識別子として扱うプロパティー
     * @param {string} [kwArgs.pathFormat='./{id}.json'] 識別子に対応するJSONファイルのパス
     * @example <caption>ファイル名の例</caption>
     * 1.json // '1'が識別子
     * @example <caption>JSONファイルのフォーマット</caption>
     * {
     *     id: 1, // 識別子（JsonLayerStoreのidPropertyはこのプロパティー名に合わせる）
     *     name: '要素1', // 表示ラベル（ただし、ルート要素の場合ツリー上は表示されない）
     *     children: [2, 3, 4] // 子要素の識別子一覧（識別子が数値の場合）
     * }
     */
    return declare(Evented, /** @lends module:idis/store/JsonLayerStore~JsonLayerStore# */ {
        /**
         * 識別子用プロパティー名
         * @type {string}
         */
        idProperty: 'id',

        /**
         * 親の識別子用プロパティー名
         * @type {string}
         */
        parentProperty: 'parentId',

        /**
         * JSONファイル名のフォーマット。
         * '{id}'という部分文字列を含む必要があり、
         * @type {string}
         */
        pathFormat: './{id}.json',

        /**
         * 取得済み要素のキャッシュ
         * @type {Object<number|string,Object>}
         */
        _cache: null,

        constructor: function(kwArgs) {
            lang.mixin(this, kwArgs);
            this._cache = {};
        },

        // 識別子の取得
        getIdentity: function(item){
            return item[this.idProperty];
        },

        /**
         * 要素の親要素の識別子を返す。
         * @param {Object} item 要素
         */
        getParentIdentity: function(item) {
            return item[this.parentProperty];
        },

        /**
         * 子要素一覧を返す。
         * @param {Object} item 要素
         * @param {number[]|string[]} item.children 要素の子要素識別子一覧
         * @returns {Promise<Object[]>} 子要素一覧
         */
        getChildren: function(item) {
            var parentId = this.getIdentity(item);
            return all(array.map(item.children, function(id) {
                return when(this.get(id)).then(lang.hitch(this, function(child) {
                    // チェックボックス対応用に親の識別子を付与
                    child[this.parentProperty] = parentId;
                    return child;
                }));
            }, this));
        },

        /**
         * 指定された識別子の要素の最新情報を取得し、変更をリスナーに対して通知する。
         * 要素の生成・削除・移動時はその要素自体に対してではなく、新旧の親要素に対して実行すること。
         * すなわち、生成・削除は親要素にとっての子要素一覧の変化として扱う。
         * @param {identifier} ツリー要素の識別子
         * @returns {Promise} 完了時に解決するPromise
         */
        refresh: function(id) {
            // キャッシュ済みでなければ何もしない
            if (!id && id !== 0 || !this._cache[id]) {
                return new Deferred().resolve();
            }
            // キャッシュ済みなら最新情報を取得
            var result = this.load(id);
            result.then(lang.hitch(this, function(item) {
                // 成功時は更新通知
                this.emit('refresh', {item: item});
            }));
            // 結果のPromiseを返す
            return result;
        },

        /**
         * キャッシュ済みの全要素の最新情報を取得し、変更をリスナーに対して通知する。
         * @returns {Promise<Object[]>} 全要素の取得結果を返すPromise
         */
        refreshAll: function() {
            return all(df.map(this._cache, function(value, key) {
                return this.refresh(key);
            }, this));
        },

        /**
         * 指定された識別子の要素の最新情報を読み込む。
         * @param {identifier} id 要素の識別子
         * @returns {Promise<Object>} 完了時に解決するPromise
         */
        load: function(id) {
            // ファイル名を算出
            var path = this.pathFormat.replace('{id}', id);
            // 結果をキャッシュ（return前にキャッシュ削除の可能性があるため一時変数に入れる）
            var result = this._cache[id] = Requester.get(path);
            result.otherwise(lang.hitch(this, function() {
                // エラー時はキャッシュを削除
                delete this._cache[id];
            }));
            // 結果のPromiseを返す
            return result;
        },

        // 識別子に対応する要素の取得、常にPromiseを返す
        get: function(id) {
            // キャッシュが無い場合に限りリクエスト
            return this._cache[id] || this.load(id);
        },

        // 問い合わせ用メソッド
        query: function() {
            throw new Error(module.id + '#query: メソッドが実装されていません');
        }
    });
});

