/**
 * 土砂災害危険度(1kmメッシュ)など, grib2から生成した画像タイルを表示するレイヤー
 * @module app/observation/map/Grib2MeshLayer
 */
define([
    'app/observation/map/ObservationRasterTMSLayer'
], function (ObservationRasterTMSLayer) {

        var _layer = ObservationRasterTMSLayer.extend({
        initialize: function (layerInfo) {
            this._checkTileUrl = [];

            ObservationRasterTMSLayer.prototype.initialize.call(this, layerInfo);
        }
    });

    return _layer;
});