/**
 * 情報ダイアログ
 */
define([
    'module',
    'dojo/_base/declare',
    './_OKDialogMixin',
    './IdisDialog'
], function(module, declare, _OKDialogMixin, IdisDialog) {
    /**
     * OKボタンのみを持つ情報ダイアログ
     */
    var InfoDialog = declare(module.id.replace(/\//g, '.'), [IdisDialog, _OKDialogMixin]);

    /**
     * OKボタンのみを持つダイアログを表示する。
     * 既に表示されている場合は複数表示せず、ダイアログの各表示文字列を置き換える。
     * @param {string} [title] ダイアログのタイトル部分
     * @param {string} message メッセージ
     */
    InfoDialog.show = function(title, message) {
        if (arguments.length === 0) {
            // メッセージは必須
            console.error(module.id + ': message is required');
        }
        if (arguments.length === 1) {
            // タイトル省略時
            message = title;
            title = '';
        }
        if (!InfoDialog._instance) {
            // singleton
            InfoDialog._instance = new InfoDialog({
                title: title,
                content: message
            });
        } else {
            InfoDialog._instance.set({
                title: title,
                content: message
            });
        }
        return InfoDialog._instance.show();
    };

    return InfoDialog;
});
