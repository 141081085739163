/**
 * 被害情報や事前情報等の表示情報を扱う。
 * @module app/model/LayerStore
 */
define([
    'idis/store/JsonLayerStore'
], function(JsonLayerStore) {
    /**
     * シングルトンを返す。
     */
    return new JsonLayerStore({
        pathFormat: '/data/layer/tree/{id}.json'
    });
});

