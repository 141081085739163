/**
 * OKボタンを持つダイアログ用のMixin
 * ConfirmDialogはInfoDialogに機能が類似しているが、
 * 両クラス間に親子関係は無いと考えられるため共通処理を切り出した。
 * このモジュール自体をインスタンス化することは想定しないためMixinとした。
 * @module idis/view/dialog/_OKDialogMixin
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/aspect',
    'dojo/text!./templates/infoActionBar.html',
    // 以下、変数で受けないモジュール
    '../form/Button'
], function(module, declare, lang, aspect, infoActionBarTemplate) {
    /**
     * OKボタンを持つダイアログ用のMixin。
     * @class _OKDialogMixin
     */
    return declare(module.id.replace(/\//g, '.'), null, {
        /**
         * ボタン領域のテンプレート文字列
         * @type {string}
         */
        actionBarTemplate: infoActionBarTemplate,

        /**
         * OKボタンが押されたときにダイアログを閉じるかどうか。
         * 閉じる前に何らかの処理待ちをさせたい場合はfalseにし、onOK内などで自分でhideを呼ぶこと。
         * @type {boolean}
         */
        hideOnOK: true,

        /**
         * キャンセルが押されたときにダイアログを閉じるかどうか。
         * 閉じる前に何らかの処理待ちをさせたい場合はfalseにし、onCancelAfter内などで自分でhideを呼ぶこと。
         * @type {boolean}
         */
        hideOnCancel: true,

        /**
         * このプロパティーがtrueの間、hideメソッドが無効化される。
         * @type [boolean}
         * @private
         */
        _preventHide: false,

        // DOM構築後に呼ばれる
        postCreate: function() {
            this.inherited(arguments);
            // キャンセル→hide後に呼ばれる
            aspect.after(this, 'onCancel', lang.hitch(this, function() {
                // hide無効化を解除
                this._preventHide = false;
                // キャンセル後の処理を実行
                this.onCancelAfter();
            }));
        },

        // ダイアログを非表示にする
        hide: function() {
            // hide無効化状態なら何もしない
            if (!this._preventHide) {
                return this.inherited(arguments);
            }
        },

        /**
         * OKボタンが押されたときに呼び出される。
         */
        _onOK: function() {
            this.onOK();
            if (this.hideOnOK) {
                this.hide();
            }
        },

        /**
         * OKボタンが押されたときに呼び出されるメソッド。
         * 子クラスや各インスタンスでオーバーライドする。
         */
        onOK: function() {
            // implement me
        },

        // キャンセル時に呼ばれるメソッド
        onCancel: function() {
            this.inherited(arguments);
            // 直後に呼ばれるhideを無効化する
            if (!this.hideOnCancel) {
                this._preventHide = true;
            }
        },

        /**
         * キャンセル→hide後に呼ばれるメソッド。
         */
        onCancelAfter: function() {
            // implement me
        }
    });
});
