/**
 * スネークライン画面の表示に使うデータのディレクトリを定義
 * @module app/map/snakeline/SnakelineDataDirectory.js
 */
define({
    // メッシュのマスターデータ
    master: '/data/master/sediment-mesh/mesh',

    // 雨量情報の時系列データ
    out: '/data/weather-mesh/sediment-snakeline',

    // 時系列のメッシュ一覧情報が入っているディレクトリ
    outMesh: '/data/weather-mesh/sediment-snakeline/mesh'
});
