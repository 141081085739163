define([
    'module',
    'dojo',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'esri-leaflet-vector',
    'leaflet',
    './BaseLayerOptions',
    '@maplibre/maplibre-gl-leaflet'
], function(module, dojo, array, declare, lang, esriLeafletVector, leaflet, BaseLayerOptions) {
    return new declare(module.id.replace(/\//g, '.'), null, {
        /**
         * 指定したレイヤーが背景地図レイヤーかどうかを判定する。
         * @param {ILayer} layer 確認対象のレイヤー
         * @returns {boolean} 指定したレイヤーが背景地図レイヤーかどうか
         */
        isBaseLayer: function(layer) {
            // 有効なbaseLayerIdを持っていれば背景地図と見なす
            return layer.options && (layer.options.baseLayerId || layer.options.baseLayerId === 0);
        },

        /**
         * 指定された地図オブジェクトに設置された背景地図レイヤー一覧を返す。
         * @param {Map} map 地図オブジェクト
         * @returns {ILayer[]} 背景地図レイヤーの配列
         */
        getBaseLayers: function(map) {
            var result = [];
            map.eachLayer(function(layer) {
                if (this.isBaseLayer(layer)) {
                    result.push(layer);
                }
            }, this);
            return result;
        },

        /**
         * Mapに対し背景地図レイヤーを設定する。
         * @param {Map} map 地図オブジェクト
         * @param {identifier} baseLayerId 背景地図の識別子（BaseLayerOptionsで定義）
         */
        setBaseLayer: function(map, baseLayerId) {
            // 元の背景地図レイヤーを取得
            // （ちらつきを防ぐため、削除→追加でなく追加→削除の順に行う準備）
            var oldBaseLayers = this.getBaseLayers(map);
            // 背景地図を地図に紐付ける
            array.some(BaseLayerOptions, lang.hitch(this, function(options) {
                if (options.id !== baseLayerId) {
                    return;
                }

                if (options.id === 'ESRI_ST') {
                    return esriLeafletVector.vectorBasemapLayer(options.itemId, {
                        apiKey: options.apiKey,
                        baseLayerId: options.id,
                        isVector: true,
                        attribution: options.attribution,
                        maxZoom: options.maxZoom,
                        pane: 'tilePane'
                    }).addTo(map);
                }
                if (options.id === 'OSM_ST') {
                    return esriLeafletVector.vectorBasemapLayer(options.styleId, {
                        apiKey: options.apiKey,
                        baseLayerId: options.id,
                        isVector: true,
                        attribution: options.attribution,
                        maxZoom: options.maxZoom,
                        pane: 'tilePane'
                    }).addTo(map);
                }
                if (options.id === 'GSI_VECTOR') {
                    var url = location.protocol + '//' + location.hostname;
                    url = location.port ? url + ':' + location.port : url;

                    var style = JSON.parse(JSON.stringify(this.getJsonFile('/data/maplibre-gl/gsiTile/style/optstd_style.json')));
                    style.sprite = url + style.sprite;


                    return new leaflet.maplibreGL({
                        baseLayerId: options.id,
                        style: style, // 初期表示時は空のstyleを設定する
                    }).addTo(map);
                }
                // 指定されたレイヤー設定が見つかったら設置して終了
                return new leaflet.TileLayer(options.url, {
                    baseLayerId: options.id,
                    attribution: options.attribution,
                    maxZoom: 18
                }).addTo(map).bringToBack();
            }));
            // 古い背景地図レイヤーを削除
            array.forEach(oldBaseLayers, function(layer) {
                map.removeLayer(layer);
            });
        },

        getJsonFile: function(targetFile) {
            // JSONファイルの読込
            var xhrArgs = {
                url: targetFile,
                handleAs: "json",
                sync: true,
                preventCache : true,
                load: function(data){
                },
                error: function(error){
                    // エラーダイアログ
                    console.log('JSON取得に失敗しました。' + error);
                    return null;
                }
            };
            return dojo.xhrGet(xhrArgs).results[0];
        }
    })();
});
