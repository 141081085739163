/**
 * 履歴管理を行う地図
 * @module idis/view/draw/history/HistoricalMap
 */
define([
    'leaflet',
    'dojo/_base/array',
    './DrawFeatureGroup',
    // 以下、変数で受けないモジュール
    'leaflet-draw',
    'leaflet-polylinedecorator',
    'leaflet-minimap',
    './_addCloneFuncs'
], function(leaflet, array, DrawFeatureGroup) {
    // 状態を保存する起点となるイベントの一覧
    var STATE_CHANGE_EVENTS = [
        leaflet.Draw.Event.EDITSTOP
    ];

    /**
     * 履歴情報を記憶する地図。
     * @class RecordMap
     */
    return leaflet.Map.extend({
        /**
         * 履歴情報の配列。
         * 各履歴情報はレイヤー識別子をキーとし、
         * 各子レイヤー情報を復元するための関数一覧を値とする辞書で表現される。
         * @type {Object<number,Array<function>>[]}
         */
        _history: null,

        /**
         * 履歴位置。
         * 履歴配列にこの位置よりも手前の索引があればUndoが可能。
         * 履歴配列にこの位置よりも後ろの索引があればRedoが可能。
         * @type {number}
         */
        _index: -1,

        // イベント登録など
        addHooks: function() {
            // superの呼び出し
            leaflet.Map.prototype.addHooks.apply(this, arguments);
            // 主要な作図イベント発生時に状態を保存する
            this.on(STATE_CHANGE_EVENTS, this.pushState, this);
        },

        // イベント削除など
        removeHooks: function() {
            // addHooksしたイベントを解除する
            this.off(STATE_CHANGE_EVENTS, this.pushState);
            // superの呼び出し
            leaflet.Map.prototype.removeHooks.apply(this, arguments);
        },

        /**
         * レイヤー集合に所属する各レイヤーを復元するためのファクトリー関数一覧を返す。
         * @param {LayerGroup} layerGroup レイヤー集合
         */
        _getCloneFuncs: function(layerGroup) {
            return array.map(layerGroup.getLayers(), function(layer) {
                // 各レイヤーのコピーを生成するための関数を取得する
                return layer.cloneFunc();
            });
        },

        /**
         * レイヤーがundo/redo対象かどうかを返す。
         * @param {Layer} layer 確認対象レイヤー
         * @returns {boolean} undo/redo対象かどうか
         */
        _isHistoryTarget: function(layer) {
            return layer instanceof DrawFeatureGroup;
        },

        // レイヤー追加メソッド
        addLayer: function(layer) {
            // superの呼び出し
            leaflet.Map.prototype.addLayer.apply(this, arguments);
            // 作図レイヤーの場合は状態を保存
            if (this._isHistoryTarget(layer)) {
                this.pushState();
            }
        },

        /**
         * 現在の状態を保存する。
         */
        pushState: function() {
            // 履歴を進める
            ++this._index;
            // レイヤーの識別子をキー、状態を値とする辞書
            var state = {};
            // 現在地以降の履歴を削除し、現在地に新しい履歴を入れる
            // 例：現在のindexが2で履歴が5個(0-4)の場合、2,3,4を削除するのでsplice(2, 5 - 2, state)となる
            this._history.splice(this._index, this._history.length - this._index, state);
            // 地図に登録された各レイヤーの状態を保存する
            this.eachLayer(function(layer) {
                // 状態保存対象は限定する
                if (this._isHistoryTarget(layer)) {
                    var layerId = leaflet.stamp(layer);
                    state[layerId] = this._getCloneFuncs(layer);
                    console.debug('layer saved: id=' + layerId);
                }
            }, this);
        },

        // Leafletのコンストラクター
        initialize: function() {
            // superの呼び出し
            leaflet.Map.prototype.initialize.apply(this, arguments);
            // 履歴情報の初期化
            this._history = [];
            // this.pushState();
        },

        /**
         * Redo可能かどうかを返す。
         * @returns {boolean} Undo可能かどうか
         */
        canRedo: function() {
            // 未来情報があればtrue
            return this._index + 1 < this._history.length;
        },

        /**
         * Undo可能かどうかを返す。
         * @returns {boolean} Undo可能かどうか
         */
        canUndo: function() {
            // 過去情報があればtrue
            return this._index > 0;
        },

        /**
         * 最後の履歴情報を削除する。
         * キャンセル時など、Undoを通さずに現在の状態が
         * 履歴の最後の状態に戻った場合に、履歴の末尾を破棄するためのメソッド。
         */
        removeLastState: function() {
            if (!this.canUndo()) {
                console.warn('no history');
                return;
            }
            // 履歴を1つ戻す
            --this._index;
            this._history.pop();
        },

        /**
         * 指定された履歴情報を適用する。
         * @param {object} state 履歴情報
         * @param {LayerGroup} state.layer 履歴に紐付くレイヤー集合
         * @param {Layer[]} state.factoryFuncs レイヤー集合に復元するレイヤー一覧
         */
        _applyState: function(state) {
            this.eachLayer(function(layer) {
                // 現在のレイヤーに紐付く復元関数一覧を取得
                var layerId = leaflet.stamp(layer);
                var factoryFuncs = state[layerId];
                if (factoryFuncs) {
                    // 矢印があれば先にHeadを削除
                    this._arrowUtil(layer, 'remove');
                    // レイヤーが復元対象に入っている場合
                    // 元の情報を削除
                    layer.clearLayers();

                    // 復元情報を適用
                    var that = this;
                    array.forEach(factoryFuncs, function(func) {
                        layer.addLayer(func());
                        that._arrowUtil(layer, 'add');
                    });
                    console.debug('layer loaded: id=' + layerId);
                }
            }, this);
        },

        /**
         * Redoする。
         */
        redo: function() {
            if (!this.canRedo()) {
                console.warn('no future');
                return;
            }
            // 履歴を1つ進める
            ++this._index;
            this._applyState(this._history[this._index]);
        },

        /**
         * Undoする。
         */
        undo: function() {
            if (!this.canUndo()) {
                console.warn('no history');
                return;
            }
            // 履歴最後のレイヤー集合の現在の状態を保存
            // var lastStateTarget = this._history[this._index - 1].layer;
            // this.saveState(lastStateTarget);
            // 履歴を1つ戻す
            --this._index;
            this._applyState(this._history[this._index]);
        },

        // インスタンスを破棄する。
        remove: function() {
            // このクラス固有の参照を片付ける
            this._history = null;
            // superの呼び出し
            leaflet.Map.prototype.remove.apply(this, arguments);
        },

        _arrowUtil: function(layer, type) {
            // FIXME 個別対応をやめる.  オブジェクトが増えたときのパフォーマンス
            Object.keys(layer._layers).forEach(function(key){
                var aLayer = layer._layers[key];
                if (aLayer.options.drawType === 'arrow') {
                    if (type === 'add') {
                        aLayer.setArrowHead(aLayer.options);
                    } else { // type === 'remove'
                        if(aLayer.options.bothArrow === true) {
                            for(var i = 0; i <= aLayer.options.arrowHead.length -1; i++) {
                                layer._map.removeLayer(aLayer.options.arrowHead[i]);
                            }
                        } else {
                            layer._map.removeLayer(aLayer.options.arrowHead);
                        }
                    }
                }
            });
        }
    });
});
