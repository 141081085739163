/**
 * ズーム・レベルに対応する縮尺（1/xのxに当たる数値）
 * @module idis/map/SCALE
 */
define({
    7: 3600000,
    8: 1800000,
    9: 900000,
    10: 450000,
    11: 220000,
    12: 100000,
    13: 56000,
    14: 28000,
    15: 14000,
    16: 7000,
    17: 4000,
    18: 2000
});
