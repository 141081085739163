define([
    'leaflet',
    './CustomDivIcon',
    './CustomIcon',
    './_SetStyleMixin'
], function(leaflet, CustomDivIcon, CustomIcon, SetStyleMixin) {
    /* add cloneFunc function to Features */
    leaflet.Polyline.include({
        cloneFunc: function () {
            var latLngs = leaflet.LatLngUtil.cloneLatLngs(this.getLatLngs()),
                options = this.options,
                popup = this._popup,
                layerInfo = this.layerInfo;
            return function () {
                var ret = new leaflet.Polyline(latLngs, options);
                if (popup) {
                    ret.bindPopup(popup);
                }
                if (layerInfo) {
                    ret.layerInfo = layerInfo;
                }
                return ret;
            };
        },
        getType: function () {
            return leaflet.Draw.Polyline.TYPE;
        }
    });

    leaflet.Polygon.include({
        cloneFunc: function () {
            var latLngs = leaflet.LatLngUtil.cloneLatLngs(this.getLatLngs()),
                options = this.options,
                popup = this._popup,
                layerInfo = this.layerInfo;
            return function () {
                var ret = new leaflet.Polygon(latLngs, options);
                if (popup) {
                    ret.bindPopup(popup);
                }
                if (layerInfo) {
                    ret.layerInfo = layerInfo;
                }
                return ret;
            };
        },
        getType: function () {
            return leaflet.Draw.Polygon.TYPE;
        }
    });

    leaflet.Rectangle.include({
        cloneFunc: function () {
            var bounds = this.getBounds(),
                options = this.options,
                popup = this._popup,
                layerInfo = this.layerInfo;
            return function () {
                var ret = new leaflet.Rectangle(bounds, options);
                if (popup) {
                    ret.bindPopup(popup);
                }
                if (layerInfo) {
                    ret.layerInfo = layerInfo;
                }
                return ret;
            };
        }
    });

    leaflet.Circle.include({
        cloneFunc: function () {
            var latLng = leaflet.LatLngUtil.cloneLatLng(this.getLatLng()),
                radius = this.getRadius(),
                options = this.options,
                popup = this._popup,
                layerInfo = this.layerInfo;
            return function () {
                var ret = new leaflet.Circle(latLng, radius, options);
                if (popup) {
                    ret.bindPopup(popup);
                }
                if (layerInfo) {
                    ret.layerInfo = layerInfo;
                }
                return ret;
            };
        },
        getType: function () {
            return leaflet.Draw.Circle.TYPE;
        }
    });

    leaflet.Marker.include({
        cloneFunc: function () {
            var latLng = leaflet.LatLngUtil.cloneLatLng(this.getLatLng()),
                options = this.options,
                popup = this._popup,
                layerInfo = this.layerInfo;
            return function () {
                var ret = null;
                if (options.drawType === 'DivIcon') {
                    ret = new leaflet.Marker(latLng, {
                        icon : new CustomDivIcon(options.icon.options)
                    });
                    ret = SetStyleMixin._setUpperOptions(ret);
                } else {
                    ret = new leaflet.Marker(latLng, {
                        icon: new CustomIcon(options.icon.options)
                    });
                    ret = SetStyleMixin._setUpperOptions(ret);
                    if (popup) {
                        ret.bindPopup(popup);
                    }
                    if (layerInfo) {
                        ret.layerInfo = layerInfo;
                    }
                }
                return ret;
            };
        },
        getType: function () {
            return leaflet.Draw.Marker.TYPE;
        }
    });
});
