/**
 * ラウター用モジュール
 *
 * @module app/control/AdisConvoRouter
 */
define(['module', 'dojo/_base/declare', 'dojo/_base/lang', 'dojo/has',
    'idis/control/Router', 'idis/model/UserInfo', 'idis/consts/ACL'],
    function(module, declare, lang, has, Router, UserInfo, ACL) {
        /**
         * ラウター
         *
         * @class AdisConvoRouter
         * @extends module:idis/control/Router~Router
         */
        return declare(module.id.replace(/\//g, '.'), Router, /** @lends app/control/AdisConvoRouter */
        {
            // 初期化処理
            init: function() {
            // ユーザ情報に応じてURLのマッピングを登録
            return UserInfo.load().then(lang.hitch(this, function() {

                // いったん誰でも開くことができるページをデフォルトにする
                this.register('app/convocation/view/mail/ConvocationMailAdminPage');

                if (UserInfo.hasAuthz('F08001')) {
                    this.register({
                        'convocation/mail': 'app/convocation/view/mail/ConvocationMailAdminPage',
                        'convocation/statusDetail': 'app/convocation/view/mail/ConvoStatusDetailPage',
                        'convocation/statusList': 'app/convocation/view/mail/ConvoStatusListPage',
                        'convocation/notificationList': 'app/convocation/view/mail/NotificationListPage',
                        'convocation/notificationDetail': 'app/convocation/view/mail/NotificationDetailPage'
                    });
                }
                if (UserInfo.hasAuthz('F08002')) {
                    this.register({
                        'convocation/employee': 'app/convocation/view/employee/ConvocationEmployeeAdminPage',
                        'convocation/group': 'app/convocation/view/group/ConvocationGroupAdminPage',
                        'convocation/groupRegister': 'app/convocation/view/group/ConvoGroupRegisterPage',
                        'convocation/groupDetail': 'app/convocation/view/group/ConvoGroupDetailPage',
                        'convocation/automail': 'app/convocation/view/condition/ConvocationAutoCondAdminPage',
                        'convocation/autoMailRegister': 'app/convocation/view/condition/ConvoAutoMailRegisterPage',
                        'convocation/autoMailDetail': 'app/convocation/view/condition/ConvoAutoMailDetailPage',
                        'convocation/mailTemp': 'app/convocation/view/mail/ConvoMailTempPage',
                    });
                    if (UserInfo.getRoleCd() === ACL.ADMIN_USER) {
                        this.register({
                            'convocation/user': 'app/convocation/view/user/UserAdminPage'
                        });
                    }
                }

            // ユーザ情報が取れない場合は常にログイン・ページを表示
            }), lang.hitch(this, function() {
                    // 本番用ログイン
                    this.register('app/convocation/view/ConvoLoginPage');
            }));
        }
    });
});
