

/**
 * 土砂災害危険度スネークライン画面へ遷移するための地図レイヤー
 * 引いた地図では行政界を表示し, 市区町村を選択するとズームインして1kmメッシュを表示する
 * layerをextendしたものではなく, layerをもったコンテナのようなモジュール.
 *
 * @module app/map/snakeline/SnakelineGuideLayerContainer.js
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'idis/control/Locator',
    'idis/consts/QUERY',
    'leaflet',
    'app/map/layer/MunicipalityLayer',
    'app/observation/map/Grib2MeshLayer',
    'app/map/snakeline/SnakelineMeshLayer',
    'app/map/snakeline/KeyName',
    'idis/view/dialog/InfoDialog'
], function (
    module, declare, lang,
    Locator, QUERY,
    leaflet,
    MunicipalityLayer, Grib2MeshLayer, SnakelineMeshLayer, KeyName,
    InfoDialog
) {
    return declare(module.id.replace(/\//g, '.'), null, {

        thresholdZoomLevel: 12, // lowerとupperを分ける境目となるzoomlevel
        currentLayer: undefined,

        constructor: function (layerInfo, option) {
            // 空のレイヤーグループから始める
            this.layerGroup = leaflet.layerGroup([]);

            // 表示対象となる3種類のレイヤーを作る
            // --- 行政界
            // クリックしたらそのポイントにズームインさせる
            this.municipalityLayer = new MunicipalityLayer(layerInfo, option);
            this.municipalityLayer.on('click', this._onMunicipalityClick);

            // --- 土砂災害危険度メッシュ
            // 行政界の下に入れて色を表示
            this.sedimentRiskMeshLayer = new Grib2MeshLayer({
                layerUrl: '/data/weather-mesh/sediment-risk'
            });
            this.sedimentRiskMeshLayer.setOpacity(0.7);

            // --- 1kmメッシュ
            // それぞれのメッシュの状況を表すファイル格納先
            var meshLayerInfo = layerInfo || {
                statusUrl: '/data/snakeline'
            };

            // ズームレベル12を引き伸ばして使うようにする
            var meshOption = option || {
                maxNativeZoom: 12
            };

            // メッシュをクリックしたらそのメッシュの詳細ページに飛ぶ
            var geojsonOption = {
                onEachFeature: this._onEachMesh
            };

            this.meshLayer = new SnakelineMeshLayer(meshLayerInfo, meshOption, geojsonOption);

            // mapのイベントをsubscribe
            Locator.on('change', lang.hitch(this, this.onLocationChanged));

            // ロード時点のズームレベルから出すものを決める
            this.onLocationChanged();
        },

        getLayer: function () {
            return this.layerGroup;
        },

        // URL変更時の処理
        onLocationChanged: function () {
            // 現在のクエリー情報を取得
            var query = Locator.getQuery();

            // ズームレベルが変わった時にonZoomLevelを動かす
            if (!this._lastQuery || query[QUERY.ZOOM] !== this._lastQuery[QUERY.ZOOM]) {
                this.onZoomLevel(query[QUERY.ZOOM]);
            }

            // 次の更新に備えてクエリー状態を保存
            this._lastQuery = query;
        },

        /**
         * 地図のズームレベルが変わった時の処理
         * @param {*} level
         */
        onZoomLevel: function (level) {
            // 地図が寄った時で, 変更前ズームレベルが閾値未満(=現在表示中のレイヤーが引いたとき用)だったら
            // 寄ったとき用のレイヤーを出す
            if (level >= this.thresholdZoomLevel &&
                (!this.currentLayer || this.currentLayer !== 'upper')) {
                this.currentLayer = 'upper';
                this._loadUpperLayer();

            // 地図が引いた時で, 変更前ズームレベルが閾値以上(=現在表示中のレイヤーが寄ったとき用)だったら
            // 引いたとき用のレイヤーを出す
            } else if (level < this.thresholdZoomLevel &&
                (!this.currentLayer || this.currentLayer !== 'lower')) {
                this.currentLayer = 'lower';
                this._loadLowerLayer();
            }
        },

        refresh: function() {
            // 一旦非表示にして
            this.layerGroup.clearLayers();
            this.sedimentRiskMeshLayer.refresh();
            this.meshLayer.refresh();

            // 再表示
            this.currentLayer = undefined;
            this.onZoomLevel(this._lastQuery[QUERY.ZOOM]);
        },

        /**
         * 寄ったときのレイヤー表示
         */
        _loadUpperLayer: function () {
            this.layerGroup.clearLayers();
            this.layerGroup.addLayer(this.meshLayer);
        },

        /**
         * 引いたときのレイヤー表示
         */
        _loadLowerLayer: function () {
            this.layerGroup.clearLayers();
            this.layerGroup.addLayer(this.sedimentRiskMeshLayer);
            this.layerGroup.addLayer(this.municipalityLayer);
        },

        /**
         * 行政界の領域をクリックした時の処理
         * クリックしたポイントにズームインさせる
         * @param {Event} e
         */
        _onMunicipalityClick: function (e) {
            var state = {};
            state[QUERY.LATLNG] = [e.latlng.lat, e.latlng.lng].join(','); // 緯度/経度をカンマでつないだ文字列
            state[QUERY.ZOOM] = 12;

            Locator.replaceState(state);
        },

        /**
         * メッシュをクリックした時の処理
         * @param {object} feature
         * @param {object} layer
         */
        _onEachMesh: function (feature, layer) {
            layer.on('click', function () {
                // メッシュIDが入っていたらページ遷移
                if (feature.properties[KeyName.keyName]) {
                    window.open('?p=snakeline&meshId=' + feature.properties[KeyName.keyName]);
                } else {
                    // メッシュIDが入っていなかったらエラーダイアログを表示
                    if (!this.infoDialog) {
                        this.infoDialog = new InfoDialog({
                            title: 'エラー',
                            content: '選択した地点に対するスネークラインの情報が存在しません。'
                        });
                    }
                    this.infoDialog.show();
                }
            });
        }
    });
});