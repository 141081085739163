define([
    'leaflet'
], function(leaflet) {
    return leaflet.Icon.extend({
        options:{
            drawType    : 'marker',
            iconUrl     : '../../../images/draw/icon/080.png',
            iconSize    : [20,20],
            iconAnchor  : [10,10]
        },
        initialize: function(options){
            leaflet.setOptions(this, options);
        }
    });
});
