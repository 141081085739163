/**
 * 標準的な基底クラスを定義する。
 * @module idis/view/_IdisWidgetBase
 */
define([
    'module',
    'dojo/_base/declare',
    'dijit/_WidgetBase',
    'dijit/_TemplatedMixin',
    'dijit/_WidgetsInTemplateMixin'
], function(module, declare, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin) {
    /**
     * 標準的なウィジェットで必要となるクラスを継承した基底クラス。
     * @class _IdisWidgetBase
     * @extends module:dijit/_WidgetBase~_WidgetBase
     * @extends module:dijit/_TemplatedMixin~_TemplatedMixin
     * @extends module:dijit/_WidgetsInTemplateMixin~_WidgetsInTemplateMixin
     */
    return declare(module.id.replace(/\//g, '.'), [_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin]);
});

