/**
 * アプリ固有のユーティリティーを提供。
 * @module app/AppUtils
 */
define([
    'dojo/dom-class'
], function(domClass) {
    /**
     * 指定された文字列が偽値や『空白文字のみからなる文字列』ならtrue、
     * それ以外ならfalseを返す。
     * @function isEmpty
     * @param {string} 検証対象文字列
     * @returns {boolean} ある値が偽値や『空白文字のみからなる文字列』かどうか
     */
    function _isEmpty(str) {
        return !str || !str.trim();
    }

    /**
     * 現在の画面がモバイル版かどうかを返す。
     * @function isMobile
     * @returns {boolean} 現在の画面がモバイル版かどうか
     */
    function _isMobile() {
        return domClass.contains(document.body, 'mobile');
    }

    return {
        isEmpty: _isEmpty,
        isMobile: _isMobile
    };
});

