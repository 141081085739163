/**
 * XHRリクエストでテストをするための中間レイヤー。
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/Deferred',
    'dojo/json',
    'dojo/request',
    '../consts/CONTENT_TYPE',
    '../util/TypeUtils'
], function(module, declare, lang, Deferred, JSON, request, CONTENT_TYPE, TypeUtils) {
    return {
        /**
         * 自動応答ルール一覧
         * @type{object}
         */
        _registry: [],

        /**
         * 指定されたURLに対しリクエストが来た場合に
         * 代わりに指定されたレスポンスを返す層を登録する。
         * 後から登録したものを優先的に利用。
         * @param {string|RegExp|function} url 返答するURLのパターン
         * @param {*} responder 応答値
         */
        register: function(url, responder) {
            this._registry.unshift({url: url, responder: responder});
        },

        /**
         * 自動応答をクリア。
         */
        clearRegistry: function() {
            this._registry = [];
        },

        /**
         * HTTPメソッドを含めて指定する場合。
         * @param {string} url リクエスト先URL
         * @param {object} options オプション
         * @param {boolean} [options.preventCache=true] キャッシュ利用を回避するか
         * @param {string} options.method HTTPメソッド
         * @param {string} [options.handleAs='json'] レスポンスをどのようなデータとして扱うか
         */
        request: function(url, options) {
            // デフォルト引数の反映
            options = lang.mixin({
                preventCache: true,
                handleAs: 'json'
            }, options);
            // デフォルトHTTPヘッダーの反映
            options.headers = lang.mixin({
                'Content-Type': CONTENT_TYPE.JSON
            }, options.headers);
            // JSONリクエストでただのオブジェクトが渡って来た場合は自動変換
            if (lang.isObject(options.data) && options.headers['Content-Type'] === CONTENT_TYPE.JSON) {
                options.data = JSON.stringify(options.data);
            }
            // 事前登録したルールに一致するURLは自動応答
            for (var i = 0; i < this._registry.length; i++) {
                var r = this._registry[i];
                if (lang.isString(r.url) && r.url === url ||
                    lang.isFunction(r.url) && r.url(url, options) || TypeUtils.isRegExp(r.url) && r.url.exec(url)) {
                    // responderが関数ならその返り値を結果とする
                    var result = lang.isFunction(r.responder) ? r.responder(url, options) : r.responder;
                    // 結果がPromiseでなければ解決済みPromiseで包んで返す
                    return result && result.then ? result : new Deferred().resolve(result);
                }
            }
            // 該当するルールが無ければ本物のリクエストを実施
            return request(url, options);
        },

        /**
         * GETメソッド用。
         * @param {string} url リクエスト先URL
         * @param {object} options オプション
         * @param {boolean} [options.preventCache=true] キャッシュ利用を回避するか
         * @param {string} [options.handleAs='json'] レスポンスをどのようなデータとして扱うか
         */
        get: function(url, options) {
            return this.request(url, lang.mixin(null, options, {method: 'GET'}));
        },

        /**
         * POSTメソッド用。
         * @param {string} url リクエスト先URL
         * @param {object} options オプション
         * @param {string} [options.handleAs='json'] レスポンスをどのようなデータとして扱うか
         */
        post: function(url, options) {
            return this.request(url, lang.mixin(null, options, {method: 'POST'}));
        },

        /**
         * PUTメソッド用。
         * @param {string} url リクエスト先URL
         * @param {object} options オプション
         * @param {string} [options.handleAs='json'] レスポンスをどのようなデータとして扱うか
         */
        put: function(url, options) {
            return this.request(url, lang.mixin(null, options, {method: 'PUT'}));
        },

        /**
         * DELETEメソッド用。
         * 'delete'はJavaScriptの予約語なので、メソッド名はdelとする。
         * @param {string} url リクエスト先URL
         * @param {object} options オプション
         * @param {string} [options.handleAs='json'] レスポンスをどのようなデータとして扱うか
         */
        del: function(url, options) {
            options = lang.mixin(null, options, {method: 'DELETE', preventCache: false});
            if (options.data) {
                console.warn(module.id + '#del: DELETEのオプションにdataは指定出来ません: ' + options.data);
            }
            // options.dataを確実に消す
            delete options.data;
            return this.request(url, options);
        }
    };
});
