/**
 * 確認ダイアログ
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/dom-style',
    'dojo/text!./templates/confirmActionBar.html',
    './_OKDialogMixin',
    './IdisDialog'
], function(module, declare, domStyle, confirmActionBarTemplate, _OKDialogMixin, IdisDialog) {
    return declare(module.id.replace(/\//g, '.'), [IdisDialog, _OKDialogMixin], {
        /**
         * ボタン領域のテンプレート文字列
         * @type {string}
         */
        actionBarTemplate: confirmActionBarTemplate,

        /**
         * OKボタンが押されたときにダイアログを自動で閉じない
         * @type {boolean}
         */
        hideOnOK: false,

        /**
         * キャンセル・ボタンの表示状態を設定する
         * @type {boolean}
         */
        showCancel: true,

        // キャンセル・ボタンの表示状態を更新する。
        _setShowCancelAttr: function(showCancel) {
            // キャンセル・ボタンのDOMノードが存在する場合だけ実行
            var cancelButtonNode = this.cancelButton && this.cancelButton.domNode;
            if (cancelButtonNode) {
                // CSSのdisplayプロパティーで切り替える
                domStyle.set(cancelButtonNode, 'display', showCancel ? '' : 'none');
            }
            // プロパティーとして保持
            this._set('showCancel', showCancel);
        }
    });
});
