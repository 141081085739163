/**
 * 不正な引数指定時のエラー用モジュール。
 * @module idis/error/InvalidArgumentException
 */
define([
    'dojo/errors/create'
], function(create) {
    /**
     * 不正な引数指定時に投げるエラー。
     * @class InvalidArgumentException
     * @extends Error
     * @param {string} [message] エラー・メッセージ
     */
    return create('InvalidArgumentException');
});
