/**
 * parser.parse()中に発生したエラーを出力するようにする。
 * @module idis/view/parser
 */
define([
    'dojo/aspect',
    'dojo/has',
    'dojo/parser'
], function(aspect, has, parser) {
    if (has('dojo-debug-messages')) {
        // dojoConfig.isDebugがtrueの場合
        aspect.after(parser, 'parse', function(promise) {
            // parser.parse()の返すPromiseが失敗した場合はエラー内容を出力する
            promise.otherwise(function(err) {
                console.error('parser.parse()中にエラーが発生しました:', err && err.stack || err);
            });
            return promise;
        });
    }
    // dojo/parserを返す
    return parser;
});

