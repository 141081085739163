/**
 * locationに含まれるパラメーター種別を定数として定義
 * @module idis/consts/QUERY
 */
define({
    // 背景地図の種類
    BASE_LAYER_ID: 'bl',
    // 詳細ペインに表示するレイヤーの識別子
    DETAIL_LAYER_ID: 'di',
    // 緯度経度
    LATLNG: 'll',
    // レイヤー一覧
    LAYER_LIST: 'l',
    // 画面の識別子
    PAGE: 'p',
    // ズーム・レベル
    ZOOM: 'z'
});
