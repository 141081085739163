define([
    'module',
    'dojo/_base/declare',
    'dojo/text!./templates/EvacRecommendPane.html',
    'idis/view/_IdisWidgetBase'
], function(module, declare, template, _IdisWidgetBase) {
    return declare(module.id.replace(/\//g, '.'), _IdisWidgetBase, {
        // テンプレート文字列
        templateString: template,

        // ウィジェットのルート要素に付与されるCSSクラス
        baseClass: 'evacRecommendPane',

        // DOMを構築する
        buildRendering: function() {
            this.inherited(arguments);
        },

    });
});
