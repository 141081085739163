/**
 * 作図レイヤー・グループ
 * @module idis/view/draw/DrawFeatureGroup
 */
define([
    'leaflet'
], function(leaflet) {
    // 作図レイヤーを見分けることが目的のため、内容は特に追加しない
    return leaflet.FeatureGroup.extend({});
});
