/**
 * ラウター用モジュール
 *
 * @module app/control/AdisSpecteeRouter
 */
define(['module', 'dojo/_base/declare', 'dojo/_base/lang', 'dojo/has',
    'idis/control/Router', 'idis/model/UserInfo', 'idis/consts/ACL'],
    function(module, declare, lang, has, Router, UserInfo, ACL) {
        /**
         * ラウター
         *
         * @class AdisConvoRouter
         * @extends module:idis/control/Router~Router
         */
        return declare(module.id.replace(/\//g, '.'), Router, /** @lends app/control/AdisSpecteeRouter */
        {
            // 初期化処理
            init: function() {
            // ユーザ情報に応じてURLのマッピングを登録
            return UserInfo.load().then(lang.hitch(this, function() {

                // いったん誰でも開くことができるページをデフォルトにする
                this.register('app/spectee/mobile/SpecteeMobileAdminPage');

                if (UserInfo.hasAuthz('F06008')) {
                    this.register({
                        'mobile/spectee': 'app/spectee/mobile/SpecteeMobileAdminPage'
                    });
                }

            // ユーザ情報が取れない場合は常にログイン・ページを表示
            }), lang.hitch(this, function() {
                    // 本番用ログイン
                    this.register('app/spectee/mobile/SpecteeMobileAdminPage');
            }));
        }
    });
});
