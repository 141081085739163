/**
 * 標準ダイアログ内に設置するとshow/hideに反応出来るようにする。
 * @module idis/view/dialog/_DialogContentMixin
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/aspect'
], function(module, declare, lang, aspect) {
    /**
     * 標準ダイアログ内に設置するとshow/hideに反応出来るようになるMixin
     * @class _DialogContentMixin
     */
    return declare(module.id.replace(/\//g, '.'), null, {
        /**
         * ダイアログのshow/hide時にonShow/onHideを実行するよう紐付ける。
         * @param {module:dijit/Dialog~Dialog} dialog 紐付け対象のダイアログ
         */
        listen: function(dialog) {
            var handles = [
                aspect.before(dialog, 'show', lang.hitch(this, function() {
                    this.onDialogShow();
                    return arguments;
                })),
                aspect.before(dialog, 'hide', lang.hitch(this, function() {
                    this.onDialogHide();
                    return arguments;
                }))
            ];
            // 破棄される際にハンドルを解除
            this.own.apply(this, handles);
            // ダイアログ破棄の際も解除
            dialog.own.apply(this, handles);
        },

        /**
         * ダイアログが表示される際に実行される処理。
         */
        onDialogShow: function() {
            console.warn(module.id + '#onShow: not yet implemented');
        },

        /**
         * ダイアログを隠す際に実行される処理。
         */
        onDialogHide: function() {
            console.warn(module.id + '#onHide: not yet implemented');
        }
    });
});
