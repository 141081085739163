/**
 * スネークラインの詳細画面に繋ぐための1kmメッシュレイヤー
 * メッシュ状況一覧のjsonを取得して, レベルごとに色をつける
 *
 */
 define([
    'dojo/_base/lang',
    'dojo/_base/array',
    'dojo/Deferred',
    'leaflet',
    'idis/service/Requester',
    'app/observation/LatestData',
    'app/map/snakeline/VectorMeshLayer',
    'app/map/snakeline/SnakelineDataDirectory',
    'app/map/snakeline/MeshColor',
    'app/map/snakeline/KeyName'
], function(
    lang, array, Deferred,
    leaflet,
    Requester,
    LatestData, VectorMeshLayer, SnakelineDataDirectory, MeshColor, KeyName
) {
    var SnakelineMeshLayer = VectorMeshLayer.extend({

        statuses: {}, // メッシュごとのレベル情報を入れる
        latestTimestamp: null,

        /**
         * レイヤー初期か処理
         * @param {*} layerInfo tree.jsonに入っているレイヤー定義情報
         * @param {*} options leaflet.GeoJSONTileLayerのoptionsに入れるべきおの
         * @param {*} geojsonOptions styleなど, leaflet.GeoJSONのoptionに入れるべきもの
         */
        initialize: function(layerInfo, options, geojsonOptions) {
            // styleの中でメッシュごとのレベル情報を入れるので, lang.hitchしてこのモジュールを束縛したfunctionオブジェクトを渡す
            geojsonOptions.style = lang.hitch(this, this.styleWithStatus);

            // 一旦レベル情報のロードがない状態でレイヤーを作る
            VectorMeshLayer.prototype.initialize.call(this, layerInfo, options, geojsonOptions);

            this.latestData = new LatestData({ url: SnakelineDataDirectory.out + '/data.json' });
            this.refresh();
        },

        refresh: function() {
            // メッシュごとのレベル情報をロード
            this.getLatestData().then(lang.hitch(this, function(result) {
                if (!result) {
                    return;
                }

                this.statuses = result;

                // setStyleを呼び出すと, 各メッシュについてstyleメソッドが再度呼ばれる.
                // この結果, メッシュごとのレベル情報を反映した色付けが行われる
                this.geojsonLayer.setStyle(lang.hitch(this,this.styleWithStatus));
            }));
        },

        /**
         * メッシュ一覧情報の最新を取得する
         */
        getLatestData: function() {

            var deferred = new Deferred();

            // スネークライン情報の最新時刻を取得
            this.latestData.refresh().then(lang.hitch(this, function() {
                var latest = this.latestData.getLatestDateTimestamp().replace(
                        /(\d{4}-\d{2}-\d{2}) (\d{2}):(\d{2}):\d{2}/, '$1-$2-$3'
                    );

                if (latest === this.latestTimestamp) {
                    // undefinedを返して変更がないことを表現
                    deferred.resolve(undefined);
                    return;
                }

                this.latestTimestamp = latest;
                var jsonname = this.latestData.getLatestJsonFileName();

                // 最新のメッシュごとの危険度レベルjsonを取得
                Requester.get([SnakelineDataDirectory.outMesh, jsonname].join('/')).then(
                    lang.hitch(this, function(data) {
                        var meshStatus = {};
                        array.forEach(data.meshInfo, function(e) {
                            meshStatus[e.meshId] = e.level;
                        });
                        deferred.resolve(meshStatus);
                    }),
                    function(err) {
                        console.error(err);
                        deferred.reject(err);
                    }
                );
            }), function(err) {
                console.error(err);
                deferred.reject(err);
            });

            return deferred;
        },

        /**
         * スタイル定義
         */
        styleWithStatus: function(feature) {
            // デフォルトの透過度(全透過)と色(なし)
            // デフォルト: 危険度なし
            var fillOpacity = 0;
            var fillColor;

            // statusはまだロードされてない場合がある
            // featureはGeoJSONTileLayerの特性上, undefinedの場合がある
            // この2つが揃っていたら, レベルに応じてfillColorを設定
            if (this.statuses && feature) {
                fillColor = MeshColor[this.statuses[feature.properties[KeyName.keyName]]];

                if (fillColor) {
                    fillOpacity = 0.7;
                }
            }

            return {
                color: 'gray',
                weight: 1,
                fill: true,
                fillColor: fillColor,
                fillOpacity: fillOpacity
            };
        }
    });

    return SnakelineMeshLayer;
});