/**
 * Web Storageを管理するためのモジュール。
 * @module idis/util/storage/StorageManager
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/Evented'
], function(module, declare, lang, Evented) {
    return declare(Evented, {
        /**
         * 管理対象のWeb Storage
         * @param {Object}
         */
        storage: null,

        constructor: function(kwArgs) {
            lang.mixin(this, kwArgs);
        },

        /**
         * 指定したキー名の値を取得する。
         * @param {string} key キー名
         * @returns {string} 値
         */
        get: function(key) {
            return this.storage.getItem(key);
        },

        /**
         * 指定したキー名の値を設定する。
         * @param {string} key キー名
         * @param {string} value 値
         */
        set: function(key, value) {
            var oldValue = this.get(key);
            this.storage.setItem(key, value);
            this.emit('change', this.get(key), oldValue, key);
        },

        /**
         * 指定したキー名の値を削除する。
         * @param {string} key キー名
         */
        remove: function(key) {
            var oldValue = this.get(key);
            this.storage.removeItem(key);
            this.emit('change', this.get(key), oldValue, key);
        },

        /**
         * Web Storageの変更を監視する。
         * @param {string} [target] 指定した場合、指定したキー名の変更のみ反応する
         * @param {function} listener 変更時に呼ばれる関数。新しい値・古い値・キー名を受け取る
         * @param {Object} [context] listenerにとってのthis
         * @returns {Object} このオブジェクトのremoveメソッドを呼ぶと監視を解除する
         */
        watch: function(target, listener, context) {
            if (lang.isFunction(target)) {
                // target省略時
                return this.on('change', lang.hitch(listener, target));
            } else {
                // 引数が2つある場合は、指定されたキー名のときのみコールバック関数を呼び出す
                return this.on('change', lang.hitch(context, function(value, oldValue, key) {
                    if (key === target) {
                        listener.call(this, value, oldValue, key);
                    }
                }));
            }
        }
    });
});
