/**
 * Main画面用の通知制御の基底モジュール。
 * @module app/common/notifier/_MainNotifierBase
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/_base/array',
    'dojo/json',
    'dojo/date/locale',
    'dojo/topic',
    'idis/model/UserInfo',
    'idis/service/Requester',
    'app/common/consts/FunctionCd',
    'app/model/DisasterInfo',
    'idis/view/_IdisWidgetBase',
    '../../config',
    'idis/consts/USER_TYPE',
    'app/chronology/consts/ChronologyType',
    // 以下、変数から参照されないモジュール
    'app/broadnotify/BroadnotifyReceiveDialog'
], function (module, declare, lang, array, JSON, locale, topic, UserInfo,
    Requester, FunctionCd, DisasterInfo, _IdisWidgetBase, config, USER_TYPE, ChronologyType) {
    return declare(module.id.replace(/\//g, '.'), _IdisWidgetBase, {
        THRESHOLD_GATHER: 1, // 表示させるべきクロノロジ・被害を集約して表示する閾値
        SHOW_DURATION: 30, // 通知を表示させておく時間(s)
        REQUEST_INTERVAL: 1, //新着クロノロジ・被害の検索APIをよびだすインターバルの時間(min)
        TIMELINE_REQUEST_INTERVAL: 5, // タイムラインの検索APIをよびだすインターバルの時間(min)
        MAX_CONTENT_LENGTH: 20, //新着クロノロジ・被害の内容に表示する文章の最大文字数
        TIMELINE_GATHER: 2, // 表示させるべきタイムラインを集約して表示する閾値
        TIMELINE_SHOW_DURATION: 10, // 通知を表示させておく時間(s)
        BROADNOTIFY_INTERVAL: 60, // 緊急通知インターバルの時間
        CHRONOLOGY_SHOW_DURATION: 5, // 通知を表示させておく時間(s)

        timer: null,

        timelineTimer: null,

        audioData: [],
        notificationAudioData: [],

        event: 'app/view/form/DisasterChanger::changed',

        /**
         * widget開始時に呼ばれるもの, 情報取得などやる
         */
        startup: function () {
            this.inherited(arguments);

            // 所属組織を取得
            this.organizationCd = UserInfo.getOrganization();

            // 緊急通知のaudioデータをスタートアップ時に取得し流せるように準備する。
            if (this.audioData.length === 0) {
                this.audioData.push(new Audio('/data/sound/sound1.mp3'));
                this.audioData.push(new Audio('/data/sound/sound2.mp3'));
                this.audioData.push(new Audio('/data/sound/sound3.mp3'));
                this.audioData.forEach(lang.hitch(this, function(a) {
                    a.load();
                }));
            }
            // ポップアップ通知のaudioデータをスタートアップ時に取得し流せるように準備する。
            if (this.notificationAudioData.length === 0) {
                this.notificationAudioData.push(new Audio('/data/sound/sound11.mp3')); // 固定通知
                this.notificationAudioData.push(new Audio('/data/sound/sound12.mp3')); // 消える通知
                this.notificationAudioData.push(new Audio('/data/sound/sound13.mp3')); // 発令判断支援
                this.notificationAudioData.forEach(lang.hitch(this, function(a) {
                    a.load();
                }));
            }

            // 発令判断支援の更新時に通知音を再生する
            this.own(topic.subscribe('app/view/dialog/EvacRecommendPane::notify',
                lang.hitch(this, function () {
                    this.playAudio(this.notificationAudioData[2]);
                })));
        },

        /**
         * DOM生成
         */
        buildRendering: function () {
            this.inherited(arguments);
            // 災害名が変更された時に実施
            topic.subscribe(this.event, lang.hitch(this, function (id) {
                this.changeDisaster(id);
            }));
        },

        /**
         * 災害名変更
         */
        changeDisaster: function (id) {
            this._disasterId = String(id);
        },

        /**
         * 緊急通知 定期処理
         */
        broadnotifyLoop: function () {
            this.processNotifyReport();
            // 関数processBbsReportを60000ミリ秒間隔で呼び出す
            this.timer = setInterval(lang.hitch(this, function () {
                this.processNotifyReport();
            }), 1 * this.BROADNOTIFY_INTERVAL * 1000);
        },

        /**
         * お知らせ掲示板の情報を取得し、トーストを表示
         * @param isFirstTime : ログイン時・ページ更新時のみtrue, 他はfalse
         */
        processMessage: function (isFirstTime) {

            // ログインユーザの組織コードを取得する
            // var orgCd = UserInfo.getOrganization().deptCd;

            //災害IDが切り替えられていたら、切り替え後のIDを利用
            var disasterId = this._disasterId ? this._disasterId : DisasterInfo.getDisasterId();

            if (!UserInfo.hasAuthz(FunctionCd.BBS)) {
                return;
            }
            // var levelList = ['undefined', 'low', 'middle', 'high', 'urgent'];

            //リクエストインターバル分遡った時刻を取得する
            var now = new Date();
            var registerTimestampFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate(),
                now.getHours(), now.getMinutes() - this.REQUEST_INTERVAL).getTime();
            if (isFirstTime) {
                // ログイン時は、3日間のメッセージを集計して、件数を通知する。
                //（金曜に登録->月曜に開封を想定し３日とする）
                // 前回のログインからにしないのは、IDの使い回しにより、通されるべき人に通知されないことを避けるため、不採用とした。
                var newMessageDays = 3 * 24 * 60 * 60 * 1000; // 3日 * 24時間 * 60分 * 60秒 * 1000ミリ秒
                registerTimestampFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate(),
                    now.getHours(), now.getMinutes()).getTime() - newMessageDays;
            }
            var registerTimestampTo = new Date(now.getFullYear(), now.getMonth(), now.getDate(),
                now.getHours(), now.getMinutes()).getTime() - 1;

            var url = '/api/bbses?disasterId=' + disasterId +
                        '&notifyFlg2=02' + // 宛先ユーザへの通知：有
                        '&notifyFlg=1' + // 自分宛かつ要通知
                        '&unreadFlg=1' + // 未読情報のみ
                        '&registerTimestampFrom=' + registerTimestampFrom +
                        '&registerTimestampTo=' + registerTimestampTo;

            Requester.get(url).then(lang.hitch(this, function (data) {
                    console.log('お知らせ情報の一覧：' +
                        JSON.stringify(data));
                    var wholeData = data.items;
                    var messageList = [];
                    // var messageLevel = 0;
                    var currentTime = new Date();
                    array.forEach(wholeData, function (message) {
                        // 複数件存在した場合、最も上位の通知に寄せる
                        // messageLevel = Math.max(messageLevel, parseInt(message.messageType, 10) - 1);
                        if (message.pubEndTimestamp){
                            var pubEndTimestamp = new Date(message.pubEndTimestamp);
                            if (currentTime <= pubEndTimestamp){
                                messageList.push(message);
                            }
                        }
                    }, this);

                    // 通知音を再生
                    if (messageList.length > 0) {
                        this.playAudio(this.notificationAudioData[0]);
                    }

                    if (messageList.length > 0) {
                        array.forEach(messageList, function (message) {
                            this.notify({
                                notifyType : 'bbs',
                                notifyId : message.bbsId,
                                title: '掲示板 新着情報',
                                message: 'タイトル：' + message.title,
                                // level: levelList[messageLevel],
                                level: 'demand', // 要請
                                page: 'bbs/detail',
                                detailId: 'bbsId',
                                detailIdNum: message.bbsId,
                                timeout: 0
                            });
                        }, this);
                    }

                }), function (error) {
                    if (error.response.status === 404) {
                        console.error('お知らせ情報が存在しません。', error);
                    } else {
                        console.error('お知らせ情報取得でエラー発生', error);
                    }

                });
        },

        /**
         * 避難情報発令判断支援情報を取得し、トーストを表示
         * @param isFirstTime : ログイン時・ページ更新時のみtrue, 他はfalse
         */
        processEvacRecommend: function (isFirstTime) {
            //災害IDが切り替えられていたら、切り替え後のIDを利用
            var disasterId = this._disasterId ? this._disasterId : DisasterInfo.getDisasterId();

            if (!UserInfo.hasAuthz(FunctionCd.EVACRECOMMEND)) {
                return;
            }

            Requester.get('/data/evacorder/recommend/' + disasterId + '/evacRecommend.json')
                .then(lang.hitch(this, function (data) {
                    console.debug('避難情報発令判断支援一覧（' + this._municipalityCd + '）：' +
                        JSON.stringify(data));
                    var wholeData = data.items;
                    var userMunics = UserInfo.getMunicipalityCds();
                    var evacRecommendTimeFrom = new Date();

                    var items = [];
                    array.forEach(wholeData, function (recommend) {
                        // ログイン時は、発令判断基準超過レコードが、ユーザの管理対象市町村だったら表示する
                        if (isFirstTime && userMunics.indexOf(recommend.municipalityCd) !== -1) {
                            items.push(recommend);
                        } else if (!isFirstTime && userMunics.indexOf(recommend.municipalityCd) !== -1 &&
                            ((evacRecommendTimeFrom.getTime() - this.REQUEST_INTERVAL * 60 * 1000) <
                                recommend.updTimestamp)) {
                            // ログイン時でなければ、発令判断基準超過レコードが、ユーザの管理対象市町村である かつ
                            // 直近1分間の間に新規追加or更新された情報を取得する
                            items.push(recommend);
                        }
                    }, this);

                    if (items.length !== 0) {

                        // 通知音を再生
                        this.playAudio(this.notificationAudioData[2]);

                        var evacRecommendSummary = this.summarizeEvacRecommend(items);

                        var message = evacRecommendSummary.munucipalities + 'で、' +
                            evacRecommendSummary.maxEvacOrderType + '（' +
                            evacRecommendSummary.issueReasonTypes + '）の発令基準を超過しました。';

                        this.notify({
                            notifyType : 'evacrecommend',
                            notifyId : null,
                            title: evacRecommendSummary.maxEvacOrderType + '基準超過',
                            message: message,
                            level: this.maxEvacRecommendLevel(evacRecommendSummary.maxEvacOrderTypeCd),
                            page: 'evacrecommend',
                            timeout: this.SHOW_DURATION * 1000
                        });
                    }
                }), function (error) {
                    if (error.response.status === 404) {
                        console.error('避難レコメンドが存在しません。', error);
                    } else {
                        console.error('避難レコメンドの情報取得でエラー発生', error);
                    }

                });
        },

        /**
         * クロノロジの最新を取得して通知
         */
        processChronology: function (isFirstTime, isDemand) {
            //災害IDが切り替えられていたら、切り替え後のIDを利用
            var disasterId = this._disasterId ? this._disasterId : DisasterInfo.getDisasterId();
            //リクエストインターバル分遡った時刻を取得する
            var now = new Date();
            var registerTimestampFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate(),
                now.getHours(), now.getMinutes() - this.REQUEST_INTERVAL).getTime();
            var registerTimestampTo = new Date(now.getFullYear(), now.getMonth(), now.getDate(),
                now.getHours(), now.getMinutes()).getTime() - 1;

            var url = '/api/chronologies?disasterId=' + disasterId +
                    '&resultLimitFlg=0&sort=-registerTimestamp'; // 最新情報を取得
            if (isDemand) {
                url += '&unreadFlg=1'; // 未読情報のみ
                url += '&chronologyTypes=' + ChronologyType.DISASTER_HLD.id; // 「要請」のみ
                // 上限以上の場合、１つにまとめる（＝総数しか表示しない）ので内容は上限数までの取得に制限する
                url += '&count=' + this.MAX_NOTIFY_COUNT;
                if (!isFirstTime) {
                    // ログイン時以外は、直近REQUEST_INTERVAL以内に限定する
                    url += '&registerTimestampFrom=' + registerTimestampFrom;
                    url += '&registerTimestampTo=' + registerTimestampTo;
                }
            } else {
                url += '&notChronologyTypes=' + ChronologyType.DISASTER_HLD.id; // 「要請以外」のみ
                // 上限以上の場合、１つにまとめる（＝総数しか表示しない）ので内容は上限数までの取得に制限する
                url += '&count=' + this.THRESHOLD_GATHER;
                url += '&registerTimestampFrom=' + registerTimestampFrom;
                url += '&registerTimestampTo=' + registerTimestampTo;
            }

            // 取得対象を、ユーザの市町・振興局のものに限定。関係機関は県扱い。
            if (UserInfo.getUserType() === USER_TYPE.PREFECTURE) {
                url += '&userMunicipalityCd=' + UserInfo.getMunicipalityCd();
                url += '&userOrganizationCd=' + UserInfo.getLowestOrganizationCd();

            } else if (UserInfo.getUserType() === USER_TYPE.REGION) {
                url += '&userMunicipalityCd=' + config.municInfo.prefMunicCd;
                url += '&userOrganizationCd=' + UserInfo.getLowestOrganizationCd();
                url += '&regionCd=' + UserInfo.getRegionCd();

            } else if (UserInfo.getUserType() === USER_TYPE.MUNICIPALITY) {
                url += '&userMunicipalityCd=' + UserInfo.getMunicipalityCd();
                url += '&userOrganizationCd=' + UserInfo.getLowestOrganizationCd();
                url += '&municipalityCd=' + UserInfo.getMunicipalityCd();
                url += '&organizationCd=' + UserInfo.getLowestOrganizationCd();

            } else if (UserInfo.getUserType() === USER_TYPE.OTHER_ORGAN) {
                url += '&userMunicipalityCd=' + config.municInfo.prefMunicCd;
                url += '&userOrganizationCd=' + UserInfo.getLowestOrganizationCd();
                url += '&municipalityCd=' + config.municInfo.prefMunicCd;
                url += '&organizationCd=' + UserInfo.getLowestOrganizationCd();
            }

            // 例えば現在時刻が18:58:10で、REQUEST_INTERVALが1minだった場合、18:57:00~18:57:59.999までの間を検索期間とする
            Requester.get(url).then(lang.hitch(this, function (data) {
                // 「要請」の場合は通知を消さない
                var timeout = isDemand ? 0 : this.CHRONOLOGY_SHOW_DURATION * 1000;

                if (data.total > 0) {
                    // 通知音を再生
                    if (timeout === 0) {
                        this.playAudio(this.notificationAudioData[0]);
                    } else {
                        this.playAudio(this.notificationAudioData[1]);
                    }
                }

                if (!isDemand && data.total > this.THRESHOLD_GATHER) {
                    // 件数が多かったら1つにまとめる
                    var message = '新着通知が' + data.total + '件あります。';
                    message += 'クロノロジを確認してください。';
                    this.notify({
                        notifyType : 'chronology',
                        notifyId : null,
                        title: '新着情報通知',
                        message: message,
                        // level: this.maxLevel(chronology),
                        level: this.getLevel(0),
                        page: 'chronology',
                        timeout: timeout
                    });
                } else {
                    // まとめないときは1つずつ通知する
                    array.forEach(data.items, lang.hitch(this, function (item) {

                        // 要請元（クロノロジのgridの表示ロジックと合わせる）
                        var senderList = [];
                        array.forEach(item.chronologyShares, function(share){
                            if (share.senderFlg === '1' &&
                            ((!item.targetDestSendingSeqNum &&
                                (!share.sendingSeqNum || share.sendingSeqNum === 1)) ||
                                share.sendingSeqNum === item.targetDestSendingSeqNum )){
                                // 配信元フラグが'ON'、且つ
                                // 対象となる発信グループ番号が設定されていないで
                                //   - 発信グループ番号が設定されていない場合(外部からの登録)
                                //   - 発信グループ番号が1のばあい(クロノロジからの登録)
                                // 又は、対象発信グループ番号と一致する
                                senderList.push(share.shareName);
                            }
                        }, this);
                        if (!senderList || senderList.length === 0) {
                            // 配信元が登録されていない場合は外部システムからの送信
                            return '外部システム';
                        }else if (senderList.length > 2) {
                            return senderList[0] + '他' + (senderList.length-1) + '件';
                        }
                        var sender = senderList.join('、');

                        // 内容が長すぎる場合は、一定の長さで内容を区切る。
                        var message = null;

                        var content = item.content ? item.content.replace('<br>', '') : '(詳細未報告)';
                        if (content.length > this.MAX_CONTENT_LENGTH) {
                            message = content.substring(0, this.MAX_CONTENT_LENGTH) + '…';
                        } else {
                            message = content;
                        }

                        var title = null;
                        var level = this.getLevel(0);
                        if (isDemand) {
                            title = 'クロノロジ 新着要請情報';
                            level = 'demand'; // 要請
                            sender = '要請元：' + sender;
                            message = '内容：' + message;
                        } else {
                            title = this.getChronologyType(item.chronologyType);
                        }
                        this.notify({
                            notifyType : 'chronology',
                            notifyId : item.chronologyId,
                            title: title,
                            sender: sender,
                            message: message,
                            // level: this.getLevel(item.urgencyType),
                            level: level,
                            page: 'chronology/detail',
                            detailId: 'chronologyId',
                            detailIdNum: item.chronologyId,
                            timeout: timeout
                        });
                    }));
                }

            }), function (error) {
                console.error('クロノロジ取得API呼び出し失敗', error);
            });
        },

        /**
         * 未対応の被害情報を取得して通知
         */
        processDamageReport: function (isFirstTime) {
            // ユーザの所属組織を取得
            var organizationCd = this.organizationCd.unitCd ? this.organizationCd.unitCd :
                this.organizationCd.sectCd ? this.organizationCd.sectCd :
                    this.organizationCd.deptCd;

            // 取得条件: 災害IDが一致、ユーザの所属組織が対応課に指定されている、対応状況が「未確認」または「依頼済」
            var url = '/api/damageReports?disasterId=' + DisasterInfo.getDisasterId() +
                //'&hldOrganization=' + UserInfo.getOrganizationCd().substr(1) + '&hldStatus=0,1&activeFlg=1';
                '&hldOrganization=' + organizationCd + '&hldStatus=0,1&activeFlg=1';

            var now = new Date();
            var timeInfo = '';
            if (!isFirstTime) {
                // ログイン時以外は、直近REQUEST_INTERVAL分の間に更新されたものに限定して表示
                var registerTimestampFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate(),
                    now.getHours(), now.getMinutes() - this.REQUEST_INTERVAL);
                var registerTimestampTo = new Date(now.getFullYear(), now.getMonth(), now.getDate(),
                    now.getHours(), now.getMinutes());

                // 例えば現在時刻が18:58:10で、REQUEST_INTERVALが1minだった場合、18:57:00~18:57:59.999までの間を検索期間とする
                url += '&reportUpdDateTimeFrom=' + registerTimestampFrom.getTime() + '&reportUpdDateTimeTo=' +
                    (registerTimestampTo.getTime() - 1);
                timeInfo = locale.format(registerTimestampFrom) +
                    '〜' + locale.format(registerTimestampTo);
            } else {
                // ログイン時は、上記取得条件を満たす全ての被害を表示
                timeInfo = '（' + locale.format(now) + '現在）';
            }


            Requester.get(url).then(lang.hitch(this, function (data) {
                if (data.total === 0) {
                    return;
                }
                var message = '';
                if (!isFirstTime) {
                    message = timeInfo + 'の間に対応課に指定された被害が' + data.total + '件あります';
                } else {
                    message = '対応課に指定された被害が' + data.total + '件あります';
                    message += timeInfo;
                }

                this.notify({
                    notifyType: 'damageReport',
                    notifyId: null,
                    title: '未対応被害情報',
                    message: message,
                    level: this.maxLevel4Damage(data.items),
                    page: 'report',
                    timeout: this.SHOW_DURATION * 1000
                });

            }), function (error) {
                console.error('被害情報取得API呼び出し失敗', error);
            });
        },

        /**
         * 未報告の定時報告を取得して通知(市町・危機管理権限のユーザ向け)
         */
        processScheduledReport: function (isFirstTime) {
            // 市町・定時報告実施権限のユーザー以外には表示しない
            if (!(UserInfo.getUserType() === USER_TYPE.MUNICIPALITY &&
                    UserInfo.hasWriteAuthz(FunctionCd.DAMAGE_SCHE_ADM))) {
                return false;
            }
            var now = new Date();
            var aggrTimestampTo = new Date(now.getFullYear(), now.getMonth(), now.getDate(),
                now.getHours(), now.getMinutes());

            // 共通の取得条件: 災害IDが一致、集計時刻が現在時刻よりも前、依頼されているが未報告（＝報告をする必要がある）
            // TODO: 依頼された時点でも表示の必要あり？
            var url = '/api/scheduledReports?disasterId=' + DisasterInfo.getDisasterId() +
                '&allReportFlg=1' +         // 全件表示フラグ（被害がなくても表示）
                '&requestingReportFlg=1' +  // 未回答分を表示
                '&aggrDateTimeTo=' + aggrTimestampTo.getTime() +
                '&unreadFlg=1' + // 未読情報のみ
                '&reportType=01&municipalityCd=' + UserInfo.getMunicipalityCd();

            // ログイン時以外は、直近REQUEST_INTERVAL分以内に「集計時刻」を超過した依頼に限定する
            if (!isFirstTime) {
                var aggrTimestampFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate(),
                    now.getHours(), now.getMinutes() - this.REQUEST_INTERVAL);
                // 例えば現在時刻が18:58:10で、REQUEST_INTERVALが1minだった場合、18:57:00~18:57:59.999までの間を検索期間とする
                url += '&aggrDateTimeFrom=' + aggrTimestampFrom.getTime();
            }

            Requester.get(url).then(lang.hitch(this, function (data) {
                if (data.items.length > 0) {
                    // 通知音を再生
                    this.playAudio(this.notificationAudioData[0]);
                }
                array.forEach(data.items, lang.hitch(this, function (item) {
                    var message = '定時報告依頼があります。';
                    message += '（' + this.formatDateTime(item.aggrTimestamp) + '）';
                    this.notify({
                        notifyType : 'scheduledReport',
                        notifyId : item.scheduledReportId,
                        title: '定時報告',
                        message: message,
                        level: 'demand', // 要請
                        page: 'report/sche',
                        timeout: 0
                    });

                }));

            }), function (error) {
                console.error('定時報告取得API呼び出し失敗', error);
            });
        },

        /**
         * タイムラインの最新を取得して通知
         */
        processTimeline: function () {
            var now = new Date();

            // 実施時刻を超えているが、完了していない行動計画のうち、アラートフラグがオンであり、対象外フラグが立っていないもののみを検索する
            var url = '/api/timeline?disasterId=' + DisasterInfo.getDisasterId() +
                //'&startDateForSeach=' + (now.getTime()) +
                '&endDateForSeach=' + (now.getTime()) +
                '&alertSettingFlg=2&activeOnly=true';

            Requester.get(url).then(lang.hitch(this, function (data) {
                // 件数が多かったら1つにまとめる
                if (data.items.length > this.TIMELINE_GATHER) {
                    var message = '未実施のタイムラインが' + data.items.length + '件あります';
                    message += '（' + locale.format(now) + '現在）';
                    this.notify({
                        notifyType: 'timeline',
                        notifyId: null,
                        title: 'タイムライン',
                        message: message,
                        level: this.getLevel(3),
                        page: 'timeline',
                        timeout: this.TIMELINE_SHOW_DURATION * 1000
                    });
                }

                // まとめないときは1つずつ通知する
                array.forEach(data.items, lang.hitch(this, function (item) {

                    // 内容が長すぎる場合は、一定の長さで内容を区切る。
                    var message = '';
                    if (item.content.length > this.MAX_CONTENT_LENGTH) {
                        message += item.content.substring(0, this.MAX_CONTENT_LENGTH) + '…';
                    } else {
                        message += item.content;
                    }
                    message += '（予定時刻：' + locale.format(new Date(item.timestamp)) + '）';

                    this.notify({
                        notifyType: 'timeline',
                        notifyId: item.timelineDetailId,
                        title: 'タイムライン',
                        message: message,
                        level: this.getLevel(3),
                        page: 'timeline',
                        timeout: this.TIMELINE_SHOW_DURATION * 1000
                    });
                }));

            }), function (error) {
                console.error('タイムライン取得API呼び出し失敗', error);
            });
        },

        /**
         * 緊急通知を受信して通知
         */
        processNotifyReport: function () {
            console.debug('緊急通知受信処理を開始します。');
            //リクエストインターバル分遡った時刻を取得する
            var self = this;
            var now = new Date();
            var loginTimestamp = new Date(now.getFullYear(), now.getMonth(), now.getDate(),
                now.getHours(), now.getMinutes() - this.REQUEST_INTERVAL).getTime();
            var userId = UserInfo.getId();
            var dialog = self.broadnotifyReceiveDialog;
            var innerDialog = self.innerBroadnotifyReceiveDialog;
            var url = '/api/broadnotify/received/?userId=' + userId +
                '&loginTimestamp=' + loginTimestamp;
            if (innerDialog) {
                Requester.get(url).then(lang.hitch(this, function(data) {
                    if (data.length > 0) {
                        for (var i = 0; i < data.length; i++) {
                            innerDialog.initDialog(data[i]);
                            dialog.show();
                            switch (data[i].notifyType){
                                case '01':
                                    innerDialog.audio(this.audioData[0]);
                                    break;
                                case '02':
                                    innerDialog.audio(this.audioData[1]);
                                    break;
                                case '03':
                                    innerDialog.audio(this.audioData[2]);
                                    break;
                                default:
                                    break;
                            }
                            clearInterval(self.timer);
                        }
                    }

                }), lang.hitch(function(error) {
                    console.error('緊急通知取得API呼び出し失敗', error);
                }));
            }
        },

        /**
         * 受信確認ダイアログを初期化する。
         */
        initReceiveDialog: function () {
            var self = this;
            var dialog = this.broadnotifyReceiveDialog;
            var page = dialog.getChildren()[0];

            page.on('update', lang.hitch(this, function (evt) {
                var form = evt.value;
                var url = '/api/broadnotify/confim/' + form.broadnotifyDetailId;
                Requester.put(url).then(lang.hitch(this, function () {
                    // 登録ダイアログを閉じる
                    dialog.hide();
                    // loop処理開始
                    self.broadnotifyLoop();
                }), function (error) {
                    console.error('緊急通知取得API呼び出し失敗', error);
                });

            }));
        },

        maxLevel4Damage: function (items) {
            var maxLevel = 0;
            array.forEach(items, function (item) {
                if (maxLevel < item.urgencyType) {
                    maxLevel = item.urgencyType;
                }
            });

            return this.getLevel(maxLevel);
        },

        /**
         * 複数の通知のうち, 緊急度が最大のものを求める
         */
        maxLevel: function (items) {
            var maxLevel = 0;
            array.forEach(items, function (item) {
                if (item.urgencyType === '9') {
                    return;
                }
                if (maxLevel < item.urgencyType) {
                    maxLevel = item.urgencyType;
                }
            });

            return this.getLevel(maxLevel);
        },

        /**
         * widgetが居なくなる時に呼ばれる
         * 定期処理を止める
         */
        destroy: function () {
            this.inherited(arguments);

            // setInterval止める
            clearInterval(this.timer);
            clearInterval(this.timelineTimer);
        },

        getLevel: function (urgencyType) {
            var levelMap = {
                '0': 'undefined',
                '1': 'low',
                '2': 'middle',
                '3': 'high'
            };

            return levelMap[urgencyType];
        },

        /**
         * 複数の避難情報発令判断支援情報から、トースト表示用に必要な情報を抜き出す
         */
        summarizeEvacRecommend: function (items) {
            var maxEvacOrderTypeCd = 11;
            var issueReasonTypes = [];
            var issueReasonTypesName = [];
            var munucipalities = []; //maxEvacOrderTypeCdが設定されているevacRecommendDTOのmunicipality
            array.forEach(items, function (evacRecommend) {
                // 最も重度の高い避難区分を選ぶ 11(緊急安全確保) > 13(避難指示) > 14(高齢者等避難)
                var currentEvacOrdetTypeInt = parseInt(evacRecommend.evacOrderType, 10);
                if (currentEvacOrdetTypeInt > maxEvacOrderTypeCd) {
                    maxEvacOrderTypeCd = currentEvacOrdetTypeInt;
                    // 最大の避難区分が更新された場合は、「最大の避難区分が出されている市町村リスト」 を更新
                    munucipalities = [];
                    munucipalities.push(evacRecommend.municipalityName);
                    issueReasonTypes = [];
                    issueReasonTypes.push(evacRecommend.issueReasonType);
                } else if (currentEvacOrdetTypeInt === maxEvacOrderTypeCd) {
                    if (munucipalities.indexOf(evacRecommend.municipalityName) === -1) {
                        munucipalities.push(evacRecommend.municipalityName);
                    }
                    if (issueReasonTypes.indexOf(evacRecommend.issueReasonType) === -1) {
                        issueReasonTypes.push(evacRecommend.issueReasonType);
                    }
                }
            }, this);
            if (munucipalities.length === 1) {
                munucipalities = munucipalities[0];
            } else {
                munucipalities = munucipalities[0] + 'など';
            }
            array.forEach(issueReasonTypes, function (issueReasonType) {
                issueReasonTypesName.push(this.getIssueReasonTypeName(issueReasonType));
            }, this);
            var summary = {};
            summary.maxEvacOrderTypeCd = maxEvacOrderTypeCd;
            summary.maxEvacOrderType = this.getEvacOrderType(maxEvacOrderTypeCd);
            summary.issueReasonTypes = issueReasonTypesName.join('・');
            summary.munucipalities = munucipalities;
            return summary;
        },

        getIssueReasonTypeName: function (issueReasonType) {
            var issueReasonTypeMap = {
                '01': '土砂',
                '02': '洪水',
                '03': '地震',
                '04': '津波',
                '05': '高潮',
                '06': '火災',
                '07': '暴風',
                '08': '火山',
                '09': '国民保護'
            };
            return issueReasonTypeMap[issueReasonType];
        },

        getEvacOrderType: function (maxEvacOrderTypeCd) {
            return {
                11: '高齢者等避難',
                13: '避難指示',
                14: '緊急安全確保'
            }[maxEvacOrderTypeCd] || '---';
        },

        maxEvacRecommendLevel: function (maxEvacOrderTypeCd) {
            // 発令判断支援パネルの色は、他と少しずれている
            return {
                11: 'middle',
                13: 'high',
                14: 'urgent'
            }[maxEvacOrderTypeCd] || '---';
        },

        getChronologyType: function (type) {
            var typeMap = {
                '00': 'その他',
                '01': '防災気象情報',
                '02': '国民保護情報',
                '03': '観測情報',
                '22': 'タイムライン',
                '23': '作図情報',
                '24': '避難情報',
                '25': '配備体制',
                '26': '避難所情報',
                '27': '被害情報',
                '28': '要請・措置情報',
                '29': '災害名管理',
                '30': '要請・措置',
                '31': '組織内情報',
                '32': '部隊活動情報',
                '33': '通行規制情報',
                '34': '山口県連携',
                '41': '定時報告依頼',
                '42': '定時報告'
            };

            return typeMap[type];
        },

        /**
         * 'yyyy-MM-dd HH:mm' 形式に変換
         */
        formatDateTime: function (val) {
            var timestamp = new Date(val);
            var dateLabel = locale.format(timestamp, {
                selector: 'date',
                datePattern: 'yyyy/MM/dd'
            });
            var timeLabel = locale.format(timestamp, {
                selector: 'time',
                timePattern: 'HH:mm'
            });
            return dateLabel + ' ' + timeLabel;
        },

        /**
         * 通知音を再生
         */
        playAudio: function (audioData) {
            audioData.play().catch(function(error) {
                console.error('音声の再生に失敗しました。', error);
            });
        }

    });
});
