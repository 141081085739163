/**
 * 観測情報表示用ラスターレイヤー
 * 画像タイルで構成されるレイヤーを時系列で表示できるようにするもの
 * @module app/observation/map/ObservationRasterLayer
 */
define([
    'dojo/_base/lang',
    'dojo/promise/all',
    'dojo/topic',
    'leaflet',
    'idis/service/Requester',
    'idis/consts/QUERY',
    'idis/control/Locator',
    'app/observation/LatestData',
    'app/map/layer/StyleLoader'
], function(lang, all, topic, leaflet, Requester, QUERY, Locator, LatestData, StyleLoader) {

    var timeSeriesGeoJsonLayer = leaflet.GeoJSON.extend({

        statusUrl: null,        // 可変となるURL

        _lastQuery: {},
        _latestDataTimestamp: null,  // データの最新時刻を保管
        _handle: null, // Locatorを監視するハンドラ

        // コンストラクタ
        initialize: function(layerInfo, options) {

            this.statusUrl = layerInfo.layerUrl;
            var latestData = new LatestData({url: this.statusUrl + '/latest.json'});
            var styleLoader = new StyleLoader({
                url: layerInfo.styleUrl,
                id: layerInfo.id
            });

            leaflet.GeoJSON.prototype.initialize.call(this, '', options);

            var timestampWithDash;

            all({
                // データの最新更新情報を取得
                latest: latestData.load(),
                style: styleLoader.load()

            }).then(lang.hitch(this, function() {
                // クエリーに時間指定があったらその時間のものを表示
                this._lastQuery = Locator.getQuery();
                if (this._lastQuery.datetime) {
                    timestampWithDash = this._lastQuery.datetime;

                } else {
                    var timestamp = latestData.getLatestDateTimestamp();
                    timestampWithDash = timestamp.replace(
                        /(\d{4}-\d{2}-\d{2}) (\d{2}):(\d{2}):\d{2}/, '$1-$2-$3');
                }

                // 初期表示
                // superの呼び出し
                var style = styleLoader.getStyle();
                lang.mixin(this.options, style && style.geojsonOptions);


                this._refresh(timestampWithDash);

                // URLの変更を監視
                this._handle = Locator.on('change', lang.hitch(this, this.onLocationChanged));
            }),
            function(error) {
                console.error(error);
            });
        },

        // URL変更時の処理
        // 時刻が変わったら観測情報を取得してレイヤーを更新
        onLocationChanged: function() {
            // 現在のクエリー情報を取得
            var query = Locator.getQuery();

            // 緯度経度、レイヤー、ズームレベルが変更された場合はデータの更新はないので何もしない
            // 最新日時が選択されている状態で「最新」ボタンをクリックすると、日時は変わっていないがデータが
            // 更新されている可能性があるので、日付の一致は確認しない
            if (query[QUERY.LATLNG] !== this._lastQuery[QUERY.LATLNG]) {
                this._lastQuery = query;
                return;
            }
            if (query[QUERY.LAYER_LIST] !== this._lastQuery[QUERY.LAYER_LIST]) {
                this._lastQuery = query;
                return;
            }
            if (query[QUERY.ZOOM] !== this._lastQuery[QUERY.ZOOM]) {
                this._lastQuery = query;
                return;
            }

            // 日時や時間モードが指定されてなかった時のデフォルト値
            var datetime = query.datetime ? query.datetime : this._latestDataTimestamp;
            // レイヤーを更新
            this._refresh(datetime);

            // 次の更新に備えてクエリー状態を保存
            this._lastQuery = query;
        },

        // レイヤー内容を指定された時間の観測情報で更新
        // ファイルは [layerUrl]/list/yyyy-mm-dd-HH-MM.geojson を見にいく
        _refresh: function(timestamp) {
            var url = this.statusUrl + '/list/' + timestamp + '.geojson';

            Requester.get(url).then(lang.hitch(this, function(geojson){
                // layer内容をクリア
                this.clearLayers();

                // 作ったgeojsonを表示
                this.addData(geojson);

                this._latestDataTimestamp = timestamp;

                // トップページに表示レイヤーの時刻表示用のpub
                topic.publish('app/map/TimestampsPanel::show', {
                    timestamp: this._latestDataTimestamp,
                    layerName: this.statusUrl.split('/').slice(-1)[0]
                });

            }), lang.hitch(this, function(){
                // TODO エラーダイアログ
                // layer内容をクリア
                this.clearLayers();
            }));
        },

        // layerが削除された時に呼ばれる
        onRemove: function(map) {
            // Locator監視を停止
            if (this._handle) {
                this._handle.remove();
            }
            leaflet.GeoJSON.prototype.onRemove.call(this, map);
            topic.publish('app/map/TimestampsPanel::disappear');
        },

        // 全ての地物を地図から消す
        clearLayers: function() {
            leaflet.GeoJSON.prototype.clearLayers.call(this);
        },

        // 地図に地物を表示する
        addData: function(geojson) {
            leaflet.GeoJSON.prototype.addData.call(this, geojson);
        }
    });

    return timeSeriesGeoJsonLayer;
});
