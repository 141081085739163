/**
 * 型判定に関するユーティリティー関数群。
 */
define(function() {
    /**
     * オブジェクトの型判定用。
     * @returns {string} オブジェクトの型を表す文字列
     * @private
     */
    var _toString = Object.prototype.toString;

    /**
     * 型判定に関するユーティリティー関数群。
     * @namespace TypeUtils
     */
    return {
        /**
         * 受け取った値がNaNかどうかを返す。
         * @param {*} value 型判定する値
         * @returns {boolean} 受け取った値がNaNであればtrue、それ以外の場合はfalse
         */
        isNaN: function(value) {
            // 自分自身と一致しないのはNaNだけ
            return value !== value;
        },

        /**
         * 受け取った値がundefinedかどうかを返す。
         * @param {*} value 型判定する値
         * @returns {boolean} 受け取った値がundefinedであればtrue、それ以外の場合はfalse
         */
        isUndef: function(value) {
            return value === void 0;
        },

        /**
         * 受け取った値がDateオブジェクトかどうかを返す。
         * @param {*} value 型判定する値
         * @returns {boolean} 受け取った値がDateオブジェクトであればtrue、それ以外の場合はfalse
         */
        isDate: function(value) {
            return _toString.call(value) === '[object Date]';
        },

        /**
         * 受け取った値が数値かどうかを返す。
         * @param {*} value 型判定する値
         * @returns {boolean} 受け取った値が数値であればtrue、それ以外の場合はfalse
         */
        isNumber: function(value) {
            return !isNaN(parseFloat(value)) && isFinite(value);
        },

        /**
         * 受け取った値が正規表現オブジェクトかどうかを返す。
         * @param {*} value 型判定する値
         * @returns {boolean} 受け取った値が正規表現オブジェクトであればtrue、それ以外の場合はfalse
         */
        isRegExp: function(value) {
            return _toString.call(value) === '[object RegExp]';
        }
    };
});
