/**
 * 地理関連のユーティリティー。
 * @module idis/util/GeoUtils
 */
define([
    'app/config',
    'module',
    'dojo/_base/kernel',
    'dojo/_base/lang',
    'dojo/Deferred',
    'dojo/on',
    'exif-js/exif',
    'leaflet',
    'idis/service/GeoService',
    './FilesUtils'
], function(config, module, kernel, lang, Deferred, on, exif, leaflet, GeoService, FilesUtils) {
    // geocode・reverseGeocode用インスタンス
    var _geoService = new GeoService({url: config.geocode && config.geocode.url});

    /**
     * 現在地情報を返すPromiseを返す。
     * @see {@link https://dev.w3.org/geo/api/spec-source.html#navi-geo}
     * @function getCurrentPosition
     * @param {Object} [options] 追加オプション
     * @param {boolean} [enableHighAccuracy=true] 正確さを優先するか
     * @param {number} [timeout=6000] タイムアウト時間（ミリ秒）
     * @param {number} [maximumAge=600000] キャッシュの有効期限（ミリ秒）
     * @returns {Promise<Position>} 現在地情報を返すPromise
     */
    function _getCurrentPosition(options) {
        var dfd = new Deferred();
        // ブラウザーの対応を確認
        if (!('geolocation' in navigator)) {
            dfd.reject(new Error('ブラウザーが現在地取得に対応していません。'));
        }
        // 現在地を取得
        navigator.geolocation.getCurrentPosition(dfd.resolve, function(err) {
            // エラー原因に対応する日本語メッセージを設定
            // https://dev.w3.org/geo/api/spec-source.html#position-error
            var msg = '現在地が取得できませんでした。';
            switch (err.code) {
                case err.PERMISSION_DENIED:
                    msg = '現在地取得が許可されませんでした。';
                    break;
                case err.POSITION_UNAVAILABLE:
                    msg = '現在地の取得に失敗しました。';
                    break;
                case err.PERMISSION_DENIED_TIMEOUT:
                    msg = '現在地取得中にタイムアウトしました。';
                    break;
            }
            // エラーを生成して返す
            dfd.reject(new Error(msg));
        }, lang.mixin({
            // オプションが与えられた場合はデフォルトを上書き
            enableHighAccuracy: true,
            timeout: 6000, // 6秒
            maximumAge: 600000 // 10分
        }, options));

        return dfd.promise;
    }

    /**
     * EXIF上の緯度経度情報を通常の緯度経度に変換する。
     * @function _normalizeExifLatLng
     * @param {Object[]} values EXIF上の緯度経度
     * @returns {number} 緯度経度数値
     * @private
     */
    function _normalizeExifLatLng(values) {
        var result = 0.0;
        for (var i = 0; i < values.length; i++) {
            result += values[i].numerator / (Math.pow(60, i) * values[i].denominator);
        }
        return result;
    }

    /**
     * 指定された画像要素から座標情報を取得して返す。
     * @function getLatLng
     * @param {Node} img 画像要素
     * @returns {Promise<L.LatLng>} 座標情報
     */
    function _getLatLng(img) {
        var dfd = new Deferred();
        // EXIFデータを解析（imgのプロパティーに追加される）
        if (!exif.getData(img, function() {
            dfd.resolve(this);
        })) {
            // 画像が未ロードの場合はロード後に実施
            on(img, 'load', function(evt) {
                exif.getData(evt.target, function() {
                    dfd.resolve(this);
                });
            });
        }
        var promise = dfd.then(function(img) {
            var latitude = exif.getTag(img, 'GPSLatitude');
            var longitude = exif.getTag(img, 'GPSLongitude');
            // 緯度経度いずれかが見つからなかった場合はrejectさせる
            if (!latitude || !longitude) {
                throw new Error('GPS data is not available');
            }
            // Leaflet座標として返す
            return leaflet.latLng(_normalizeExifLatLng(latitude), _normalizeExifLatLng(longitude));
        });
        return promise;
    }

    /**
     * 指定された画像ファイルから座標情報を取得して返す。
     * @function readLatLng
     * @param {File} file 画像ファイル
     * @returns {Promise<L.LatLng>} 座標情報
     */
    function _readLatLng(file) {
        return FilesUtils.readExif(file).then(function(data) {
            var lat = data.GPSLatitude;
            var lng = data.GPSLongitude;
            // 緯度経度いずれかが見つからなかった場合はrejectさせる
            if (!lat || !lng) {
                throw new Error('GPS data is not available');
            }
            // Leaflet座標として返す
            return leaflet.latLng(_normalizeExifLatLng(lat), _normalizeExifLatLng(lng));
        });
    }

    /**
     * 指定された文字列から緯度経度を取得して返す。
     * @see {@link http://esri.github.io/esri-leaflet/api-reference/tasks/geocode.html}
     * @function geocode
     * @param {string} text 検索対象文字列
     * @returns {Promise<GeoService~Result[]>} 結果の配列
     */
    function _geocode() {
        kernel.deprecated(module.id + '::geocode', 'idis/service/GeoService#geocodeを使ってください。');
        return _geoService.geocode.apply(_geoService, arguments);
    }

    /**
     * 指定された緯度経度から住所を取得して返す。
     * @see {@link http://esri.github.io/esri-leaflet/api-reference/tasks/reverse-geocode.html}
     * @function _reverseGeocode
     * @param {L.LatLng} latlng Leafletの緯度経度
     * @param {number} [distance=10000] 指定座標から半径何メートルまでの住所を探すか
     * @returns {Promise<GeoService~ReverseResult>} 住所取得結果
     */
    function _reverseGeocode() {
        kernel.deprecated(module.id + '::geocode', 'idis/service/GeoService#reverseGeocodeを使ってください。');
        return _geoService.reverseGeocode.apply(_geoService, arguments);
    }

    return {
        getCurrentPosition: _getCurrentPosition,
        getLatLng: _getLatLng,
        readLatLng: _readLatLng,
        geocode: _geocode,
        reverseGeocode: _reverseGeocode
    };
});
