/**
 * ラウター用のモジュール。
 * @module idis/control/Router
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom',
    'dojo/when',
    'dijit/_WidgetBase',
    'dijit/_Container',
    'dojox/lang/functional/object',
    '../consts/QUERY',
    './Locator'
], function(module, array, declare, lang, dom, when, _WidgetBase, _Container, df, QUERY, Locator) {
    /**
     * ラウター。
     * @class Router
     * @extends module:dijit/_WidgetBase~_WidgetBase
     * @extends module:dijit/_Container~_Container
     */
    var Router = declare(module.id.replace(/\//g, '.'), [_WidgetBase, _Container],
        /** @lends module:idis/control/Router~Router# */ {
        /**
         * 管理する移動先パスと処理のマッピング
         * @type {Object.<string, string|function>}
         * @private
         */
        _routes: null,

        /**
         * 移動先パスに紐付けられた処理が無かった場合の処理
         * @type {string|function}
         */
        _defaultRoute: null,

        constructor: function() {
            // マッピングを初期化
            this._routes = {};
        },

        /**
         * 指定されたパスが表示された際の処理を登録する。
         * @param {string|object} [path] 紐付け先パス。省略時時はデフォルト処理として登録される
         * @param {function|module:idis/view/page/_PageBase~_PageBase} action 移動時の処理または担当ウィジェット
         */
        register: function(path, action) {
            if (lang.isObject(path)) {
                df.forIn(path, function(action, path) {
                    this.register(path, action);
                }, this);
            } else if (!action) {
                // pathが省略された場合
                this._defaultRoute = path;
            } else {
                this._routes[path] = action;
            }
        },

        /**
         * 準備完了したら解決するPromiseを返す。
         * Promise以外を返した場合は準備完了済みとみなす。
         * @returns {Promise|*}
         */
        init: function() {
            // 子クラスで実装
        },

        /**
         * 現在のURLに対応するウィジェットを配置し、監視を開始する。
         * @function module:idis/control/Router~Router#~startup
         */
        startup: function() {
            this.inherited(arguments);
            when(this.init(), lang.hitch(this, function() {
                // 現在のURLに紐付けられた処理を実施
                this.handlePath();
                // URLが書き換わった際に反応するようにする
                this.own(Locator.on('change', lang.hitch(this, 'handlePath')));
            }));
        },

        /**
         * 現在のURLに紐付けられた処理を実施する。
         */
        handlePath: function() {
            // ページ用パラメーターに対応する処理を取り出す
            var query = Locator.getQuery();
            var action = this._routes[query[QUERY.PAGE] || ''];
            // 現在のURLに紐付けられた処理が無ければデフォルト処理を実施
            if (!action) {
                action = this._defaultRoute;
            }
            if (lang.isString(action)) {
                // index.jsでも呼び出しているが、処理順序によって、MainPageModulesの読み込み完了前に実行して、画面が白くなることがあるため、
                // 事前にMainPageModuleの読み込みを完了してから実行する
                import(/* webpackChunkName: "MainPages" */ '../../../MainPageModules')
                .then(lang.hitch(this,function () {
                    // 文字列の場合はモジュールとして読み込む
                    return require([action], lang.hitch(this, function (Widget) {
                        var page = this.getChildren()[0];
                        if (page && page.isInstanceOf(Widget)) {
                            return; // 同一画面なら入れ替えない
                        }
                        // 前の画面を除去
                        if (page) {
                            this.removeChild(page);
                            page.destroyRecursive();
                        }
                        // 新しい画面を配置して初期化
                        this.addChild(new Widget());
                    }));
                }));
            } else if (lang.isFunction(action)) {
                // 関数の場合は実行
                action(query);
            } else {
                throw new Error('unknown action');
            }
        }
    });

    /**
     * 指定された画面へ遷移する。
     * @param {string} page 遷移先画面
     * @param {Object} [query] URLに追加で付与するパラメーター
     * @function moveTo
     */
    Router.moveTo = function(page, query) {
        // 追加クエリーの内容をコピー
        query = lang.mixin(null, query);
        // 遷移先を設定
        query[QUERY.PAGE] = page;
        // URLを更新
        Locator.pushState(query, true);
    };

    /**
     * 指定されたURLへ画面遷移する。
     * @param {string} url 遷移先URL
     * @function moveTo
     */
    Router.moveToByUrl = function(url) {
        var page = url.match(new RegExp('p=[a-zA-Z0-9%/]*', 'g'))[0].replace('p=','').replace(/\%2F/g, '/');
        var keys = [];
        array.forEach(url.match(new RegExp('&[a-zA-Z]*', 'g')), function(key){
            if (key!=='&p') {
                keys.push(key.replace('&', ''));
            }
        });
        var query = {};
        array.forEach(keys, function(key){
            var regexp = new RegExp(key +'\=[a-zA-Z0-9]*', 'g');
            query[key] = url.match(regexp)[0].replace(key+'=', '');
        });
        // 追加クエリーの内容をコピー
        query = lang.mixin(null, query);
        // 遷移先を設定
        query[QUERY.PAGE] = page;
        // URLを更新
        Locator.pushState(query, true);
    };

    /**
     * 指定された画面へ履歴を残さず遷移する。
     * @param {string} page 遷移先画面
     * @param {Object} [query] URLに追加で付与するパラメーター
     * @function replaceTo
     */
    Router.replaceTo = function(page, query) {
        // 追加クエリーの内容をコピー
        query = lang.mixin(null, query);
        // 遷移先を設定
        query[QUERY.PAGE] = page;
        // URLを更新
        Locator.replaceState(query, true);
    };

    /**
     * 現在の画面パスを取得する。
     * @returns {string} 画面パスを表す文字列
     */
    Router.getCurrentPath = function() {
        return Locator.getQuery()[Router.PAGE_KEY];
    };

    // 定数を公開
    Router.PAGE_KEY = QUERY.PAGE;

    return Router;
});

