/**
 * 日付関連のユーティリティー。
 * @module idis/util/DateUtils
 */
define([
    'module',
    'dojo/_base/lang',
    'dojo/date/locale',
    '../error/InvalidArgumentException',
    './TypeUtils'
], function(module, lang, locale, InvalidArgumentException, TypeUtils) {
    /**
     * 日付パターンのデフォルト
     */
    var _DATE_PATTERN = 'yyyy/MM/dd';

    /**
     * 時間パターンのデフォルト
     */
    var _TIME_PATTERN = 'HH:mm:ss';

    /**
     * 指定された値をDateオブジェクトに変換して返す。
     * @function parse
     * @param {number|string|Date} 変換対象の値
     * @returns {Date} 指定された値に対応するDateオブジェクト
     */
    function _parse(value) {
        if (TypeUtils.isNumber(value)) {
            // Unix EpochならDate型に変換
            return new Date(value);
        } else if (lang.isString(value)) {
            // 文字列の場合は日付の'-'を'/'にしてDate型に変換
            return new Date(value.replace(/-/g, '/'));
        } else if (TypeUtils.isDate(value)) {
            // Dateオブジェクトはそのまま帰す
            return value;
        } else {
            throw new InvalidArgumentException(module.id + '#parseDate: 不正な値です: ' + value);
        }
    }

    /**
     * 指定された値を日付文字列に変換する。
     * @function format
     * @param {number|string|Date} value 変換対象の値
     * @param {Object} [options] 追加オプション
     * @param {string} [options.date='yyyy/MM/dd'] 日付文字列
     * @param {string} [options.time='HH:mm:ss'] 時間文字列
     * @returns {string} '[options.date] [options.time]'形式の日時文字列
     */
    function _format(value, options) {
        return locale.format(_parse(value), {
            datePattern: options && options.date || _DATE_PATTERN,
            timePattern: options && options.time || _TIME_PATTERN
        });
    }

    /**
     * 指定された値を日付文字列に変換する。
     * @function formatDate
     * @param {number|string|Date} value 変換対象の値
     * @param {string} [datePattern='yyyy/MM/dd'] 日付文字列
     * @returns {string} datePattern形式の日付文字列
     */
    function _formatDate(value, datePattern) {
        return locale.format(_parse(value), {
            selector: 'date',
            datePattern: datePattern || _DATE_PATTERN
        });
    }

    /**
     * 指定された値を時間文字列に変換する。
     * @function formatTime
     * @param {number|string|Date} value 変換対象の値
     * @param {string} [timePattern='yyyy/MM/dd'] 日付文字列
     * @returns {string} timePattern形式の日付文字列
     */
    function _formatTime(value, timePattern) {
        return locale.format(_parse(value), {
            selector: 'time',
            timePattern: timePattern || _TIME_PATTERN
        });
    }

    return {
        format: _format,
        formatDate: _formatDate,
        formatTime: _formatTime,
        parse: _parse
    };
});

