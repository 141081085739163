/**
 * 避難所表示用レイヤー
 * @module app/shelter/map/ShelterLayer
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/promise/all',
    'dojox/lang/functional',
    'leaflet',
    'idis/service/Requester',
    '../ShelterUtils',
    'app/util/DateFormatter'
], function(module, array, declare, lang, all, df,
    leaflet, Requester, ShelterUtils, DateFormatter) {

    var shelterLayer = leaflet.GeoJSON.extend({

        statusUrl: null,
        geometryType: null,  // レイヤーの地物種類
        facilityId: null,
        _shelters: null, // 避難所一覧

        // コンストラクタ
        initialize: function(layerInfo, options) {
            // superの呼び出し
            // 最初は空のgeoJsonを元にする
            leaflet.GeoJSON.prototype.initialize.call(this, this._emptyGeoJson(), options);

            this.statusUrl = layerInfo.dynamic.statusUrl;
            this.geometryType = layerInfo.dynamic.type;
            if (!!layerInfo.facilityId) {
                this.facilityId = layerInfo.facilityId;
            }
            this._shelters = {};

            all({
                // 避難所情報を生成
                shelter: this.generateShelter()
            }).then(lang.hitch(this, function() {
                // 表示
                this._refresh();
            }));
        },

        /**
         * 避難所情報を読み込む
         */
        generateShelter: function() {
            return Requester.get(this.statusUrl, {preventCache: false})
            .then(lang.hitch(this, function(data) {
                this._shelters = data.items;
            }));
        },

        /**
         * レイヤーを表示
         */
        _refresh: function() {
            // layer内容をクリア
            this.clearLayers();
            // geojsonを生成
            var geojson = this._generateGeoJson(this._shelters);
            // 作ったgeojsonを表示
            this.addData(geojson);
        },

        // このレイヤーがmapから削除されたときに呼ばれる
        onRemove: function(map) {
            leaflet.GeoJSON.prototype.onRemove.call(this, map);
        },

        // 全ての地物を地図から消す
        clearLayers: function() {
            leaflet.GeoJSON.prototype.clearLayers.call(this);
        },

        // 地図に地物を表示する
        addData: function(geojson) {
            leaflet.GeoJSON.prototype.addData.call(this, geojson);
        },

        // 取得した避難所データから表示させるためのGeoJSONを生成
        _generateGeoJson: function(items) {

            // 空のgeojsonからスタート
            var geojson = this._emptyGeoJson();

            // 開設状態をチェックする関数を生成
            var isOpen = ShelterUtils.isOpenOn();

            // 各種プロパティをGeoJSON形式に変換
            array.forEach(items, lang.hitch(this, function(item, i) {
                var feature = {
                    geometry: {
                        type: this.geometryType,
                        'coordinates': [item.longitude, item.latitude]
                    },
                    type: 'Feature',
                    id: i+1,
                    properties: {}
                };

                // 要素に開設判定を付与する
                item.isOpen = isOpen(item);
                // ポップアップ表示用に日時も予め表示用に変換しておく
                if (item.shelterStartTimestamp !== '' && item.shelterStartTimestamp !== null) {
                    item.shelterStartTimestamp =
                        DateFormatter.dateTime(new Date(item.shelterStartTimestamp.replace(/-/g, '/')), true);
                }
                if (item.shelterEndTimestamp !== '' && item.shelterEndTimestamp !== null) {
                    item.shelterEndTimestamp =
                        DateFormatter.dateTime(new Date(item.shelterEndTimestamp.replace(/-/g, '/')), true);
                }
                // 表示する避難所判定を付与する
                if (this.facilityId === null) {
                    // 特定の避難所IDが設定されていなければ開設中ののみ表示
                    if (item.isOpen) {
                        item.isShow = true;
                    } else {
                        item.isShow = false;
                    }
                } else {
                    // 設定されていればその避難所のみ表示
                    if (item.facilityId === this.facilityId) {
                        item.isShow = true;
                    } else {
                        item.isShow = false;
                    }
                }

                // すべてのプロパティをfeature.propertiesに入れる
                // FIXME 特定のプロパティのみでOK
                df.forIn(item, function(value, key) {
                    feature.properties[key] = value;
                });

                geojson.features.push(feature);
            }));

            return geojson;
        },

        // 空のgeojsonを作る
        _emptyGeoJson: function() {
            return {
                type: 'FeatureCollection',
                features:[]
            };
        }
    });

    return shelterLayer;
});
