/* 開発環境用設定ファイル */
define({
    /**
     * geocode関連
     */
    geocode: {
        // geocodeサービスのURL
        url: process.env.GEOCODE_SERVICE_URL
    },

    map: {
        /**
         * 使用する背景地図一覧（各背景地図情報はapp/map/baselayer/BaseLayerOptionsで定義）
         * 指定されない場合は全選択肢を表示する。
         * @type {identifier[]}
         */
        userBaseLayers: {'INTERNET': ['OSM_ST','GSI_PALE_NET','GSI_STANDARD_NET','GSI_ORT_NET','ESRI_IM','ESRI_ST','GSI_VECTOR'],
                            'LGWAN': ['GSI_PALE_NET_LGWAN','GSI_STANDARD_NET_LGWAN','GSI_ORT_NET_LGWAN']},
        saveImageBaseLayers: {'INTERNET': ['GSI_PALE_NET','GSI_STANDARD_NET','GSI_ORT_NET','GSI_VECTOR'],
                            'LGWAN': ['GSI_PALE_NET_LGWAN','GSI_STANDARD_NET_LGWAN','GSI_ORT_NET_LGWAN']},
        url: process.env.MAP_URL,
        latitude: 34.1860410,
        longitude: 131.4706540
    },

    municInfo: {
        prefCd: '350001', //山口県の県コード
        prePrefCd: '35', //山口県の県コードの上2桁
        prefMunicCd: '350001', //長崎県の市町村コード(M_MUNICIPALITYにおける長崎県のコード)
        prefName: '山口県', //県名
        defaultMunicCd: '352039', // FIX 山口市の市町村コード
        defaultMunicCdForSimpleReport: '350001', // (被害情報で利用)デフォルトの市町コード 山口県の市町村コード
        cityDistrictMunicCds: [],
        parentCdsForSpecialCities: [], // 県内の政令指定都市(長崎県では特別区部を政令指定都市とする)の市町村コード,
        cityMunicCd: '', // 特別区部の市町村コード
        cityMunicName: '○×区', // 操作主体となる自治体名
        wardList: [], // 政令指定都市内の区
        prefRegCdGudge: '350', //自治体コード上3桁から、市町村ではなく、県/振興局であることを判断するコード
        kisyoAreaCd: '420012', //操作主体の気象エリアコード。気象ページの地区セレクターの制御に利用。
        cityMunicDistrictCd: '201000', //地区コード。地区セレクターの制御に利用。
        cities4multiDistShare: [], // 避難情報で、複数地区で同じポリゴンを多重に共有している市町村（5桁版コード）
        otherRelationOrg: '990001'//その他の行政機関
    },

    layerPubStatus: {
        roleList: ['R01001', 'R01011', 'R01012', 'R01912', 'R03012']
    },

    dept: {
        soumuDeptCd: 'D02000',
        fireDepartmentDeptCd: 'D46000',
        crisisManagementDeptCd: 'D50000',
        humanResourceDeptCd: 'D61000'
    },

    print: {
        /**
         * 印刷サービスでArcGISサーバーからの画像取得先
         */
        getImageUrl: function () {
            // 内部向けWebサーバー
            return 'http://' + location.host;
        }
    },

    /**
     * Esri地図モジュール用の設定（モバイルで利用）
     */
    esri: {
        initExtent: [{
            ymin: 33.6860410, // latitude - 0.5
            ymax: 34.6860410, // latitude + 0.5
            xmin: 130.7706540, // longitude - 0.7
            xmax: 132.1706540 // longitude + 0.7
        }],
        bookmarks: [{
            name: '庁舎',
            ymin: 34.1860410,
            ymax: 34.1860410,
            xmin: 131.4706540,
            xmax: 131.4706540
          }]
    },
    /**
     * 防災ポータル情報
     */
    portalInfo: {
        url: 'http://example.com/portal'
    },

    /**
     * サーバ情報
     */
     server: {
        adisWeb: process.env.ADISWEB_PATH,
        adisTraining: process.env.ADISTRAINING_PATH
    },

    // ファイル共有サイト（外部サイト）のURL
    fileShare: {
        url: '/fileshare'
    },

    // ヘルプデスクの連絡先
    helpdesk: {
        mail: 'bousai-helpdesk@dcloud.z-hosts.com',
        tel: '050-3196-1912'
    }
});
