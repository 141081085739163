/**
 * 土砂災害危険度(1kmメッシュ)など, 時刻ごとに見せるタイルを切り換えるレイヤー
 * @module app/observationmap/ObservationRasterTMSLayer
 */
define([
    'app/observation/map/ObservationRasterLayer'
], function (ObservationRasterLayer) {

        var _layer = ObservationRasterLayer.extend({
        initialize: function (layerInfo) {
            var options = {
                minZoom: layerInfo.minZoom ? layerInfo.minZoom : 8,
                maxZoom: layerInfo.maxZoom ? layerInfo.maxZoom : 18,
                maxNativeZoom: layerInfo.overlayZoom ? layerInfo.overlayZoom : 12,
                tms: true
            };

            ObservationRasterLayer.prototype.initialize.call(this, layerInfo, options);
        }
    });

    return _layer;
});