/**
 * スネークラインの詳細画面に繋ぐための1kmメッシュレイヤー
 *
 */
define([
    'dojo/_base/lang',
    'dojo/_base/array',
    'leaflet',
    // 引数で受けない
    'idis/control/GeoJSONTileLayer'
], function(
    lang, array,
    leaflet
) {
    var defaultURL = '/data/map/mesh/1km/{z}/{x}/{y}.geojson';

    var VectorMeshLayer = leaflet.GeoJSONTileLayer.extend({
        
        initialize: function(layerInfo, options, geojsonOptions) {
            var url = layerInfo.layerUrl || defaultURL;
            
            options = options || {};
            geojsonOptions = geojsonOptions || {};
            
            // styleのfunctionは何かしら必要なので, _styleというデフォルトのスタイル定義を用意しておいて
            // 指定がなければそれを使う
            if (!geojsonOptions.style || typeof(geojsonOptions.style) !== 'function') {
                lang.mixin(geojsonOptions, {
                    style: this._style
                });
            }

            // filterもstyleと同様にデフォルトを定義する
            // filterは他のものに差し替えると正しく表示されないので, 独自に実装するときは
            // this._filterの処理をコピーして, そこにやりたいことを足すこと.
            if (!geojsonOptions.filter || typeof(geojsonOptions.filter) !== 'function') {
                lang.mixin(geojsonOptions, {
                    filter: this._filter
                });
            }

            // uniqueも同様. タイルまたがりのfeatureの一意性を判定するもので, よっぽどの理由がない限り上書き不要.
            if (!options.unique || typeof(options.style) !== 'function') {
                lang.mixin(options, {
                    unique: this._unique
                });
            }

            // superを呼び出し, 呼び出すと自動的にタイルへアクセスされ, 地物がロードされる
            leaflet.GeoJSONTileLayer.prototype.initialize.call(this, url, options, geojsonOptions);
        },

        /**
         * タイルファイル1つがロードされた時の処理
         * 
         * 1つの地物(メッシュ)がタイルをまたがって存在する場合がある.
         * その時は, GeoJSONTileLayerのaddTileDataによって1つのレイヤー内にまたがった複数の地物が共存して入っている.
         * この入っている地物をマージして, 1つの大きな地物を作って既存のレイヤーと置き換える処理を行う.
         */
        _tileLoaded: function() {
            // superを呼び出し, 呼び出した中でタイルをまたがった地物の共存をやっている
            leaflet.GeoJSONTileLayer.prototype._tileLoaded.apply(this, arguments);

            // ここから共存して入っている地物の統合をする.
            // eachLayerで得られる各レイヤーはlayergroupになっていて, ただのpolygonなどではないことに注意.
            this.geojsonLayer.eachLayer(lang.hitch(this, function(layer) {

                // layerがlayergroupでない場合 => 1つの地物である => タイルまたがりではないので何もしない
                if (!(layer instanceof leaflet.LayerGroup)) {
                    return;
                }

                // layergroupの中に入っているレイヤーの数が1つ => タイルまたがりではないので何もしない
                if (layer.getLayers().length === 1) {
                    return;
                }

                // 大きな地物を表す領域(leaflet.LatLngBound)
                var extendedBound;

                // 座標情報は,
                // - _layers(layerGroupの各Layer)
                // - feature.geometry
                // の2つに格納されていて, どちらかを見ただけでは不十分になってしまう.
                // このため, 両方の全ての座標群をチェックして, 最大の領域が得られるようにしている.

                // feature.geometryから座標を得る
                array.forEach(layer.feature.geometry.geometries, function(g) {

                    // ここに入っている座標は(lng, lat)の順に入っているので, 逆にしながらLatLngオブジェクトを作る
                    var latlngs = array.map(g.coordinates[0][0], function(c) {
                        return leaflet.latLng(c[1], c[0]);
                    });

                    // 得られたlatlngから領域を作る
                    var bound = leaflet.latLngBounds(latlngs);

                    // 初回はそのまま入れる
                    // 2回目以降はextendを使って, 自分が持っている領域を広げる
                    // ref. https://leafletjs.com/reference-0.7.7.html#latlngbounds-extend
                    if (!extendedBound) {
                        extendedBound = bound;
                    } else {
                        extendedBound.extend(bound);
                    }
                });

                // LayerGroupに入っている各レイヤーから座標を得る
                layer.eachLayer(function(l) {

                    // getboundというfunctionがなかったら領域を得られないので何もしない
                    if (typeof(l.getBound) !== 'function') {
                        return;
                    }

                    // getboundした得られた領域をextendBoundに入れていく
                    if (!extendedBound) {
                        extendedBound = l.getBound();
                    } else {
                        extendedBound.extend(l.getBound());
                    }
                });

                // この結果として得られたextendBoundは, このレイヤーが持っている各座標を統合した領域情報.
                // この領域で作ったポリゴンに置き換える

                // まずは, 現在入っているレイヤーをclearlayersで全部消す
                layer.clearLayers();

                // extendedBoundの領域で新しくレイヤーを作る. メッシュ(四角)なのでrectangleを作る.
                var newLayer = leaflet.rectangle(extendedBound);

                // 作ったrectangleをaddLayerする
                layer.addLayer(newLayer);

                // スタイルを他と合わせる
                this.geojsonLayer.resetStyle(layer);
            }));

        },

        /**
         * デフォルトのスタイル
         * @param {Object}} feature
         */
        _style: function() {
            return {
                weight: 1,
                fill: true,
                fillOpacity: 0.2
            };
        },

        /**
         * デフォルトのフィルター
         * 
         * MultiPolygon(レイヤーをまたがらないポリゴン)とGeometryCollection(タイルをまたがったポリゴンが入る)以外を取り除く.
         * 地物の中にMultiLineStringなどが入っているときに, これらを取り除いておかないと_tileload処理が正しく動かない.
         * 
         * 独自のfilterを作りたい時は, 必ずこの中の処理をコピーしてからやりたいことを足すこと.
         * 
         * @param {object} feature 
         */
        _filter: function(feature) {
            if (!feature.geometry || 
                (feature.geometry.type !== 'GeometryCollection' && feature.geometry.type !== 'MultiPolygon')) {
                    return false;
            }
            return true;
        },

        /**
         * タイルをまたがったfeatureをまとめるためのキーを戻すfunction.
         * @param {object} feature 
         */
        _unique: function (feature) {
            return feature.id; 
        }
    });

    return VectorMeshLayer;
});