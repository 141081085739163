/**
 * 地図のクリックでポップアップを表示するラスターレイヤー
 *
 * @module app/map/mapLayer
 */
define([
    'dojo/_base/lang',
    'dojo/json',
    'dojo/text!./templates/SedimentMeshMapPopup.html',
    'leaflet',
    'idis/service/Requester',
    'app/map/layer/ClickableRasterTileLayer',
    'app/map/layer/CalcMeshCode'
], function(lang, JSON, template, leaflet, Requester,
            ClickableRasterTileLayer, CalcMeshCode) {
    var mapLayer = ClickableRasterTileLayer.extend({

        // コンストラクタ
        initialize: function(layerUrl, layerInfo, opacity) {
            ClickableRasterTileLayer.prototype.initialize.call(
                this,
                layerUrl,
                layerInfo,
                opacity
            );
            this.layerInfo = layerInfo;
        },

        // mapをクリックされた時に動く
        _onclick: function(event) {

            var layerid = this.layerInfo.id;
            // チェックボックス1km,5km
            var km = (layerid === 45)? '1':'5';
            // jsonを取得
            var url = '/data/weather-mesh/sediment-risk-' + km + 'km/latest.json';
            console.log('url:' + url);
            var jpDate;
            var shortTime;
            // 最新日付を取得
            Requester.get(url, {preventCache: false}).then(lang.hitch(this, function(jsondeta) {
                // ない時は何もしない
                if (!jsondeta) {
                    return;
                }

                var newDate = jsondeta.latestDateTimestamp;

                // ポップアップに表示する日付を整形
                jpDate = newDate.substr(0,4) + '年' + newDate.substr(5,2) + '月' + 
                            newDate.substr(8,2) + '日'; 
                shortTime = newDate.substr(11,2) + ':' + 
                            newDate.substr(14,2);

                // 画像名取得用にメッシュを取得
                var mesh = (layerid === 45)? 
                CalcMeshCode.convertLatLngToMeshCode(event.latlng.lat, event.latlng.lng, 3):
                CalcMeshCode.convertLatLngTo5KmMeshCode(event.latlng.lat, event.latlng.lng);

                var png = '/data/jwa/snake-line/' + km + 'km/' + mesh + '_snakeK.png';
                var http = new XMLHttpRequest();
                // 画像が存在しているかチェック
                http.open('HEAD', png, false);
                http.send();
                if (http.status === 404){
                    // ない場合、ポップアップ出力しない
                    return;
                }

                // ポップアップに表示する内容
                var popupContent = '<table>';
                    popupContent += jpDate + ' ';
                    popupContent += shortTime + '現在';
                    popupContent += '<br>';
                    popupContent += '<img src="' + png;
                    popupContent += '" style="width:250px"> ';
                    popupContent += '</table>';
                // templateの文言に表示する内容を置換
                var content = template.replace('%content%', popupContent);
                // popup.onRemove(this._map);
                // ポップアップに設定
                var popup = leaflet.popup();
                popup.setLatLng(event.latlng)
                    .setContent(content)
                    .openOn(this._map);

            }), lang.hitch(this, function(error) {
                console.log(error.jsondeta.text);
                if (error.jsondeta.status !== 404) {
                    var content = '<div><h3>エラー</h3>' + error.jsondeta.text + '</div>';
                    var popup = leaflet.popup();
                    popup.setLatLng(event.latlng)
                        .setContent(content)
                        .openOn(this._map);
                }
            }));
        }
    });

    return mapLayer;
});
