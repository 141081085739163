/**
 * Content-Typeを定数として定義。
 */
define({
    // JSON形式
    JSON: 'application/json; charset=utf-8',
    // FORM形式
    FORM: 'application/x-www-form-urlencoded',
    // OCTET STREAM形式
    OCTET_STREM: 'application/octet-stream'
});
