/**
 * ヘッダー用モジュール。
 * @module app/view/SpecteeHeader
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/dom-style',
    'dojo/topic',
    'dojo/text!./templates/SpecteeHeader.html',
    'idis/model/UserInfo',
    'idis/view/_IdisWidgetBase',
    // 以下、変数で受けないモジュール
    'idis/view/dialog/IdisDialog',
    'app/view/PopupAlert',
    'app/view/Menu',
    'idis/view/form/GeocodingForm',
    'idis/view/form/MunicipalitySelectForm',
    'dijit/form/Select',
    'dijit/form/Form',
    'dijit/form/Button',
    'app/view/form/DisasterChanger',
    'dojox/mobile/SimpleDialog',
], function (module, declare, domStyle, topic, template, UserInfo, _IdisWidgetBase) {
    /**
     * ヘッダー用オブジェクト
     * @class Header
     */
    return declare(module.id.replace(/\//g, '.'), _IdisWidgetBase, /** @lends app/view/Header~Header# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素のCSSクラス
        baseClass: 'spectee-Header',

         // DOMを構築する
        buildRendering: function () {
            this.inherited(arguments);
            if (UserInfo.isAuthed()) {
                // 認証済みの場合
                if (UserInfo.getLoginMode() === '訓練') {
                    // 訓練
                    domStyle.set(this.disaster, 'display', 'none');
                    domStyle.set(this.training, 'display', '');
                } else {
                    // 実災害
                    domStyle.set(this.disaster, 'display', '');
                    domStyle.set(this.training, 'display', 'none');
                }
            }
        },

        // DOMの準備が完了してから呼ばれる
        postCreate: function () {
            this.inherited(arguments);
        },

        startup: function () {
            this.inherited(arguments);
        },

        /**
         * 現在地ボタンクリック時の動作。
         * @param {MouseEvent} evt クリック・イベント
         */
        onCurrentPositionButtonClick: function() {
            topic.publish(module.id + '::onCurrentPosition');
        }
    });
});
