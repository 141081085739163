/**
 * 画面（上部）に通知を出す
 * @module app/common/notifier/NotifierTop
 */
define([
    'module',
    'dojo/dom-class',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/NotifierTop.html',
    'idis/view/_IdisWidgetBase',
    './Notify'
], function (module, domClass, array, declare, lang, template, _IdisWidgetBase, Notify) {
    return declare(module.id.replace(/\//g, '.'), _IdisWidgetBase, {
        // テンプレート文字列
        templateString: template,

        /**
         *
         * @param {*} obj メッセージが入ったオブジェクト
         *  message(required): メッセージ本体
         *  title: タイトル
         *  level: {high/middle/low} (default: low)
         *  timeout: ポップアップを出し続ける時間 (default: 0[消えない])
         *  page: ポップアップクリック時に遷移させたいページ(adisrouterに登録したもの)
         *  url: ポップアップクリック時に遷移させたいURL, pageとurlが両方登録されていたらpageが優先
         */
        notify: function(obj) {
            if (!obj || !obj.message) {
                return;
            }

            return new Notify(lang.mixin(obj, {
                _container: this.notifierContainer
            }));
        }

    });
});