/**
 * アプリ固有のユーティリティーを提供。
 * @module app/AppUtils
 */
define([
    'idis/util/DateUtils',
    '../AppUtils'
], function(DateUtils, AppUtils) {
    /**
     * 指定された日時を日本の表示形式に変換する。
     * @param {number|string|Date} value 変換対象の値
     * @param {boolean} includeYear 年の表示を含むか
     * @returns {string} 'yyyy年MM月dd日'形式の文字列
     */
    function _jpDate(value, includeYear) {
        return DateUtils.formatDate(value, includeYear ? 'yyyy年MM月dd日' : 'MM月dd日');
    }

    /**
     * 指定された日時を日本の表示形式に変換する。
     * @param {number|string|Date} value 変換対象の値
     * @returns {string} 'hh時mm分'形式の文字列
     */
    function _jpTime(value) {
        return DateUtils.formatTime(value, 'HH時mm分');
    }

    /**
     * 指定された日時を日本の表示形式に変換する。
     * @param {number|string|Date} value 変換対象の値
     * @param {boolean} includeYear 年の表示を含むか
     * @returns {string} 'yyyy年MM月dd日 mm月dd日'形式の文字列
     */
    function _jpDateTime(value, includeYear) {
        return _jpDate(value, includeYear) + ' ' + _jpTime(value);
    }

    /**
     * 指定された日時を短い表示形式に変換する。
     * @param {number|string|Date} value 変換対象の値
     * @param {boolean} includeYear 年の表示を含むか
     * @returns {string} 'yyyy年MM月dd日'形式の文字列
     */
    function _shortDate(value, includeYear) {
        return DateUtils.formatDate(value, includeYear ? 'yy/MM/dd' : 'MM/dd');
    }

    /**
     * 指定された日時を短い表示形式に変換する。
     * @param {number|string|Date} value 変換対象の値
     * @returns {string} 'hh時mm分'形式の文字列
     */
    function _shortTime(value) {
        return DateUtils.formatTime(value, 'HH:mm');
    }

    /**
     * 指定された日時を短い表示形式に変換する。
     * @param {number|string|Date} value 変換対象の値
     * @param {boolean} includeYear 年の表示を含むか
     * @returns {string} 'yyyy年MM月dd日 mm月dd日'形式の文字列
     */
    function _shortDateTime(value, includeYear) {
        return _shortDate(value, includeYear) + ' ' + _shortTime(value);
    }

    /**
     * 指定された日時を公開防災Web用の表示形式に変換する。
     * @param {number|string|Date} value 変換対象の値
     * @param {boolean} includeYear 年の表示を含むか
     * @returns {string} 'yyyy年MM月dd日'形式の文字列
     */
    function _date(value, includeYear) {
        return AppUtils.isMobile() ? _shortDate(value) : _jpDate(value, includeYear);
    }

    /**
     * 指定された日時を公開防災Web用の表示形式に変換する。
     * @param {number|string|Date} value 変換対象の値
     * @returns {string} 'hh時mm分'形式の文字列
     */
    function _time(value) {
        return AppUtils.isMobile() ? _shortTime(value) : _jpTime(value);
    }

    /**
     * 指定された日時を公開防災Web用の表示形式に変換する。
     * @param {number|string|Date} value 変換対象の値
     * @param {boolean} includeYear 年の表示を含むか
     * @returns {string} 'yyyy年MM月dd日 mm月dd日'形式の文字列
     */
    function _dateTime(value, includeYear) {
        return AppUtils.isMobile() ? _shortDateTime(value, includeYear) : _jpDateTime(value, includeYear);
    }

    return {
        jpDate: _jpDate,
        jpTime: _jpTime,
        shortTime: _shortTime,
        jpDateTime: _jpDateTime,
        date: _date,
        time: _time,
        dateTime: _dateTime
    };
});

