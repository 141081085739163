/**
 * 水位観測局マスターのストア
 *
 * 外部向けでJSONファイルを読み込んで利用する場合に使う
 *
 * @module app/model/RiverLevelObservatoryStore
 */
define([
    'dstore/RequestMemory'
], function(RequestMemory) {
    /**
     * シングルトンを返す。
     */
    return new RequestMemory({
        idProperty: 'observatoryId',
        target: '/data/master/observatory/riverLevelObservatory.json'
    });

});

