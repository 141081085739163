/**
 * 住所検索欄用モジュール。
 * フォーム要素を含むためフォーム内には設置出来ない。
 * @module idis/view/form/GeocodingForm
 */
define([
    'app/config',
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-class',
    'dojo/text!./templates/GeocodingForm.html',
    'dojo/topic',
    'dijit/form/TextBox',
    'esri-leaflet-geocoder',
    '../_IdisWidgetBase',
    '../dialog/DialogChain',
    // 以下、変数で受けないモジュール
    'dijit/form/Form',
    'dijit/form/TextBox'
], function(config, module, declare, lang, domClass, template, topic, TextBox, geocoder, _IdisWidgetBase, DialogChain) {
    var _coder = new geocoder.geocode({url: config.geocode && config.geocode.url});

    /**
     * 住所検索欄用ウィジェット
     * @class GeocodingInput
     * @extends module:dijit/form/TextBox~TextBox
     */
    return declare(module.id.replace(/\//g, '.'), _IdisWidgetBase,
        /** @lends module:idis/view/form/GeocodingForm~GeocodingForm# */ {
        templateString: template,

        // DOM要素を構築する
        buildRendering: function() {
            this.inherited(arguments);
            domClass.add(this.domNode, 'idis-GeocodingForm');
            // ダイアログ連鎖を設定
            this.chain = DialogChain.get(this);
        },

        /**
         * 検索欄の入力値を元に住所検索を行い、見つかった場合はイベントを発行する。
         */
        geocode: function() {
            // 入力文字列長チェック
            if (this.input.get('value').length > 200) {
                this.chain.info('200文字以下で入力してください。', 'エラー');
                return;
            }
            _coder.text(this.input.get('value')).run(lang.hitch(this, function(err, result) {
                if (err) {
                    // エラー時はダイアログを出して終了
                    this.chain.infoError(err);
                    return;
                }
                var results = result.results;
                if (!results.length) {
                    // 該当位置情報なし
                    this.chain.info('入力された住所が見つかりません。', 'エラー');
                    return;
                }
                var latlng = lang.mixin(null, results[0].latlng);
                topic.publish(module.id + '::geocoded', {latlng: latlng});
            }));
        },

        // 入力要素の無効化状態を切り替える。
        _setDisabledAttr: function(disabled) {
            this.input.set('disabled', disabled);
        },

        /**
         * 検索フォーム投稿時に呼ばれる。
         */
        onSubmit: function() {
            try {
                if (this.input.get('value')) {
                    this.geocode();
                }
            } finally {
                return false;
            }
        }
    });
});
