/**
 * esri-leaflet-geocoderのラッパー・モジュール。
 * @module idis/service/GeoService
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/Deferred',
    'esri-leaflet-geocoder'
], function(module, declare, lang, Deferred, EsriLeafletGeocoder) {
    /**
     * 住所情報オブジェクト
     * @typedef {Object} GeoService~Address
     * @property {string} LongLabel 末尾に国情報の入った住所情報
     * @property {string} Address 住所情報
     */

    /**
     * 住所取得結果オブジェクト
     * @typedef {Object} GeoService~ReverseResult
     * @property {L.LatLng} latlng Leafletの緯度経度
     * @property {GeoService~Address} address 住所情報
     */

    /**
     * 緯度経度と住所の相互変換サービスのラッパー・クラス。
     * @class GeoService
     * @param {Object} kwArgs
     * @param {string} [kwArgs.url] 相互変換サービスのURL
     */
    return declare(null, {
        _parameter: null,
        /**
         * geocode用インスタンス
         * @type {Object}
         * @private
         */
        _coder: null,

        /**
         * reverseGeocode用インスタンス
         * @type {Object}
         * @private
         */
        _reverseCoder: null,

        constructor: function (kwArgs) {
            this._parameter = kwArgs;
        },

        /**
         * ジオコード系サービスを作る
         * 作るときにAPI呼び出しが発生するので, constructorでは行わず, 必要なときにやる.
         */
        initialize: function () {
            var service = new EsriLeafletGeocoder.geocodeService(this._parameter);
            // geocode・reverseGeocode用インスタンス
            this._coder = service.geocode();
            this._reverseCoder = service.reverse();
        },

        /**
         * 指定された文字列から緯度経度を取得して返す。
         * @see {@link http://esri.github.io/esri-leaflet/api-reference/tasks/geocode.html}
         * @function geocode
         * @param {string} text 検索対象文字列
         * @returns {Promise<GeoService~Result[]>} 結果の配列
         */
        geocode: function(text) {
            if (!this._coder) {
                this.initialize();
            }

            var dfd = new Deferred();
            this._coder.text(text).run(lang.hitch(this, function(err, res) {
                    if (err) {
                    dfd.reject(err);
                } else {
                    dfd.resolve(res.results);
                }
            }));
            return dfd.promise;
        },

        /**
         * 指定された緯度経度から住所を取得して返す。
         * @see {@link http://esri.github.io/esri-leaflet/api-reference/tasks/reverse-geocode.html}
         * @param {L.LatLng} latlng Leafletの緯度経度
         * @param {number} [distance=10000] 指定座標から半径何メートルまでの住所を探すか
         * @returns {Promise<GeoService~ReverseResult>} 住所取得結果
         */
        reverseGeocode: function(latlng, distance) {
            if (!this._reverseCoder) {
                this.initialize();
            }

            var dfd = new Deferred();
            this._reverseCoder.latlng(latlng).distance(distance || 10000).run(lang.hitch(this, function(err, res) {
                if (err) {
                    dfd.reject(err);
                } else {
                    dfd.resolve(res);
                }
            }));
            return dfd.promise;
        }
    });
});

