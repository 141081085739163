/**
 * ヘッダー用モジュール。
 * @module app/convocation/view/ConvoHeader
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/dom-style',
    'dojo/text!./templates/ConvoHeader.html',
    'idis/control/Locator',
    'idis/model/UserInfo',
    'idis/view/_IdisWidgetBase',
    // 以下、変数で受けないモジュール
    'dijit/form/Form',
    './ConvoMenu'
], function(module, declare, domStyle, template, Locator, UserInfo, _IdisWidgetBase) {
    /**
     * ヘッダー用オブジェクト
     * @class ConvoHeader
     */
    return declare(module.id.replace(/\//g, '.'), _IdisWidgetBase,
            /** @lends app/convocation/view/ConvoHeader~ConvoHeader# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素のCSSクラス
        baseClass: 'index-Header',

        // DOMを構築する
        buildRendering: function() {
            this.inherited(arguments);
            this.loginIdNode.innerHTML = UserInfo.getName();

            var html = [];
            // ログアウト・フォーム
            html.push('<form method="POST" action="/api/auth/convocation/logout" name="logout" style="padding: 2px;">');
            // 戻り先を設定（現在のURLのpath部分）
            html.push('<input type="hidden" name="backPath" value="');
            html.push(Locator.getHref().split('?')[0]);
            html.push('">');
            html.push('<a href="javascript:document.forms.logout.submit()">');
            html.push('ログアウト</a></form>');

            // ログアウトボタンの中身を置き換える
            this.logoutButton.innerHTML = html.join('');

            if (UserInfo.isAuthed()) {
                // メニュー用ボタンを表示
                domStyle.set(this.menuButton, 'display', '');
                domStyle.set(this.controlArea, 'display', '');
            }
        },

        /**
         * タイトル文字列がクリックされた際に呼ばれ、トップ画面へ遷移させる。
         * @param {MouseEvent} evt クリック・イベント
         */
        onTitleLinkClick: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            // トップ画面以外にいる場合はトップ画面（通常は地図画面）へ遷移
            if (Locator.getQuery().p) {
                // ページへ遷移
                Locator.pushState('?', true);
            }
        }
    });
});