/**
 * ファイル・アップロード用のiframeを生成する。
 * @module idis/service/iframe
 */
define([
    'dojo/request/iframe'
], function(iframe) {
    // 初回リクエスト時は準備前にcallbackが呼ばれてしまうため
    // dojo/request/iframeの実装を参考にiframeを事前に作成
    // refs:
    // https://stackoverflow.com/questions/22919643/cannot-read-property-value-of-undefined-on-dojo-request-iframe-post
    if (!iframe._frame) {
        var onloadstr = iframe._iframeName.replace(/_IoIframe$/, '_onload();');
        iframe._frame = iframe.create(iframe._iframeName, onloadstr);
    }
    // dojo/request/iframeを返す
    return iframe;
});

