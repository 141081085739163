/**
 * ダイアログを連続的に利用するためのユーティリティー。
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    '../Loader',
    './ConfirmDialog'
], function(module, declare, lang, Loader, ConfirmDialog) {
    // 自分を閉じる関数
    var _hideSelf = function() {return this.hide();};

    /**
     * ダイアログを連鎖させるオブジェクト。
     * @class DialogChain
     * @param {Object} kwArgs
     * @param {module:dijit/_WidgetBase~_WidgetBase} owner ダイアログ連鎖を管理するウィジェット
     */
    var DialogChain = declare(null, /** @lends module:idis/view/dialog/DialogChain~DialogChain# */ {
        /**
         * このダイアログ連鎖に利用するダイアログ
         * @type {module:idis/view/dialog/DialogChain~DialogChain}
         */
        dialog: null,

        /**
         * このダイアログ連鎖を管理するウィジェット
         * @type {module:dijit/_WidgetBase~_WidgetBase}
         */
        owner: null,

        constructor: function(kwArgs) {
            lang.mixin(this, kwArgs);
            // このダイアログ連鎖を管理するウィジェットが破棄された際に合わせて破棄
            if (this.owner) {
                this.owner.own(this);
            }
        },

        /**
         * ダイアログを閉じる。
         * @returns {Promise} 閉じ終わったときに解決するPromise
         */
        hide: function() {
            return this.dialog.hide();
        },

        /**
         * このインスタンスを破棄する。
         * @type {boolean} preserveDom DOMを保持するか
         */
        destroy: function(preserveDom) {
            this.dialog.destroyRecursive(preserveDom);
            this.owner = null;
        },

        /**
         * 確認ダイアログを指定されたメッセージで表示する。
         *
         * @param {string} [message] 本文に表示するメッセージ
         * @param {function} onOK OKボタンが押されたときに実行する処理
         * @param {function} [onCancel] キャンセルボタンか閉じるボタンが押されたときに実行する処理
         * @returns {Promise} 開き終わったときに解決するPromise
         */
        confirm: function(message, onOK, onCancel) {
            if (!lang.isString(message)) {
                // メッセージ省略時は固定文言を表示
                onCancel = onOK;
                onOK = message;
                message = '処理を実行します。よろしいですか？';
            }
            // ダイアログ表示に反映
            this.dialog.set({
                title: '確認',
                content: message,
                showCancel: true
            });
            // onOK, onCancel内のthisはダイアログの持ち主
            this.dialog.onOK = lang.hitch(this.owner, onOK, this);
            // onCancel省略時はキャンセル時に自動的にダイアログを閉じる
            this.dialog.onCancelAfter = onCancel ? lang.hitch(this.owner, onCancel, this) : _hideSelf;
            return this.dialog.show();
        },

        /**
         * 指定された処理に対する確認ダイアログを表示する。
         *
         * @param {string} [action] 処理内容を表す文字列（'登録'など）
         * @param {string} [type] 処理対象の種別（'報告'など）
         * @param {function} onOK OKボタンが押されたときに実行する処理
         * @param {function} [onCancel] キャンセルボタンか閉じるボタンが押されたときに実行する処理
         * @returns {Promise} 開き終わったときに解決するPromise
         * @private
         */
        _confirmFor: function(action, type, onOK, onCancel) {
            var message = action + 'します。よろしいですか？';
            if (lang.isString(type)) {
                // 名称を適用
                message = type + 'を' + message;
            } else {
                // 名称省略時
                onCancel = onOK;
                onOK = type;
            }
            return this.confirm(message, onOK, onCancel);
        },

        /**
         * 登録確認ダイアログを表示する。
         *
         * @param {string} [type] 登録するものの種別（'報告'など）
         * @param {function} onOK OKボタンが押されたときに実行する処理
         * @param {function} [onCancel] キャンセルボタンか閉じるボタンが押されたときに実行する処理
         * @returns {Promise} 開き終わったときに解決するPromise
         */
        confirmAdd: function(type, onOK, onCancel) {
            return this._confirmFor('登録', type, onOK, onCancel);
        },

        /**
         * 報告確認ダイアログを表示する。
         *
         * @param {string} [type] 登録するものの種別（'報告'など）
         * @param {function} onOK OKボタンが押されたときに実行する処理
         * @param {function} [onCancel] キャンセルボタンか閉じるボタンが押されたときに実行する処理
         * @returns {Promise} 開き終わったときに解決するPromise
         */
        confirmAddHoukoku: function(type, onOK, onCancel) {
            return this._confirmFor('報告', type, onOK, onCancel);
        },

        /**
         * 更新確認ダイアログを表示する。
         *
         * @param {string} [type] 登録するものの種別（'報告'など）
         * @param {function} onOK OKボタンが押されたときに実行する処理
         * @param {function} [onCancel] キャンセルボタンか閉じるボタンが押されたときに実行する処理
         * @returns {Promise} 開き終わったときに解決するPromise
         */
        confirmPut: function(type, onOK, onCancel) {
            return this._confirmFor('更新', type, onOK, onCancel);
        },

        /**
         * 削除確認ダイアログを表示する。
         *
         * @param {string} [type] 登録するものの種別（'報告'など）
         * @param {function} onOK OKボタンが押されたときに実行する処理
         * @param {function} [onCancel] キャンセルボタンか閉じるボタンが押されたときに実行する処理
         * @returns {Promise} 開き終わったときに解決するPromise
         */
        confirmDel: function(type, onOK, onCancel) {
            return this._confirmFor('削除', type, onOK, onCancel);
        },

        /**
         * 情報ダイアログを指定されたメッセージで表示する。
         *
         * @param {string} message 本文に表示するメッセージ
         * @param {string} [title] タイトル文字列
         * @param {function} [onOK] OKボタンが押されたときに実行する処理
         * @returns {Promise} 開き終わったときに解決するPromise
         */
        info: function(message, title, onOK) {
            if (!lang.isString(title)) {
                // onOKだけ指定された場合
                if (lang.isFunction(title)) {
                    onOK = title;
                }
                // タイトル省略時は固定文言を表示
                title = '情報';
            }
            // ダイアログ表示に反映
            this.dialog.set({
                title: title,
                content: message,
                showCancel: false
            });

            // onOK, onCancel内のthisはダイアログの持ち主
            // onOK省略時は自動的にダイアログを閉じる
            this.dialog.onOK = onOK ? lang.hitch(this.owner, onOK, this) : _hideSelf;
            // 右上のキャンセルボタン押下時はOKボタン押下時と同じ動きをする
            this.dialog.onCancelAfter = onOK ? lang.hitch(this.owner, onOK, this) : _hideSelf;
            return this.dialog.show();
        },

        /**
         * 完了情報ダイアログを表示する。
         * @param {function} [onOK] OKボタンが押されたときに実行する処理
         * @returns {Promise} 開き終わったときに解決するPromise
         */
        infoComplete: function(onOK) {
            return this.info('完了しました。', '完了', onOK);
        },

        /**
         * エラー情報ダイアログを表示する。
         * @param {Error} [err] エラー・オブジェクト
         * @param {function} [onOK] OKボタンが押されたときに実行する処理
         * @returns {Promise} 開き終わったときに解決するPromise
         */
        infoError: function(err, onOK) {
            var message = 'エラーが発生しました。';
            // エラー省略時
            if (lang.isFunction(err)) {
                onOK = err;
                err = null;
            }
            // サーバから受け取ったメッセージがある場合、表示する
            if (err.response && err.response.status) {
                if(err.response.status === 409) {
                    // レスポンスが409の場合は下記のエラーメッセージを使用する
                    message += '<br>' + '同一のデータを別の方が更新しています。' +  '<br>' +
                               '「OK」を押して最新の状態をご確認の上、' +  '<br>' +
                               '再度処理をお願いします。' +  '<br>' +
                               '(OKボタンを押すと画面が更新されます)';
                    // 「OK」ボタン押下後はページをリロードする
                    onOK = function() {
                        location.reload();
                    };
                } else {
                    if(err.response.data && err.response.data.messages){
                        message += '<br>' + err.response.data.messages.join('<br>');
                    }
                }
            }
            // TODO エラー情報の出力
            return this.info(message, 'エラー', onOK);
        }
    });

    /**
     * DialogChainインスタンスを取得する。
     * @function get
     * @param {module:dijit/_WidgetBase~_WidgetBase} owner ダイアログ連鎖を管理するウィジェット
     * @returns {module:idis/view/dialog/DialogChain~DialogChain} ダイアログ連鎖インスタンス
     */
    DialogChain.get = function(owner) {
        return new DialogChain({
            dialog: new ConfirmDialog({hideOnCancel: false}),
            owner: owner
        });
    };
    return DialogChain;
});
