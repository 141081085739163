define([
    'dojo/_base/lang',
    'dojo/on',
    'dojo/query',
    'dojo/topic',
    'dojox/lang/functional/object',
    'dojo/dom-class',
    'dojo/dom-construct'
], function(lang, on, query, topic, funcObj, domClass, domConstruct) {
    return {
        /**
         * MultiTypeのFeatureを１つ１つSingleのFeatureに変換する。
         * in: Multi layers
         * out: [single layer]
         */
        _multiToSingle: function(orgLayer) {
            var layerArr = [];
            orgLayer.eachLayer(function(multiLayers){
                var geoInfo  = multiLayers.feature.geometry;
                var propInfo = multiLayers.feature.properties;
                // for description
                var popup4Shape = '';
                Object.keys(propInfo).forEach(function(aRow){
                    if (popup4Shape!==''){popup4Shape+='<br/>';}
                    var content = aRow + ' : ' + propInfo[aRow];
                    popup4Shape += content;
                });
                var drawType4Shape =
                geoInfo.type === 'MultiPoint'      ? 'marker'   :
                geoInfo.type === 'MultiPolygon'    ? 'polygon'  :
                geoInfo.type === 'MultiLineString' ? 'polyline' :
                geoInfo.type === 'Point'           ? 'marker'   :
                geoInfo.type;

                // shapefileによると思うがmultiPointは返却されない
                if (geoInfo.type === 'Point') {
                    var layer = multiLayers;
                    layer.options.drawType = drawType4Shape;
                    layer.options.title    = '';
                    layer.options.description = popup4Shape;
                    if (popup4Shape !== '') {
                        layer.bindPopup(popup4Shape);
                    }
                    layerArr.push(layer);
                } else {
                    multiLayers.eachLayer(function(layer){
                        layer.options.drawType = drawType4Shape;
                        layer.options.title    = '';
                        layer.options.description = popup4Shape;
                        if (popup4Shape !== '') {
                            layer.bindPopup(popup4Shape);
                        }
                        layerArr.push(layer);
                    });
                }
            });
            return layerArr;
        },

        /**
         * 複数のGeoJSONをMergeする
         */
        _mergeGeoJSON: function(tanjsonArr, contentType) {
            // 配列内のtanjsonのfeaturesのみをmergeする
            var featureList = [];
            // 返却用Object
            var mergedTanJSON = {'type':'FeatureCollection'};

            // loopの中でfunction作るな！と怒られるので。
            var pushFeatures = function(feature){featureList.push(feature);};
            tanjsonArr.forEach(lang.hitch(this, function(tanjson){
                if (contentType === 'feature') {
                    pushFeatures(tanjson);
                } else {
                    tanjson.features.forEach(pushFeatures);
                }                
            }));
            mergedTanJSON.features = featureList;
            console.log('json created & merged');

            return mergedTanJSON;
        },

        /**
         * 改行コードと改行タグを変換 ” をエスケープする。（付箋以外）
         */
        _replaceContent: function(content){
            if(!content){return;}
            var newCntnt = '';
            if (content.indexOf('<br/>') === -1) {
                newCntnt = content.replace(/\n/g, '<br/>');
            } else {
                newCntnt = content.replace(/<br\/>/g, '\n');
            }
            return newCntnt.replace(/\"/g, '\\\"');
        },

        /**
         * 作図オブジェクトに対するポップアップの設定（付箋以外）
         */
        _setPopup: function(layer, type, title, description){
            if ( layer === void 0 || type === void 0) {return;}
            var ttl = '';
            var dscrpt = '';
            var fileList = [];
            var content = '';
            if (type === 'edit') {
                layer.options.title = title;
                layer.options.description = description;
            }
            ttl = layer.options.title;
            dscrpt = layer.options.description;
            fileList = layer.options.fileList;
            if (ttl === void 0 && dscrpt === void 0) {
                if (fileList && fileList.length === 0) {
                    layer.unbindPopup();
                } else {
                    content = '<b>添付ファイル:</b><br/>';
                    content += this._createPopupLink(fileList);
                    layer.bindPopup(content);
                }
            } else {
                ttl = !ttl ? '無題' : ttl;
                content += '<b><font color="red">' + ttl + '</font></b>';
                if (!!dscrpt) {
                    content += '<br/><br/>' + dscrpt;
                }
                if (fileList && fileList.length !== 0) {
                    content += '<br/><br/><b>添付ファイル:</b><br/>' + this._createPopupLink(fileList);
                }
                layer.bindPopup(content, {maxWidth: 500});
            }
        },

        /**
         * aタグを生成する
         */
        _createPopupLink: function(files) {
            var content = '';
            files.forEach(lang.hitch(this, function(file, idx){
                if (idx !== 0) {content += '<br/>';}
                content += this._createDownLoadNode({
                    'name' : file.name,
                    'url'  : file.url
                }).outerHTML;
            }));
            return content;
        },

        /**
         * get: アイコンのサイズを取得する。
         * set: アイコンにサイズを設定する。
         * target, get/set
         */
        _iconUtil: function(type, iconUrl, args) {
            var image = new Image();
            image.src = iconUrl;
            if (type === 'get' && !!args) {
                var mag = args[0] / image.naturalWidth;
                var values = [0.5, 1.0, 1.5, 2.0, 2.5];
                if (values.indexOf(mag) === -1) {
                    var diff = [];
                    var idx = 0;
                    values.forEach(function(i, v){
                        diff[i] = Math.abs(mag - v);
                        idx = diff[idx] < diff[i] ? idx : i;
                    });
                    mag = values[idx];
                }
                return mag;
            } else if (type === 'set' && !!args){
                return [image.naturalWidth * args, image.naturalHeight * args];
            } else {
                return [image.naturalWidth, image.naturalHeight];
            }
        },

        /**
        *  domNode.remove()がIEでは無効。
        */
        _rmElement: function(node){
            node.parentNode.removeChild(node);
        },

        /**
        *  NodeList / HTMLCollection を配列にして返す。
        */
        _toArray: function(HTMLCollection){
            return Array.prototype.slice.call(HTMLCollection);
        },

        /**
         * image / fileTypeごとのpreview処理
         */
         _createIconNode: function(fileInfo, targetClass) {
            var tgtCls = targetClass === void 0 ?
                'is-showPreview' : targetClass;
             var targetExt = '';
             var tmpName = fileInfo.name;
             var fileExt = tmpName.toUpperCase().slice(
                           tmpName.lastIndexOf('.'), tmpName.length);
             var extensionType = {
                 'image' : ['.JPG','.JPEG','.PNG','.GIF'],
                 'excel' : ['.XLS', '.XLSX'],
                 'word'  : ['.DOC', '.DOCX'],
                 'ppt'   : ['.PPT', '.PPTX'],
                 'pdf'   : ['.PDF'],
                 'zip'   : ['.ZIP']
             };
             funcObj.forIn(extensionType, function(extArr, idx){
                 extArr.forEach(function(ext){
                    if (ext === fileExt) {targetExt = idx;}
                 });
             });
             targetExt = targetExt === '' ? 'other' : targetExt;
             var icon = new Image();
             icon.src = targetExt === 'image' ?
                fileInfo.url : 'images/'+targetExt+'icon.png';
             domClass.add(icon, tgtCls);
             return icon;
         },

         /**
         * DownLoad用のDomNodeを生成する。
         */
         _createDownLoadNode: function(fileInfo) {
             var content = this._isIE() ? this._fileInfoConvertForIE(fileInfo)
                                        : fileInfo;
             // プレビュー用のノードを作成
             return domConstruct.create('a', !this._isIE() ? {
                     // chrome, FF
                     innerHTML: content.name,
                     href     : content.url,
                     download : content.name,
                     'data-draw-download' : fileInfo.url
                } : {
                     // IE
                     innerHTML: content.name,
                     href     : '#',
                     'class'  : 'drawDownload4IE',
                     'data-draw-download' : content.url,
                     onclick  : function(){
                         require(['dojo/topic'], function(topic){
                             topic.publish('/app/view/page/MapPage::download4IE', {
                                 name : content.name,
                                 url  : content.url
                             });
                         });
                     }
                }
             );
         },

         /**
         * IE用にaタグのAttributeを変換する。
         */
         _fileInfoConvertForIE: function(fileInfo){
             var newFileInfo = {};
             newFileInfo.name = fileInfo.name.indexOf('\\') !== -1 ?
                    fileInfo.name.slice(fileInfo.name.lastIndexOf('\\')+1) : fileInfo.name;
             newFileInfo.url  =
                    location.protocol + '//' +
                    location.hostname + ':' +
                    location.port + fileInfo.url;
             return newFileInfo;
         },

         /**
         * DownLoad可能なImageの生成
         */
         _createDownLoadImage: function(fileInfo, targetClass) {
             // プレビュー用のノードを作成
             var aNode = this._createDownLoadNode(fileInfo);
             aNode.textContent = '';
             var image = this._createIconNode(fileInfo, targetClass);
             aNode.appendChild(image);
             return aNode;
         },

         /**
          * IE対策 ダウンロード用ファイルを取得する
          */
         _downloadFile: function(fileInfo) {
             // Dojoのrequestor(dojo/request/xhr)のresponseType（handleAsプロパティ）で、
             // バイナリ（arraybuffer, blob）が利用できなかったためネイティブJSを利用している.
             var xhr = new XMLHttpRequest();
             xhr.open('GET', fileInfo.url, true);
             xhr.responseType = 'arraybuffer';
             xhr.onload = function() {
                 var arrayBuffer = this.response;
                 var blob = new Blob([arrayBuffer], {type: 'application/octet-stream'});
                 // IE10+
                 if(window.navigator.msSaveOrOpenBlob){
                     window.navigator.msSaveOrOpenBlob(blob, fileInfo.name);
                 }
             };
             xhr.send();
             return false;
         },

         /**
          * クリックで即時DownLoad
          */
         _doDownload: function(content, fileName, extension){
             var a    = null;
             var evt  = null;
             var blob = null;
             var name = (fileName === void 0 && extension === void 0) ?
              'allFeaturesOnMap.geojson' : fileName + extension;

             // IE10+
             if(window.navigator.msSaveOrOpenBlob){
                 blob = new Blob([content],{'type':'application/octet-stream'});
                 window.navigator.msSaveOrOpenBlob(blob, name);
             } else {
             // firefox, chrome
                 blob = new Blob([content],{'type':'text/plain'});

                 a = document.createElement('a');
                 a.setAttribute('target', '_blank');
                 a.setAttribute('href',window.URL.createObjectURL(blob));
                 a.setAttribute('download', name);

                 // IE対策
                 a.innerHTML = '<a></a>';
                 var el = a.childNodes[0];
                 a.removeChild(el);

                 evt = document.createEvent('MouseEvents');
                 evt.initEvent('click', false, false);
                 a.dispatchEvent(evt);
             }
         },

         /**
          * ブラウザチェック
          * TODO 他のブラウザチェックも必要なら
          */
         _isIE: function(){
             var userAgent = window.navigator.userAgent.toLowerCase();
             if (userAgent.match(/(msie|MSIE)/) || userAgent.match(/(T|t)rident/)){
                 return true;
             }
         },

         _setPopupEvtForMap: function(scope){
             // popupが開かれるイベントを拾って、aタグがあれば、msSaveのイベントを付与する。
             scope.own(
                 scope.map.on('popupopen', lang.hitch(this, function(){
                     var targetAnchor = query('a[class="drawDownload4IE"]');
                     targetAnchor.forEach(lang.hitch(this, function(anchor){
                         var downloadEvt = on(anchor, 'click',
                            lang.hitch(this, function(e){
                                this._downloadFile({
                                    name : e.target.outerText,
                                    url  : e.target.dataset.drawDownload
                                });
                            })
                        );
                        scope._downloadEvts.push(downloadEvt);
                    }));
                 }))
             );
             // popupが閉じるイベントを拾って、付与したイベントを破棄する。
             scope.own(
                 scope.map.on('popupclose', function(){
                     scope._downloadEvts.forEach(function(dlEvt){
                         dlEvt.remove();
                     });
                 })
             );
             // popup以外のanchor
             scope.own(topic.subscribe(scope.DOWNLOAD_4IE, lang.hitch(this, function(file){
                 this._downloadFile(file);
             })));
         }
    };
});
