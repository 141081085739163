/**
 * 共通ボタン用モジュール。
 * @module idis/view/form/Button
 */
define([
    'module',
    'dojo/_base/declare',
    'dijit/form/Button'
], function(module, declare, Button) {
    /**
     * 共通ボタン・ウィジェット。
     * @class Button
     * @extends module:dijit/form/Button~Button
     */
    return declare(module.id.replace(/\//g, '.'), Button,
        /** @lends module:idis/view/form/Button~Button# */ {
        'class': 'alt-primary'
    });
});
