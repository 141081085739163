define([
    'idis/view/draw/_DrawUtil'
], function(Util) {
    return {
        setIconStyle: function(styleObject){
            // 既に作成したiconのimgタグをいじることでstyleを反映させる
            var shownIcon   = this._icon;
            var iconOptions = this.options;
            var iconStyle   = this.options.icon.options;
            if(!!styleObject.iconUrl){
                shownIcon.src       = styleObject.iconUrl;
                iconOptions.iconUrl = styleObject.iconUrl;
                iconStyle.iconUrl   = styleObject.iconUrl;
            }
            if(!!styleObject.iconSize){
                shownIcon.style.width  = styleObject.iconSize[0] +'px';
                shownIcon.style.height = styleObject.iconSize[1] +'px';
                iconOptions.iconSize = styleObject.iconSize;
                iconStyle.iconSize = styleObject.iconSize;
            }
            this.update();
        },

        setNoteStyle: function(styleObjects){
            // 生成されたDomのスタイル
            var shownNote = this._icon.style;
            // 塗りつぶし色
            var bgColor = shownNote.backgroundColor;
            // 外枠色
            var border = shownNote.border;
            var bdColor = border.slice(border.indexOf('rgb'), border.length);
            // コメント文字色
            var strColor = shownNote.color;

            // 一括スタイル変更
            if (Object.keys(styleObjects).length !== 1) {
                // map上のDomNodeに対するスタイル変換
                this._icon.style.backgroundColor = this.colorFormatConverter(
                    styleObjects.fillColor, styleObjects.fillOpacity);
                this._icon.style.border = styleObjects.borderWidth + 'px solid ' +
                    this.colorFormatConverter(styleObjects.borderColor, styleObjects.borderOpacity);
                this._icon.style.color = this.colorFormatConverter(styleObjects.fontColor);
                this._icon.style.fontSize = styleObjects.fontSize + 'px';

                // Layer自身が持つStyle情報
                this.options.fillColor = this.options.icon.options.fillColor = styleObjects.fillColor;
                this.options.fillOpacity = this.options.icon.options.fillOpacity = styleObjects.fillOpacity;
                this.options.borderWidth = this.options.icon.options.borderWidth = styleObjects.borderWidth;
                this.options.borderColor = this.options.icon.options.borderColor = styleObjects.borderColor;
                this.options.borderOpacity = this.options.icon.options.borderOpacity = styleObjects.borderOpacity;
                this.options.fontColor = this.options.icon.options.fontColor = styleObjects.fontColor;
                this.options.fontSize = this.options.icon.options.fontSize = styleObjects.fontSize;
                this.options.fileList = this.options.icon.options.fileList = styleObjects.fileList;

            } else {
                // 塗りつぶし色
                if (!!styleObjects.fillColor) {
                    //透過度が設定されているかを確認。
                    if (bgColor.indexOf('rgba') !== -1) {
                        bgColor = this.colorFormatConverter(styleObjects.fillColor,
                                                        bgColor.slice(bgColor.lastIndexOf(',')+2, bgColor.length-1));
                    } else if (bdColor.indexOf('rgb') !== -1) {
                        bgColor = this.colorFormatConverter(styleObjects.fillColor);
                    }
                    this._icon.style.backgroundColor = bgColor;
                    this.options.fillColor = this.options.icon.options.fillColor = styleObjects.fillColor;
                }
                //塗りつぶし透過度
                if (typeof styleObjects.fillOpacity === 'number') {
                    //透過度が設定されているかを確認。
                    if (bgColor.indexOf('rgba') === -1) {
                        bgColor = 'rgba' + bgColor.slice(3, bgColor.length-1) + ', ' +
                            (''+styleObjects.fillOpacity) + ')';
                    } else {
                        bgColor = 'rgba' + bgColor.slice(4, bgColor.lastIndexOf(',')+2) +
                            (''+styleObjects.fillOpacity) + ')';
                    }
                    this._icon.style.backgroundColor = bgColor;
                    this.options.fillOpacity = this.options.icon.options.fillOpacity = styleObjects.fillOpacity;
                }
                // 枠線色
                if (!!styleObjects.borderColor) {
                    if (bdColor.indexOf('rgba') !== -1) {
                        bdColor = this.colorFormatConverter(styleObjects.borderColor,
                                                        bdColor.slice(bdColor.lastIndexOf(',')+2, bdColor.length-1));
                    } else if (bdColor.indexOf('rgb') !== -1) {
                        bdColor = this.colorFormatConverter(styleObjects.borderColor);
                    }
                    this._icon.style.border = border.slice(0, border.lastIndexOf('rgb')) + bdColor;
                    this.options.borderColor = this.options.icon.options.borderColor = styleObjects.borderColor;
                }
                // 枠線透過度
                if (typeof styleObjects.borderOpacity === 'number') {
                    if (bdColor.indexOf('rgba') === -1) {
                        bdColor = 'rgba' + bdColor.slice(3, bdColor.length-1) + ', ' +
                            (''+styleObjects.borderOpacity) + ')';
                    } else {
                        bdColor = 'rgba' + bdColor.slice(4, bdColor.lastIndexOf(',')+2) +
                            (''+styleObjects.borderOpacity) + ')';
                    }
                    this._icon.style.border = border.slice(0, border.lastIndexOf('rgb')) + bdColor;
                    this.options.borderOpacity = this.options.icon.options.borderOpacity = styleObjects.borderOpacity;
                }
                // 枠線幅
                if (!!styleObjects.borderWidth) {
                    var bdWidth = border.slice(0, border.indexOf('px')+2);
                    bdWidth = styleObjects.borderWidth + 'px';
                    this._icon.style.border = bdWidth + border.slice(border.indexOf('px')+2);
                    this.options.borderWidth = this.options.icon.options.borderWidth = styleObjects.borderWidth;
                }
                // コメント文字色
                if (!!styleObjects.fontColor) {
                    strColor = this.colorFormatConverter(styleObjects.fontColor);
                    this._icon.style.color = strColor;
                    this.options.fontColor = this.options.icon.options.fontColor = styleObjects.fontColor;
                }
                // コメント文字サイズ
                if (!!styleObjects.fontSize) {
                    var strSize = styleObjects.fontSize + 'px';
                    this._icon.style.fontSize = strSize;
                    this.options.fontSize = this.options.icon.options.fontSize = styleObjects.fontSize;
                }
            }
            // コメント
            if (styleObjects.comment !== undefined ) {
                // コメント用DivをResizeとともに生成しなおす。
                while(this._icon.firstChild){
                    this._icon.removeChild(this._icon.firstChild);
                }
                this.createCommentArea(this._icon, {'comment':styleObjects.comment});
                this.options.comment = this.options.icon.options.comment = styleObjects.comment;
            }
            // サムネイル
            if (!!styleObjects.fileList) {
                // FileListが変更される度に付箋の添付画像を生成しなおす。
                var thumnails = Util._toArray(this._icon.firstChild.children);
                thumnails.forEach(function(html){
                    if(html.tagName === 'A'){Util._rmElement(html);}
                });
                this.setThumnails(this._icon, styleObjects.fileList);
                this.options.fileList = this.options.icon.options.fileList = styleObjects.fileList;
            }

            this.update();
        },

        _setUpperOptions: function(ret){
            // ImportされたIcon/NoteのOptionはMarkerClassに設定する必要がある
            var MarkerOptions = ret.options;
            var iconOptions   = ret.options.icon.options;
            Object.keys(iconOptions).forEach(function(key){
                MarkerOptions[key] = iconOptions[key];
            });
            return ret;
        }
    };
});
