/**
 * 画面（下部）に通知を出す
 * @module app/common/notifier/NotifierBottom
 */
define([
    'module',
    'dojo/dom-class',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/NotifierBottom.html',
    'idis/view/_IdisWidgetBase',
    './Notify'
], function (module, domClass, array, declare, lang, template, _IdisWidgetBase, Notify) {
    return declare(module.id.replace(/\//g, '.'), _IdisWidgetBase, {
        // テンプレート文字列
        templateString: template,

        MAX_NOTIFY_COUNT: 3, // 表示する通知の最大件数

        // 表示している通知の情報を保持（通知の種類とidの連想配列）
        displayNotify: [],

        /**
         *
         * @param {*} obj メッセージが入ったオブジェクト
         *  message(required): メッセージ本体
         *  title: タイトル
         *  level: {high/middle/low} (default: low)
         *  timeout: ポップアップを出し続ける時間 (default: 0[消えない])
         *  page: ポップアップクリック時に遷移させたいページ(adisrouterに登録したもの)
         *  url: ポップアップクリック時に遷移させたいURL, pageとurlが両方登録されていたらpageが優先
         */
        notify: function(obj) {
            if (!obj || !obj.message) {
                return;
            }

            // 表示件数を超えた場合
            if (this.domNode.childElementCount >= this.MAX_NOTIFY_COUNT) {
                return;
            }

            // 通知がすでに表示済みであるか
            var currentNotifyIds = 
                this.displayNotify.map( function( item ) { if (item.notifyType === obj.notifyType) { return item.notifyId; } });
            if (!obj.timeout && currentNotifyIds.indexOf(obj.notifyId) !== -1) {
                return;
            }

            // 表示する通知の情報を保持
            this.displayNotify.push({ notifyType: obj.notifyType, notifyId: obj.notifyId });

            // notifierContainerのStyleをセット
            this.setTopStyle();

            return new Notify(lang.mixin(obj, {
                _container: this.notifierContainer
            }));
        },

        /**
         * 通知数に応じて、topの高さを変える
         */
        setTopStyle: function(){
            // 通知数を取得（既に追加されている要素数 ＋ 今回追加する分１）
            var notifyCount = this.domNode.childElementCount + 1;
            // top用のクラスを除去
            array.forEach(this.notifierContainer.classList, function(className){
                if (className.includes('is-')&&className.includes('notifies')) {
                    domClass.remove(this.notifierContainer, className);
                }
            }, this);
            // 数値数に応じたtopがセットされているクラスを追加
            domClass.add(this.notifierContainer, 'is-' + notifyCount + 'notifies');
        }
    });
});