/**
 * 土砂災害危険度情報表示用レイヤー
 * 内容がrefreshできるGeoJSONレイヤーを基礎とする
 * TODO: 基礎部分を分離して 'DynamicGeoJSONLayer' などを作る
 * - ベースとなる地物とそれぞれの状況を表した情報を組み合わせる
 * - 状況を表す情報を入れ替えることができる
 * - 情報の入れ替えは、URLが変更されたことを起点とする
 * @module app/observation/map/SedimentWarningLayer
 */
define([
    'dojo/_base/array',
    'dojo/_base/lang',
    'dojo/promise/all',
    'leaflet',
    'idis/service/Requester',
    'idis/control/Locator',
    'app/observation/LatestData'
], function(array, lang, all,
    leaflet, Requester, Locator, LatestData) {

    var SedimentWarningLayer = leaflet.GeoJSON.extend({

        statusUrl: null,  // 可変となるURL
        masterUrl: null,  // マスターとなる地物情報が入ったgeojsonへのパス
        idProperty: null, // 地物と危険度情報レベルをマッチするプロパティ名

        _lastDate: null, // 前回選択された観測時間
        _lastMode: '', // 前回選択された時間モード
        _latestDataTimestamp: null, // 観測データの最新時刻を保管
        _handle: null, // Locatorを監視するハンドラ
        _warningAreas: null, // 危険度を表す地物一覧

        // コンストラクタ
        initialize: function(layerInfo, options) {
            // superの呼び出し
            // 最初は空のgeoJsonを元にする
            leaflet.GeoJSON.prototype.initialize.call(
                this,
                this._emptyGeoJson(),
                options
            );

            this.statusUrl = layerInfo.dynamic.statusUrl;
            this.masterUrl = layerInfo.dynamic.masterUrl;
            this.idProperty = layerInfo.dynamic.idProperty;

            this._lastDate = null;
            this._lastMode = '10';
            this._warningAreas = {};

            var latestData = new LatestData({
                url: this.statusUrl + '/data.json'
            });

            all({
                // マスター情報を生成
                master: this.generateMaster(),
                // データの最新更新情報を取得
                latest: latestData.load()
            }).then(lang.hitch(this, function() {
                var timestamp = latestData.getLatestDateTimestamp();
                // YYYY-mm-DD HH:MM:ss -> YYYY-mm-DD-HH-MM
                this._latestDataTimestamp = timestamp.replace(
                    /(\d{4}-\d{2}-\d{2}) (\d{2}):(\d{2}):\d{2}/,
                    '$1-$2-$3'
                );

                // 初期表示
                this.onLocationChanged();

                // URLの変更を監視
                this._handle = Locator.on('change', lang.hitch(this, this.onLocationChanged));
            }));
        },

        // 観測所マスターデータを読み込む
        // 更新処理高速化のため、配列形式からHash形式に変換しておく
        generateMaster: function() {
            return Requester.get(this.masterUrl, {
                preventCache: false
            }).then(lang.hitch(this, function(data) {
                array.forEach(data.features, lang.hitch(this, function(feature) {
                    this._warningAreas[feature.properties[this.idProperty]] = feature;
                }));
            }));
        },

        // URL変更時の処理
        // 時刻が変わったら観測情報を取得してレイヤーを更新
        onLocationChanged: function() {
            // 現在のクエリー情報を取得
            var query = Locator.getQuery();

            // 日時や時間モードが指定されてなかった時のデフォルト値
            var timeMode = query.mode ? query.mode : this._lastMode;
            var datetime = query.datetime ?
                query.datetime :
                this._latestDataTimestamp;

            // 時刻が変更されていたら、レイヤーを作り直す
            if (this._lastDate !== datetime || this._lastMode !== timeMode) {
            this._refresh(datetime, timeMode);

            // 次の更新に備えてクエリー状態を保存
            this._lastDate = datetime;
            this._lastMode = timeMode;
            }
        },

        // レイヤー内容を指定された時間の観測情報で更新
        _refresh: function(timestamp, timeMode) {
            // datetimeで表されるjsonファイルを取得
            var url = this.statusUrl + '/' + timestamp + '.json';
            Requester.get(url).then(
                lang.hitch(this, function(json) {
                    // jsonからgeojsonを作る
                    var geojson = this._generateGeoJson(json, timeMode);

                    // layer内容をクリア
                    this.clearLayers();

                    // 作ったgeojsonを表示
                    this.addData(geojson);
                })
            );
        },

        // このレイヤーがmapから削除されたときに呼ばれる
        onRemove: function(map) {
            // Locator監視を停止
            this._handle.remove();
            leaflet.GeoJSON.prototype.onRemove.call(this, map);
        },

        // 全ての地物を地図から消す
        clearLayers: function() {
            leaflet.GeoJSON.prototype.clearLayers.call(this);
        },

        // 地図に地物を表示する
        addData: function(geojson) {
            leaflet.GeoJSON.prototype.addData.call(this, geojson);
        },

        // 取得した観測データから表示させるためのGeoJSONを生成
        _generateGeoJson: function(json, mode) {
            // 空のgeojsonからスタート
            var geojson = this._emptyGeoJson();

            // statusに緯度経度と各種プロパティが並んで入っているので、GeoJSON形式に変換
            array.forEach(json.items, lang.hitch(this, function(item, i) {
                // マスター情報を取得
                var warningArea = this._warningAreas[item[this.idProperty]];

                // 一致するものがなかったらskip
                if (!warningArea) {
                    return;
                }

                var feature = {
                    geometry: warningArea.geometry,
                    type: 'Feature',
                    id: i + 1,
                    timestamp: this._latestDataTimestamp,
                    properties: warningArea.properties
                };

                // 危険度レベルをpropertiesに入れる
                feature.properties.level = item.level;

                // 時間モードをfeature.propertiesに入れる
                feature.properties.mode = mode;

                geojson.features.push(feature);
        }));

            return geojson;
        },

        // 空のgeojsonを作る
        _emptyGeoJson: function() {
            return {
                type: 'FeatureCollection',
                features: []
            };
        }
    });

    return SedimentWarningLayer;
});