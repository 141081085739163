/**
 * 観測情報のレイヤーIDを定数として定義
 * @module app/consts/LAYER_ID
 */
 define({
    // 雨量観測情報（10分）
    RAINFALL_10MIN: '35-0',
    // 雨量観測情報（1時間）
    RAINFALL_1HOUR: '36-0',
    // 雨量観測情報（累計）
    RAINFALL_CUMULATIVE: '37-0',
    // 水位観測情報
    RIVER: '38-0',
    // 気象情報・注意報
    WEATHER_WARNING: '51-0',
    // 高解像度降雨ナウキャスト
    HIGHRES_NOWCAST: '54-0',
    // 土壌雨量指数(実況)
    SRF_CURRENT: '55-0',
    // 土壌雨量指数(予測)
    SRF_FORECAST: '56-0',
    // 大雨警報（浸水害）危険度分布
    INUND_RISK: '57-0',
    // 土砂災害危険度情報
    SEDIMENT_RISK: '65-0',
    // 土砂災害危険度情報：スネークライン
    SEDIMENT_RISK_SNAKELINE: '58-0',
    // 洪水警報危険度分布
    FLOOD_RISK: '62-0',
    // SIP4D近隣県避難所(山口県)
    YAMAGUCHI_PRE_SHELTER: '851-0',
    // SIP4D近隣県避難所(福岡県)
    FUKUOKA_PRE_SHELTER: '852-0',
    // SIP4D近隣県避難所(佐賀県)
    SAGA_PRE_SHELTER: '853-0',
    // SIP4D近隣県避難所(熊本県)
    KUMAMOTO_PRE_SHELTER: '854-0',
    // SIP4D近隣県避難所(大分県)
    OITA_PRE_SHELTER: '855-0',
    // SIP4D近隣県避難所(宮崎県)
    MIYAZAKI_PRE_SHELTER: '856-0',
    // SIP4D近隣県避難所(鹿児島県)
    KAGOSHIMA_PRE_SHELTER: '857-0'
});
