/**
 * ポップアップアラート
 * @module app/view/PopupAlert
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojox/widget/Toaster'
], function(module, array, declare, Toaster){
	return declare(module.id, [Toaster], {
        // オーバーライドして独自のcssを当てられるように変更
        templateString: '<div class="adisDijitToasterClip" data-dojo-attach-point="clipNode">' +
        ' <div class="adisDijitToasterContainer" data-dojo-attach-point="containerNode" ' +
        'data-dojo-attach-event="onclick:onSelect">' +
        '<div class="adisDijitToasterContent" data-dojo-attach-point="contentNode"></div></div></div>'
    });
});
