/**
 * style.jsを読み込むモジュール
 * layercontrol内に閉じているものを外出しするもの
 * @module app/map/layer/StyleLoader
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'idis/service/Requester',
    'idis/error/InvalidArgumentException'
], function(module, declare, lang, Requester, InvalidArgumentException) {

    return declare(null, {
        /**
         * style.jsへのリクエスト結果
         * @type {Promise<Object>}
         */
        _promise: null,

        /**
         * style.jsのURL
         * @type {String}
         */
        url: null,

        /**
         * レイヤーID
         * @type {Number}
         */
        id: null,

        /**
         * style.jsをparseした中身
         * @type {Object}
         */
        _data: null,

        constructor: function(kwArgs) {
            lang.mixin(this, kwArgs);
        },

        /**
         * style.jsをサーバーから取得し、成否をPromiseとして返す。
         * @returns {Promise}
         */
        load: function() {
            if (!this._promise) {
                this._data = {};
                this._promise = Requester.get(this._parseUrl(this.url), {
                    handleAs: 'text'
                }).then(lang.hitch(this, function(result) {
                    this._data = eval('(' + result + ')'); //jshint ignore: line
                }));
            }
            return this._promise;
        },

        /**
         * レイヤーのレコードに含まれるURL指定を解析し、
         * 相対パス指定ならばレイヤーのIDに応じたパスに変換する。
         * @returns {string} 変換後のURL。urlとして偽値が与えられた場合は偽値そのまま。
         */
        _parseUrl: function () {
            if (!this.id && this.id !== 0) {
                throw new InvalidArgumentException(module.id + '::_parseUrl: idの指定は必須です');
            }
            // 偽値か完全なURLの場合はそのまま返す
            if (!this.url || this.url.indexOf('://') !== -1 || this.url.indexOf('/') === 0) {
                return this.url;
            }
            // IDに応じたデータ用ディレクトリー直下からの相対パスと見なす
            return ['/data/layer/data', this.id, this.url].join('/');
        },

        getStyle: function() {
            return this._data;
        }
    });
});
