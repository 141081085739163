/**
 * locationオブジェクトのメソッドは直接スタブ出来ないため、
 * このモジュールを通して操作することでテスト時にスタブ可能にする。
 * @module idis/control/Locator
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/Evented',
    'dojo/io-query',
    'dojo/on',
    'dojox/lang/functional/array',
    '../consts/QUERY'
], function(module, array, declare, lang, Evented, ioQuery, on, df, QUERY) {
    var Locator = declare(Evented, /** @lends module:idis/control/Locator */ {
        /**
         * 履歴操作のイベントリスナーに対するハンドル
         * @type {handle}
         * @private
         */
        _handle: null,
        _prevHref: null,
        _prevQuery: null,

        /**
         * 履歴操作に対する監視を開始する。
         */
        start: function() {
            if (this._handle) {
                console.debug(module.id + '::on: already on');
            } else {
                this._handle = on(window, 'popstate', lang.hitch(this, function() {
                    // 変更イベントを発行
                    this.emit('change', true);
                }));
            }
        },

        /**
         * 履歴操作に対する監視を停止する。
         */
        stop: function() {
            if (this._handle) {
                this._handle.remove();
                this._handle = null;
            } else {
                console.debug(module.id + '::off: already off');
            }
        },

        /**
         * location.assignを呼び出す。
         * @param {string} url 読み込むURL
         */
        assign: function(url) {
            return location.assign(url);
        },

        /**
         * location.replaceを呼び出す。
         * @param {string} url 読み込むURL
         */
        replace: function(url) {
            return location.replace(url);
        },

        /**
         * pushState, replaceState用のパラーメーターから移動先URLを生成する。
         * @param {Object|string} url 更新先のURL。オブジェクトの場合はクエリー部のみ対応するキーと値で更新する。
         *                            2つ以上の引数が指定された場合はクエリーのキー名として扱う。
         * @param {*} [value] クエリーの設定値。第1引数が文字列の場合のみ有効。
         * @param {boolean} [only=false] 指定されなかったクエリーを除去するか。第1引数がオブジェクト or 引数が3つ揃った場合のみ有効。
         * @returns {stirng} 移動先URLを表す文字列
         * @example
         * // クエリー部のfooの値を'bar'に更新
         * Locator._parseStateArgs({foo: 'bar'}); // '?...&foo=bar&...'
         * // 同上、ただし指定されなかったキーは消去する
         * Locator._parseStateArgs({foo: 'bar'}, true); // '?foo=bar'
         * // クエリー部のfooの値を'bar'に更新
         * Locator._parseStateArgs('foo', 'bar'); // '?...&foo=bar&...'
         * // 同上、ただし指定されなかったキーは消去する
         * Locator._parseStateArgs('foo', 'bar', true); // '?foo=bar'
         * // URLを指定された値で更新する
         * Locator._parseStateArgs('?foo=bar'); // '?foo=bar'
         */
        _parseStateArgs: function(url, value, only) {
            // 引数が1つしかない場合は移動先URLが直接指定されたと見なす
            if (lang.isString(url) && (arguments.length < 2 || value === true)) {
                if (url.indexOf('?p=') === 0 && value === true) {
                    console.warn([
                        'Locator.pushState(\'?\' + Router.PAGE_KEY + \'=mypage\', true)という書き方はDeprecatedです。',
                        'Router.moveTo(\'mypage\') (or Router.moveTo(\'mypage\', {foo: \'bar\'}))と書いてください。'
                    ].join(''));
                }
                return url;
            }
            // 第1引数を一旦オブジェクトに揃える
            if (lang.isObject(url)) {
                // 第1引数がオブジェクトなら、valueは使わない
                only = value;
            } else {
                // 第1引数をクエリーのキー名と見なす
                var state = {};
                state[url] = value;
                url = state;
            }
            if (!only) {
                // 元のパラメーターを保持（同じキー名の場合は今回の設定値で上書き）
                url = lang.mixin(this.getQuery(), url);
            }
            // 文字列化して返す
            return '?' + ioQuery.objectToQuery(url);
        },

        /**
         * URLをpushStateで更新する。
         * 更新後、自分自身に対しchangeイベントを発行する。
         * @param {Object|string} url 更新先のURL。オブジェクトの場合はクエリー部のみ対応するキーと値で更新する。
         *                            2つ以上の引数が指定された場合はクエリーのキー名として扱う。
         * @param {*} [value] クエリーの設定値。第1引数が文字列の場合のみ有効。
         * @param {boolean} [only=false] 指定されなかったクエリーを除去するか。第1引数がオブジェクト or 引数が3つ揃った場合のみ有効。
         */
        pushState: function (/* url, value, only */) {
            // 遷移前の画面取得
            this._prevHref = this.getHref();
            this._prevQuery = this.getQuery();
            // 移動して変更イベントを発行
            history.pushState(null, null, this._parseStateArgs.apply(this, arguments));
            this.emit('change', false);
        },

        /**
         * URLをreplaceStateで更新する。
         * 更新後、自分自身に対しchangeイベントを発行する。
         * @param {Object|string} url 更新先のURL。オブジェクトの場合はクエリー部のみ対応するキーと値で更新する。
         *                            2つ以上の引数が指定された場合はクエリーのキー名として扱う。
         * @param {*} [value] クエリーの設定値。第1引数が文字列の場合のみ有効。
         * @param {boolean} [only=false] 指定されなかったクエリーを除去するか。第1引数がオブジェクト or 引数が3つ揃った場合のみ有効。
         */
        replaceState: function(/* url, value, only */) {
            // 移動して変更イベントを発行
            history.replaceState(null, null, this._parseStateArgs.apply(this, arguments));
            this.emit('change', false);
        },

        /**
         * location.hrefの内容を返す。
         * @returns {string} location.hrefの内容
         */
        getHref: function() {
            return location.href;
        },

        /**
         * location.hrefの内容を更新する。
         */
        setHref: function(url) {
            location.href = url;
        },

        /**
         * location.searchの内容を返す。
         * @returns {string} location.searchの内容
         */
        getSearch: function() {
            return location.search;
        },

        /**
         * location.searchの内容をオブジェクト形式で返す。
         * @returns {Object} location.searchの内容
         */
        getQuery: function() {
            return ioQuery.queryToObject(this.getSearch().slice(1)) || {};
        },

        /**
         * 受け取った文字列のsearch部相当の部分を取り出す。
         * 偽値を受け取った場合やsearch部相当の部分が無い場合は空オブジェクトを返す。
         * @param {string} [url] URL文字列
         * @returns {Object} location.search相当の内容
         */
        getQueryFrom: function(url) {
            var search = url && url.split('#')[0].split('?')[1];
            return search && ioQuery.queryToObject(search) || {};
        },

        /**
         * URLの状態からレイヤー識別子と透過度の組み合わせをオブジェクトとして取得する。
         * キーと値に分割出来ないものが含まれていた場合は単に無視する。
         * @returns {Object<identifier,string>} レイヤー識別子と透過度の組み合わせ
         */
        getLayerQuery: function() {
            var layerQueryParam = this.getQuery()[QUERY.LAYER_LIST];
            // 指定が無ければ空オブジェクトを返す
            if (!layerQueryParam) {
                return {};
            }
            // 結果オブジェクト
            var result = {};
            array.forEach(layerQueryParam.split(','), function(param) {
                var idAndOpacity = param.split('-');
                // 正常に分割できた場合だけ使う
                if (idAndOpacity[1]) {
                    // IDをキー、透明度を値として格納
                    result[idAndOpacity[0]] = idAndOpacity[1];
                }
            });
            return result;
        },

        getPrevHref: function () {
            return this._prevHref;
        },
        getPrevQuery: function () {
            return this._prevQuery;
        },
        getPrevPage: function () {
            // 全画面の情報がない場合
            if (!this._prevQuery) {
                return '';
            }
            // パラメータにpがない場合
            if (!'p' in this._prevQuery) {
                return '';
            }
            // 前のページを返す
            return this._prevQuery['p'];
        },
        /**
         * レイヤー識別子と透過度の組み合わせをオブジェクトとして受け取り、
         * レイヤー用クエリー文字列に変換して返す。
         * @param {Object<identifier,string>} レイヤー識別子と透過度の組み合わせ
         * @returns {string} レイヤー一覧のクエリー値化した文字列、偽値を受け取った場合は長さ0の文字列
         * @example
         * Locator.toLayerQuery({1: 20, 3: 40}); // === '1=20,3=40'
         */
        toLayerQuery: function(layerQuery) {
            if (!layerQuery) {
                return '';
            }
            return df.map(layerQuery, function(value, key) {
                return [key, value].join('-');
            }).join(',');
        },

        /**
         * 指定された文字列が現在と同一のパスを表すURLかどうかを返す。
         * ドメイン、スキーム、パスが異なる場合は異なるパスとみなす。
         * クエリー部、ハッシュ部、index.html有無の差異、空文字列を受け取った場合は同一のパスとみなす。
         * @returns {boolean} 指定された文字列が現在と同一のパスを表すURLかどうか
         */
        isCurrentPath: function(url) {
            var urlPath = url.split('#')[0].split('?')[0];
            var urlPathNoIndex = urlPath.replace(/\/?index\.html$/, '');
            var locationPathNoIndex = this.getHref().split('#')[0].split('?')[0].replace(/\/?index\.html$/, '');
            // 引数がクエリー部以前を含み、かつ現在のパスと一致しないならtrue
            return urlPath && urlPathNoIndex !== locationPathNoIndex;
        },
        
        /**
         * location.originの内容を返す
         * @returns {string} location.originの内容
         */
        getOrigin: function() {
            // IE10以下ではlocation.originは未実装のためlocation.originは使わず個別に取得して形成
            return location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '');
        }
    });

    return new Locator();
});
