/**
 * ユーザ種別の定数定義
 */
define({
    // 長崎県ユーザ
    PREFECTURE: '01',
    // 振興局ユーザ
    REGION: '02',
    // 区・一般ユーザ
    MUNICIPALITY: '03',
    // 関係機関ユーザ
    OTHER_ORGAN: '04'
});
