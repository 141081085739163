/**
 * 行政界レイヤー
 *
 */
define([
    'dojo/_base/lang',
    'idis/service/Requester',
    'idis/view/dialog/InfoDialog',
    'leaflet'
], function(
    lang,
    Requester, InfoDialog,
    leaflet
) {
    var defaultURL = '/data/master/municipalities.geojson';

    var MunicipalityLayer = leaflet.GeoJSON.extend({

        initialize: function(layerInfo, options) {
            var url = (layerInfo && layerInfo.layerUrl) || defaultURL;

            options = options || {};
            // styleのfunctionは何かしら必要なので, _styleというデフォルトのスタイル定義を用意しておいて
            // 指定がなければそれを使う
            if (!options.style || typeof(options.style) !== 'function') {
                lang.mixin(options, {
                    style: this._style
                });
            }

            // superの呼び出し, 地物は空の状態で作る
            leaflet.GeoJSON.prototype.initialize.call(
                this,
                null,
                options
            );

            this._loadData(url);
        },

        /**
         * デフォルトのスタイル
         * @param {Object}} feature
         */
        _style: function() {
            return {
                color: 'blue',
                weight: 2,
                fill: true,
                fillOpacity: 0
            };
        },

        /**
         * 地物ファイルをロード
         * @param {*} url 地物のURL
         * @returns requesterが発行するpromise
         */
        _loadData: function(url) {
            // geojsonをリクエストして, もらえたら地物を追加
            return Requester.get(url).then(lang.hitch(this, function(data) {
                this.addData(data);
                
            }), function(error) {
                InfoDialog.show(
                    'エラー',
                    '行政界の地物データがロードできません' + url
                );

                console.error('行政界地物データロード失敗' + '\n' + error);
            });
        }
    });

    return MunicipalityLayer;
});