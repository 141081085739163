/**
 * 複数のGeoJSONを1つのレイヤーに表示させるためのレイヤー
 *
 * 使う場合は, layerInfo.filesに表示させたいGeoJSONの
 * URLリストを入れる.
 *
 */
define([
    'dojo/Deferred',
    'dojo/_base/array',
    'dojo/_base/lang',
    'dojo/promise/all',
    'idis/service/Requester',
    'leaflet'
], function(
    Deferred, array, lang, all,
    Requester,
    leaflet
) {

    var MultipleGeoJsonLayer = leaflet.GeoJSON.extend({

        files: ['red.geojson', 'yellow.geojson'], // GeoJSONのURLをいれるarray
        parent: null,

        initialize: function(layerInfo, options) {
            this.parent = layerInfo.layerUrl;
            this.files = layerInfo.files;

            this._generateGeoJSON().then(
                lang.hitch(this, function(data) {
                    // superの呼び出し
                    // 最初は空のgeoJsonを元にする
                    leaflet.GeoJSON.prototype.initialize.call(
                        this,
                        data,
                        options
                    );
                })
            );
        },

        /**
         * GeoJSONを読み込んで作る
         */
        _generateGeoJSON: function() {
            var promise = new Deferred();

            all(
                array.map(this.files, lang.hitch(this, this._request))
            ).then(lang.hitch(this, function(results) { // resultsにはarrayが入る

                var geojsonList = array.filter(results, function(item) {
                    return (item); // null や undefinedではないものでfilter
                });

                // 中身が空(全てのURLに対するリクエストがエラー)なら
                // 何もしない
                if (geojsonList.length === 0) {
                    promise.reject();
                }

                var geojson = {
                    type: 'FeatureCollection',
                    features: []
                };

                // それぞれのgeojsonにあるfeatureを追加
                array.forEach(geojsonList, function(item) {
                    var a = geojson.features.concat(item.features);
                    geojson.features = a;
                });

                promise.resolve(geojson);
            }));

            return promise;
        },

        /**
         * GeoJSONのfileに対してリクエストする
         * エラーになった場合はdataにnullを入れてresolveする(rejectしない)
         */
        _request: function(file) {
            var deferred = new Deferred();

            var url = [this.parent, file].join('/');
            Requester.get(url).then(
                function(data) {
                    return deferred.resolve(data);
                },
                function() {
                    // エラー時はnullを入れてresolve
                    return deferred.resolve(null);
                }
            );

            return deferred;
        }
    });

    return MultipleGeoJsonLayer;
});